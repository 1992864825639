import Fingerprint2 from 'fingerprintjs2';
import SimpleCrypto from 'simple-crypto-js';

const simpleCrypto = new SimpleCrypto('');

// Generates the same key for web browser and standalone app
const fingerprintOptions: Fingerprint2.Options = {
  excludes: {
    userAgent: true,
    enumerateDevices: true,
    canvas: true,
    webgl: true,
    adBlock: true,
    indexedDb: true,
    language: true,
    localStorage: true,
    openDatabase: true,
  },
};

const getSecretKey = async (): Promise<string | Error> => {
  return Fingerprint2.getPromise(fingerprintOptions)
    .then(components => {
      const values = components.map(component => component.value);
      const murmur = Fingerprint2.x64hash128(values.join(''), 31);

      return murmur;
    })
    .catch((reason: Error) => reason);
};

export const encrypt = async (data: string): Promise<string | undefined> => {
  try {
    const secretKey = await getSecretKey();
    simpleCrypto.setSecret(secretKey);
    const encryptedData = simpleCrypto.encrypt(data);
    return encryptedData;
  } catch (e) {
    return undefined;
  }
};

export const decrypt = async (data: string): Promise<string | undefined> => {
  try {
    const secretKey = await getSecretKey();
    simpleCrypto.setSecret(secretKey);
    const decryptedData = simpleCrypto.decrypt(data).toString();
    return decryptedData;
  } catch (e) {
    return undefined;
  }
};

export const decryptBatch = async (
  data: (string | undefined)[]
): Promise<(string | undefined)[] | undefined> => {
  try {
    const secretKey = await getSecretKey();
    simpleCrypto.setSecret(secretKey);
    const decryptedDataBatch: (string | undefined)[] = [];
    data.forEach((value: string | undefined) => {
      if (value) {
        const decryptedData = simpleCrypto.decrypt(value).toString();

        decryptedDataBatch.push(decryptedData);
      } else {
        decryptedDataBatch.push(value);
      }
    });
    return decryptedDataBatch;
  } catch (e) {
    return undefined;
  }
};

export const cipherOcuco = (
  value: string,
  encrypt: boolean = false
): string => {
  /* let charCode = 0;
  let charChar = '';
  let result = '';
  for (let i = 0; i < value.length; i++) {
    charCode = value[i].charCodeAt(0);
    if (charCode >= 65 && charCode <= 90) {
      if (charCode + i < 65) {
        charCode += i + 26;
      } else {
        charCode += i;
      }
    } else if (charCode >= 97 && charCode <= 122) {
      if (charCode + i < 97) {
        charCode += i + 26;
      } else {
        charCode += i;
      }
    } else {
      charCode += i;
    }

    charChar = String.fromCharCode(charCode);
    result += charChar;
  }

  return result;*/

  return value;
};

export const decodeURL = (url: string) => {
  let result = '';
  if (url.length > 0) {
    let i = 0;
    while (i <= url.length) {
      if (url[i] === '%') {
        const hexaDecimal = url[i + 1] + url[i + 2];
        result += String.fromCharCode(parseInt(hexaDecimal, 16));
        i += 2;
      } else {
        result += url[i];
      }
      i++;
    }
  }

  return result;
};
