import React from 'react';

import { NavItemComponent } from './nav-item.component';
import { NavItem } from './nav-item.model';

interface NavItemsProps {
  links: NavItem[];
  className?: string;
}

export const NavItemsComponent: React.FunctionComponent<NavItemsProps> = React.memo(
  ({ links, className }) => {
    const classNames = `nav ${className ? className : ''}`;
    return (
      <ul className={classNames}>
        {links.map((link, index) => (
          <NavItemComponent key={index} link={link} isApiTranslated={true} />
        ))}
      </ul>
    );
  }
);
