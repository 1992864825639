export enum AlertType {
  DEFAULT = 'primary',
  ERROR = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
}

export interface AlertModel {
  type: AlertType;
  isShown: boolean;
  message?: string;
}
