import React, { Suspense } from 'react';

import { SpinnerComponent } from '../design/components/spinner/spinner.component';

export function withLazy(lazyLoadFn: () => Promise<{ default: any }>) {
  const LazyComponent = React.lazy(lazyLoadFn);
  return (props: any) => (
    <Suspense fallback={<SpinnerComponent />}>
      <LazyComponent {...props} />
    </Suspense>
  );
}
