import {
  DatabaseEntry,
  LocalStorageErrorType,
  DatabaseError,
} from './local-storage.model';
import { v4 as uuidv4 } from 'uuid';
import {
  mapLocalStorageErrorToMessage,
  getDatabaseInstance,
} from './local-storage.mapper';
import {
  DatabaseType,
  DATABASE_GOS_FORMS_ALLOW_DUPLICATES,
  DATABASE_AUTHORIZATION_ALLOW_DUPLICATES,
  DATABASE_INITIALIZAITON_ALLOW_DUPLICATES,
} from './local-storage.config';
import { isDatabaseError } from './utils';

export const updateDatabaseEntry = async (
  dbEntry: DatabaseEntry,
  databaseType: DatabaseType = DatabaseType.GOS_FORMS
): Promise<DatabaseEntry | DatabaseError> => {
  const localForageInstance = getDatabaseInstance(databaseType);

  return await localForageInstance
    .setItem(dbEntry.key, dbEntry.data)
    .then((value: any) => {
      const retVal: DatabaseEntry = { key: dbEntry.key, data: value };
      return retVal;
    })
    .catch(() => {
      const error: DatabaseError = {
        type: LocalStorageErrorType.ERROR_UPDATE,
        message: mapLocalStorageErrorToMessage(
          LocalStorageErrorType.ERROR_UPDATE
        ),
      };

      return error;
    });
};

export const removeDatabaseEntry = async (
  key: string,
  databaseType: DatabaseType = DatabaseType.GOS_FORMS
): Promise<any | DatabaseError> => {
  const localForageInstance = getDatabaseInstance(databaseType);

  return await localForageInstance
    .removeItem(key)
    .then((value: any) => value)
    .catch((err: any) => {
      const error: DatabaseError = {
        type: LocalStorageErrorType.ERROR_REMOVE,
        message: mapLocalStorageErrorToMessage(
          LocalStorageErrorType.ERROR_REMOVE
        ),
      };

      return error;
    });
};

// Comparasion function needed if only unique values should be stored in the database
export const addToDatabase = async (
  data: any,
  key?: string,
  comparasionFunction?: (a: any, b: any) => boolean,
  databaseType: DatabaseType = DatabaseType.GOS_FORMS
): Promise<DatabaseEntry | DatabaseError> => {
  let dbEntryKey = key || uuidv4();
  let dbEntry: DatabaseEntry = { key: dbEntryKey, data: data };

  if (!mapDatabaseToAllowDuplicates(databaseType) && comparasionFunction) {
    const isInDb = await findInDatabase(
      data,
      comparasionFunction,
      databaseType
    );
    if (isInDb) {
      const error: DatabaseError = {
        type: LocalStorageErrorType.ERROR_DUPLICATE,
        message: mapLocalStorageErrorToMessage(
          LocalStorageErrorType.ERROR_DUPLICATE
        ),
      };

      return error;
    }
  }

  let addedToDatabase = false;
  while (!addedToDatabase) {
    const retValue = updateDatabaseEntry(dbEntry, databaseType);
    if (!isDatabaseError(retValue)) {
      return retValue;
    } else {
      key = uuidv4();
      dbEntry = { key: key, data: data };
    }
  }

  const error: DatabaseError = {
    type: LocalStorageErrorType.ERROR_ADD,
    message: mapLocalStorageErrorToMessage(LocalStorageErrorType.ERROR_ADD),
  };

  return error;
};

export const getFromDatabase = (
  key: string,
  databaseType: DatabaseType = DatabaseType.GOS_FORMS
): Promise<any | DatabaseError> => {
  const localForageInstance = getDatabaseInstance(databaseType);

  return localForageInstance
    .getItem(key)
    .then((value: any) => value)
    .catch(() => {
      const error: DatabaseError = {
        type: LocalStorageErrorType.ERROR_GET,
        message: mapLocalStorageErrorToMessage(LocalStorageErrorType.ERROR_GET),
      };

      return error;
    });
};

export const clearDatabase = async (
  databaseType: DatabaseType = DatabaseType.GOS_FORMS
) => {
  const localForageInstance = getDatabaseInstance(databaseType);

  await localForageInstance.clear();
};

export const getAllFromDatabase = (
  databaseType: DatabaseType = DatabaseType.GOS_FORMS
): Promise<DatabaseEntry[] | DatabaseError> => {
  const localForageInstance = getDatabaseInstance(databaseType);

  const dbEntries: DatabaseEntry[] = [];

  return localForageInstance
    .iterate((value: any, key: string) => {
      const dbEntry: DatabaseEntry = {
        key: key,
        data: { ...value },
      };
      dbEntries.push(dbEntry);
    })
    .then(() => {
      return dbEntries;
    })
    .catch(() => {
      const error: DatabaseError = {
        type: LocalStorageErrorType.ERROR_GET,
        message: mapLocalStorageErrorToMessage(LocalStorageErrorType.ERROR_GET),
      };

      return error;
    });
};

export const findInDatabase = async (
  object: any,
  comparasionFunction: (a: any, b: any) => boolean,
  databaseType: DatabaseType = DatabaseType.GOS_FORMS
): Promise<any | undefined> => {
  const items = await getAllFromDatabase(databaseType);

  if (!isDatabaseError(items)) {
    const itemsSize = (items as DatabaseEntry[]).length;
    for (let i = 0; i < itemsSize; i++) {
      if (comparasionFunction(object, items[i].data)) {
        return items[i];
      }
    }
  }
  return undefined;
};

export function instanceOf<T>(object: any): object is T {
  let object2 = {} as T;
  const keys1 = Object.keys(object);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

const mapDatabaseToAllowDuplicates = (databaseType: DatabaseType) => {
  switch (databaseType) {
    case DatabaseType.GOS_FORMS:
      return DATABASE_GOS_FORMS_ALLOW_DUPLICATES;
    case DatabaseType.AUTHORIZATION:
      return DATABASE_AUTHORIZATION_ALLOW_DUPLICATES;
    case DatabaseType.INITIALIZATION:
      return DATABASE_INITIALIZAITON_ALLOW_DUPLICATES;
  }
};
