import React from 'react';
import { Label as BootstrapLabel } from 'reactstrap';

import formStyle from './label.module.scss';

interface LabelProps {
  children: React.ReactNode;
  isRequired?: boolean;
  htmlFor?: string;
  className?: string;
}

export const Label: React.FunctionComponent<LabelProps> = ({
  children,
  isRequired,
  className,
  htmlFor,
  ...rest
}) => {
  return (
    <BootstrapLabel for={htmlFor} className={className} {...rest}>
      {children}
      {isRequired && (
        <span
          className={formStyle.requiredMarker}
          data-test-el="label-is-required"
        >
          *
        </span>
      )}
    </BootstrapLabel>
  );
};
