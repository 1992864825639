import {
  EmbeddedDate,
  SignaturePadOptionsURL,
  SignaturePadOptions,
  CanvasImageType,
} from '../../../shared/design';

// Requirements
export const SIGNATURE_SIZE_LIMIT = 15000; // in KBs
export const SIGNATURE_WIDTH = 500;
export const SIGNATURE_HEIGHT = 200;
export const CANVAS_WIDTH = 500;
export const CANVAS_HEIGHT = 200;
export const SIGNATURE_RESIZE_SCALE = 1.0;
export const CANVAS_IMAGE_TYPE: CanvasImageType = 'image/jpeg';
export const CANVAS_IMAGE_QUALITY = 0.7;

export const SIGNATURE_PAD_OPTIONS: SignaturePadOptions = {
  velocityFilterWeight: 0.6,
};

export const SIGNATURE_PAD_OPTIONS_URL: SignaturePadOptionsURL = {
  height: SIGNATURE_HEIGHT,
  width: SIGNATURE_WIDTH,
  ratio: window.devicePixelRatio,
};

export const SIGNATURE_EMBEDDED_DATE: EmbeddedDate = {
  enable: true,
  fontSize: 1.0, // in REM
  locale: 'en-GB',
  offset: { x: 5, y: -5 },
};
