import React, { useRef } from 'react';
import { FlexColumn } from '../../..';
import { NumericInput } from '../../numeric-input/numeric-input.component';
import { MINUS_KEY, PLUS_KEY, DOT_KEY } from '../../../../utils/keys';
import classnames from 'classnames';
import styles from './pin-field.module.scss';

export interface PinFieldProps {
  value: string;
  pinFieldRef?: React.RefObject<HTMLInputElement>;
  className?: string;
  onChange?: (value: string) => void;
  onInput?: (event: React.FormEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const PinField: React.FC<PinFieldProps> = React.memo(
  ({ value, pinFieldRef, className, onChange, onKeyDown }) => {
    const inputValue = React.useMemo(() => {
      let strippedValue = value;
      if (value.length > 1) {
        strippedValue = value.slice(1, 2);
      }

      strippedValue = strippedValue.replace(/\D/g, '');

      if (strippedValue) {
        return strippedValue;
      }

      return value;
    }, [value]);

    const inputRefTemp = useRef<HTMLInputElement>(null);
    const inputRef = pinFieldRef || inputRefTemp;

    return (
      <FlexColumn className={classnames(className, styles.pinFieldContainer)}>
        <NumericInput
          innerRef={inputRef}
          value={inputValue}
          onChange={onChange}
          onKeyDown={onKeyDown}
          className={styles.pinField}
          centerText
        />
      </FlexColumn>
    );
  }
);
