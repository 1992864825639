import React from 'react';
import classnames from 'classnames';

import { Icon } from '../icon';

import styles from './icon-text.module.scss';

export interface IconTextProps {
  icon?: React.SFC<React.SVGProps<SVGSVGElement>>;
  inline?: boolean;
  iconProps?: React.HTMLAttributes<SVGElement>;
}

export const IconText: React.FC<IconTextProps> = ({
  icon,
  children,
  inline,
  iconProps: { className: iconClassName, ...iconProps } = {},
}) => (
  <>
    {icon && (
      <Icon
        inline={inline}
        SvgComponent={icon}
        className={classnames(styles.icon, iconClassName)}
        {...iconProps}
      />
    )}
    {children}
  </>
);
