import React from 'react';
import {
  CustomGrid,
  FlexColumn,
  InnerBox,
  CustomGridSize,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalSize,
  PrimaryButton,
  ModalSuccessSVG,
  Icon,
} from '../../../../';

import styles from './modal-success.module.scss';

interface ModalSuccessProps {
  isOpen: boolean;
  title?: string | JSX.Element;
  body?: string | JSX.Element;
  footer?: string | JSX.Element;
  onConfirm?: () => void;
  size?: ModalSize;
  className?: string;
  hasCloseButton?: boolean;
}

export const ModalSuccess: React.FunctionComponent<ModalSuccessProps> = ({
  isOpen,
  title,
  body,
  footer,
  onConfirm,
  size = ModalSize.SMALL,
  className,
  hasCloseButton = false,
  ...props
}) => {
  return (
    <Modal isOpen={isOpen} size={size} className={className} autoFocus={true}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <InnerBox withOnlyVerticalPadding={true}>
          <CustomGrid autoColumnSize={CustomGridSize.MAX_CONTENT}>
            <FlexColumn data-testid="modal-icon">
              <Icon className={styles.icon} SvgComponent={ModalSuccessSVG} />
            </FlexColumn>
            <FlexColumn className={styles.message} data-testid="modal-message">
              {props.children ? props.children : body}
            </FlexColumn>
          </CustomGrid>
        </InnerBox>
      </ModalBody>
      <ModalFooter>
        {footer ? (
          footer
        ) : (
          <PrimaryButton onClick={onConfirm} data-testid="modal-confirm">
            Ok
          </PrimaryButton>
        )}
      </ModalFooter>
    </Modal>
  );
};
