import React from 'react';
import { FlexColumn } from '../../..';
import styles from './decorator-list.module.scss';

interface DecoratorListProps {
  caption?: string;
}

export const DecoratorList: React.FC<DecoratorListProps> = ({
  caption,
  children,
}) => {
  return (
    <FlexColumn>
      {caption}
      <ul className={styles.decoratorList}>{children}</ul>
    </FlexColumn>
  );
};
