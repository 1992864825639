export function* serialIdGenerator(objectName: string) {
  let id = 0;
  while (true) {
    yield `${objectName}_${id++}`;
  }
}

export function* serialNumericIdGenerator(base?: number | null) {
  let id = base ? base : 0;

  while (true) {
    yield id++;
  }
}
