import React from 'react';
import { FlexColumn, Label, Loading } from '../../';
import { EmptyList } from './empty-list/empty-list.component';
import classnames from 'classnames';
import styles from './list.module.scss';

export interface ListProps {
  title?: string;
  itemCount: number;
  emptyComponent?: JSX.Element;
  isLoading?: boolean;
  className?: string;
}

export const List: React.FC<ListProps> = ({
  title = 'Patients',
  itemCount,
  emptyComponent,
  isLoading,
  className,
  children,
}) => {
  return (
    <Loading isLoading={isLoading}>
      <FlexColumn className={classnames(className, styles.listContainer)}>
        <Label className={styles.title}>{title}</Label>
        <FlexColumn>
          {itemCount ? children : emptyComponent || <EmptyList />}
        </FlexColumn>
      </FlexColumn>
    </Loading>
  );
};
