import React from 'react';
import {
  FullHeightContainer,
  SpinnerComponent,
  FlexStretchItem,
  FlexRow,
  Icon,
  DeleteSVG,
} from '../../../../';

import styles from './delete-placeholder.module.scss';
import classnames from 'classnames';

export interface DeletePlaceholderProps {}

export const DeletePlaceholder: React.FC<DeletePlaceholderProps> = ({
  children,
}) => {
  return (
    <FlexRow className={styles.deletePlaceholderContainer}>
      <Icon SvgComponent={DeleteSVG} className={styles.icon} />
    </FlexRow>
  );
};
