import React, { useRef, useEffect, useState } from 'react';
import {
  DefaultButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalSize,
  PrimaryButton,
  CustomGrid,
  FlexColumn,
  CustomGridSize,
  Icon,
  DisclaimerSVG,
} from '../../..';
import classnames from 'classnames';
import styles from './modal-disclaimer.module.scss';

interface ModalDisclaimerProps {
  isOpen: boolean;
  title?: string | JSX.Element;
  body?: string | JSX.Element;
  footer?: string | JSX.Element;
  size?: ModalSize;
  className?: string;
  confirmDisabledOnStart?: boolean;
  confirmDisableDelay?: number;
  onConfirm?: () => void;
  onDismiss?: () => void;
}

export const ModalDisclaimer: React.FunctionComponent<ModalDisclaimerProps> = ({
  isOpen,
  title,
  body,
  footer,
  children,
  size = ModalSize.SMALL,
  className,
  confirmDisabledOnStart = false,
  confirmDisableDelay = 3000,
  onConfirm,
  onDismiss,
}) => {
  const [confirmDisabled, setConfirmDisabled] = useState(
    confirmDisabledOnStart
  );
  const timeout = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (isOpen) {
      timeout.current = setTimeout(() => {
        setConfirmDisabled(false);
      }, confirmDisableDelay);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        setConfirmDisabled(confirmDisabledOnStart);
      }
    };
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      size={size}
      className={classnames(className, styles.modalDisclaimer)}
      autoFocus={true}
    >
      <ModalHeader data-private>{title}</ModalHeader>
      <ModalBody>
        <FlexColumn data-testid="modal-message">
          <CustomGrid autoColumnSize={CustomGridSize.MAX_CONTENT}>
            <FlexColumn data-testid="modal-icon">
              <Icon className={styles.icon} SvgComponent={DisclaimerSVG} />
            </FlexColumn>
            <FlexColumn className={styles.message} data-testid="modal-message">
              {children ? children : body}
            </FlexColumn>
          </CustomGrid>
        </FlexColumn>
      </ModalBody>
      <ModalFooter>
        {footer ? (
          footer
        ) : (
          <>
            <PrimaryButton
              onClick={onConfirm}
              data-testid="modal-confirm"
              className={'order-2'}
              disabled={confirmDisabled}
            >
              {`Accept`}
            </PrimaryButton>
            <DefaultButton onClick={onDismiss} data-testid="modal-dismiss">
              {`Cancel`}
            </DefaultButton>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};
