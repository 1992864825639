import React, { MouseEvent } from 'react';
import classnames from 'classnames';

import { ButtonType } from '../button';

import { Icon } from './icon';
import iconButtonStyles from './icon-button.module.scss';

interface IconButtonProps {
  SvgComponent?: React.SFC<React.SVGProps<SVGSVGElement>>;
  className?: string;
  name?: string;
  type?: ButtonType;
  onClick?: (e: MouseEvent) => void;
  onMouseDown?: (e: MouseEvent) => void;
  onFocus?: (e: React.FocusEvent<HTMLButtonElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  iconProps?: React.HTMLAttributes<SVGElement>;
  tabIndex?: number;
}

export const IconButton: React.FunctionComponent<IconButtonProps> = ({
  SvgComponent,
  type,
  className,
  name,
  onClick,
  onMouseDown,
  onBlur,
  onFocus,
  disabled,
  iconProps: { className: iconClassName, ...iconProps } = {},
  tabIndex,
  ...rest
}) => {
  return (
    <button
      type={type || 'button'}
      onMouseDown={onMouseDown}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        if (onClick) {
          onClick(e);
        }
      }}
      onFocus={(e: React.FocusEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (onFocus) {
          onFocus(e);
        }
      }}
      onBlur={(e: React.FocusEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (onBlur) {
          onBlur(e);
        }
      }}
      name={name}
      className={classnames(iconButtonStyles.iconButton, className)}
      disabled={disabled}
      tabIndex={tabIndex}
      {...rest}
    >
      <Icon
        SvgComponent={SvgComponent}
        className={classnames(iconButtonStyles.icon, iconClassName)}
        {...iconProps}
      />
    </button>
  );
};
