import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  FlexColumn,
  ModalSuccess,
  ModalError,
  DefaultButton,
  PrimaryButton,
  AlertType,
  Loading,
  AlertModel,
  DismissableAlert,
  Icon,
  NoCameraSVG,
  CameraSVG,
  FlexRow,
  CheckboxSwitch,
  IconButton,
  Label,
  QrReaderV2,
  OrientationLock,
} from '../../shared/design';
import { QrCodeFormat } from '../../shared/api/qr/qr.model';
import {
  mapQRCodeToGOSForm,
  isGosFormEqual,
} from '../../shared/api/qr/qr.mapper';
import {
  GOSForm,
  GOSFormHttp,
  HttpRequestState,
} from '../../shared/api/signature/signature.model';
import * as LocalStorage from '../../shared/api/common/local-storage';
import { ModalState, WindowOrientation } from '../common/common.model';
import { getAcuitasNameInCorrectFormat } from '../../shared/utils/acuitas-name-to-normal';
import { detectBrowser } from '../../shared/utils/browser';
import { detectOS } from '../../shared/utils/system';
import {
  BrowserType,
  OperatingSystemType,
} from '../../shared/design/components/common.model';
import {
  VIBRATION_ON_SCAN_ERROR_PATTERN,
  VIBRATION_ON_SCAN_SUCCESS_PATTERN,
  CAMERA_SCANNER_START_LOAD_DELAY,
  CAMERA_DELAY,
  CAMERA_RESOLUTION,
} from './qr-scan.options';
import { DATABASE_INITIALIZATION_STORE_KEY } from '../initialize/initialize.options';
import { InitializationOptions } from '../../shared/api/authorization/authorization.model';
import {
  decrypt,
  cipherOcuco,
} from '../../shared/api/common/encryption/encryption';
import classnames from 'classnames';
import styles from './qr-scan.module.scss';
import {
  MediaStreamErrorType,
  MediaStreamError,
} from '../../shared/design/components/qr-reader-v2/qr-reader-v2.model';
import LogRocket from 'logrocket';

const ALERT_DURATION = 3000;

interface LegacyModeState {
  enabled: boolean;
  alertMessage: string;
}

interface QrScanContainerProps {
  scanHandle?: (qrData: string) => void;
  onCameraAccessDenied?: () => void;
  onLegacyModeButtonClick?: () => void;
}

export const QrScanContainer: React.FC<QrScanContainerProps> = ({
  scanHandle,
  onCameraAccessDenied,
}) => {
  const history = useHistory();
  const [modalSuccessState, setModalSuccessState] = useState<ModalState>({
    isOpen: false,
  });
  const [modalErrorState, setModalErrorState] = useState<ModalState>({
    isOpen: false,
  });
  const [currentScannedGOSForm, setCurrentScannedGOSForm] = useState<GOSForm>();
  const [multiScanModeEnabled, setMultiScanModeEnabled] = useState(false);
  const [dismissableAlert, setDismissableAlert] = useState<AlertModel>({
    type: AlertType.ERROR,
    isShown: false,
    message: 'Error',
  });
  const [loading, setLoading] = useState(true);
  const [cameraAccessDenied, setCameraAccessDenied] = useState(false);
  const [legacyMode, setLegacyMode] = useState<LegacyModeState>({
    enabled: false,
    alertMessage: `Camera has failed to load or is not supported on this device. Try another browser otherwise
    capture a photo of QR code or upload existing one using the button below.`,
  });
  const [cameraModeNotAvailable, setCameraModeNotAvailable] = useState(false);

  const timeout = useRef<NodeJS.Timeout>();
  const qrLoadedTimeout = useRef<NodeJS.Timeout>();

  const qrReaderInputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    const browserType = detectBrowser();
    const osType = detectOS();

    let browserAllowed = false;
    switch (browserType) {
      case BrowserType.CHROME:
      case BrowserType.MOZILLA:
      case BrowserType.SAFARI:
      case BrowserType.OPERA:
        browserAllowed = true;
        break;
    }

    if (
      (osType === OperatingSystemType.IOS ||
        osType === OperatingSystemType.MAC) &&
      browserType !== BrowserType.SAFARI
    ) {
      setLegacyMode({
        enabled: true,
        alertMessage: `Camera scanner not supported. If you are using an Apple device,
        please switch to Safari browser in order to gain an access to the camera scanner.`,
      });
    } else {
      setLegacyMode({
        ...legacyMode,
        enabled: !browserAllowed,
      });
    }

    if (legacyMode.enabled && qrReaderInputRef.current) {
      qrReaderInputRef.current.addEventListener(
        'loadstart',
        onImageLoadStartHandler
      );

      if (qrReaderInputRef) {
        // Ensure that value is empty on initialize
        qrReaderInputRef.current.value = '';
      }
    }

    return () => {
      if ('vibrate' in navigator) {
        navigator.vibrate(0);
      }

      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      if (qrLoadedTimeout.current) {
        clearTimeout(qrLoadedTimeout.current);
      }

      if (legacyMode.enabled && qrReaderInputRef.current) {
        qrReaderInputRef.current.removeEventListener(
          'loadstart',
          onImageLoadStartHandler
        );
      }
    };
  }, []);

  useEffect(() => {
    if (legacyMode.enabled) {
      setLoading(false);
      if (legacyMode.alertMessage !== '') {
        // At this point legacy mode was enabled, because
        // some error has occured
        setDismissableAlert({
          isShown: true,
          type: AlertType.ERROR,
          message: legacyMode.alertMessage,
        });

        setCameraModeNotAvailable(true);
      }
    }
  }, [legacyMode]);

  const onQrCodeScanHandler = (data: string | null) => {
    onQrCodeScanHandlerAsync(data);
  };

  const onQrCodeScanHandlerAsync = async (data: string | null) => {
    setLoading(false);
    if (legacyMode.enabled && !data) {
      vibrationError();
      setModalErrorState({
        isOpen: true,
        title: 'No QR code',
        message:
          'QR code not detected. Please ensure that QR code is on the image and is not blurred.',
      });
      return;
    }

    if (modalSuccessState.isOpen || modalErrorState.isOpen || !data) {
      return;
    }

    if (scanHandle) {
      scanHandle(data);
      return;
    }
    try {
      const qrCodeObj: QrCodeFormat = JSON.parse(data);
      const gosForm = mapQRCodeToGOSForm(qrCodeObj);

      setupLogRocket(qrCodeObj, gosForm);

      // Check if business id in QR code matches the one in Local Storage
      const businessId = await getInitializationBusinessId();
      if (!businessId || businessId !== gosForm.business.customerId) {
        vibrationError();
        setModalErrorState({
          isOpen: true,
          title: `Invalid business ID`,
          message: `Business ID for scanned QR code is invalid.`,
        });
        return;
      }

      // check if form has any signatures that needs to be captured
      if (gosForm.signatures.length === 0) {
        vibrationError();
        setModalErrorState({
          isOpen: true,
          title: `No signatures`,
          message: `No signatures left to capture.`,
        });
        return;
      }

      setCurrentScannedGOSForm(gosForm);
      vibrationSuccess();
      setModalSuccessState({
        isOpen: true,
        title: 'Success',
        message: `GOS Form ${
          gosForm.formNo
        } for patient ${getAcuitasNameInCorrectFormat(
          gosForm.patient.name
        )} scanned successfuly!`,
      });
    } catch (e) {
      vibrationError();
      setModalErrorState({
        isOpen: true,
        title: 'Invalid QR Code',
        message: 'Invalid QR Code. Try once again.',
      });
    }
  };

  const setupLogRocket = (qrCode: QrCodeFormat, gosForm: GOSForm) => {
    const qrCodeStripped = {
      VisitDate: qrCode.VisitDate,
      PracticeID: qrCode.PracticeID,
      PatientSignatureParams: qrCode.PatientSignatureParams,
      PatientSignatureId: qrCode.PatientSignatureID,
      Patient2SignatureParams: qrCode.Patient2SignatureParams,
      PatientSignature2ID: qrCode.PatientSignature2ID,
      PerformerSignatureParams: qrCode.PerformerSignatureParams,
      PerformerSignatureID: qrCode.PerformerSignatureID,
      ContractorSignatureParams: qrCode.ContractorSignatureParams,
      ContractorSignatureID: qrCode.ContractorSignatureID,
      SupplierSignatureParams: qrCode.SupplierSignatureParams,
      SupplierSignatureID: qrCode.SupplierSignatureID,
    };

    const gosFormStripped = {
      visitDate: gosForm.visitDate,
      signaturesCount: gosForm.signatures.length,
      formId: gosForm.formId,
      formNo: gosForm.formNo,
      patientId: gosForm.patient.patientId,
      business: gosForm.business,
    };

    LogRocket.info('QR Code Information', {
      qrCode: JSON.stringify(qrCodeStripped),
      gosForm: JSON.stringify(gosFormStripped),
    });
  };

  const getInitializationBusinessId = async (): Promise<string | undefined> => {
    const initialziation: InitializationOptions = await LocalStorage.getFromDatabase(
      DATABASE_INITIALIZATION_STORE_KEY,
      LocalStorage.DatabaseType.INITIALIZATION
    );
    if (initialziation && initialziation.P3) {
      // app decrypt
      try {
        const businessIdAppDecrypted = await decrypt(initialziation.P3);
        if (businessIdAppDecrypted) {
          const businessIdOcucoDecrypted = await cipherOcuco(
            businessIdAppDecrypted
          );

          return businessIdOcucoDecrypted;
        }
      } catch (e) {
        setDismissableAlert({
          type: AlertType.ERROR,
          isShown: true,
          message: `An error occured while retrieving verification information.
          Please try once again.`,
        });
      }
    }

    return undefined;
  };

  const addToDatabase = async (gosForm: GOSForm) => {
    const gosFormHttp: GOSFormHttp = {
      gosForm: gosForm,
      httpRequestState: HttpRequestState.NONE,
    };
    const retVal = await LocalStorage.addToDatabase(
      gosFormHttp,
      undefined,
      isGosFormEqual
    );
    if (LocalStorage.isDatabaseError(retVal)) {
      setModalErrorState({
        isOpen: true,
        title: 'An error has occured',
        message: (retVal as LocalStorage.DatabaseError).message,
      });
    } else {
      if (!multiScanModeEnabled) {
        history.push({ pathname: '/' });
      }
    }
  };

  const processError = (error: MediaStreamErrorType) => {
    LogRocket.error(error);

    switch (error) {
      case 'NotAllowedError': {
        setCameraAccessDenied(true);
        setModalErrorState({
          isOpen: true,
          title: 'Access denied',
          message:
            'You have blocked camera access. Please update your browser settings to allow access.',
        });
        break;
      }
      case 'NotReadableError':
        setLegacyMode({
          enabled: true,
          alertMessage: `Please ensure that no apps that use the camera are
          running in the background and then restart this app.`,
        });
        break;
      case 'NoBackCamera':
        setLegacyMode({
          enabled: true,
          alertMessage: `Problem occured when accessing back camera. Make sure that your device supports it. 
          If the issue persists, please try a different web browser.`,
        });
        break;
      case 'AbortError':
      case 'NotFoundError':
      default: {
        setLegacyMode({
          ...legacyMode,
          enabled: true,
        });
        break;
      }
    }
  };

  const onConfirmModalSuccessHandler = () => {
    setModalSuccessState({ isOpen: false });

    if (currentScannedGOSForm) {
      addToDatabase(currentScannedGOSForm);
    }
  };

  const onDismissModalSuccessHandler = () => {
    setModalSuccessState({ isOpen: false });
  };

  const onConfirmModalErrorHandler = () => {
    setModalErrorState({ isOpen: false });
    if (cameraAccessDenied) {
      setLegacyMode({ enabled: true, alertMessage: '' });
      onCameraAccessDenied && onCameraAccessDenied();
    }
  };

  const onScanModeChangedHandler = () => {
    setMultiScanModeEnabled(!multiScanModeEnabled);
  };

  const onQrReaderLoadedHandler = () => {
    setLoading(false);
  };

  const onUploadHandler = () => {
    if (qrReaderInputRef.current) {
      qrReaderInputRef.current.click();
      setDismissableAlert({ ...dismissableAlert, isShown: false });
    }
  };

  const vibrationSuccess = () => {
    if ('vibrate' in navigator) {
      navigator.vibrate(VIBRATION_ON_SCAN_SUCCESS_PATTERN);
    }
  };

  const vibrationError = () => {
    if ('vibrate' in navigator) {
      navigator.vibrate(VIBRATION_ON_SCAN_ERROR_PATTERN);
    }
  };

  const onQrReaderErrorHandler = (error: MediaStreamError) => {
    processError(error.type);
  };

  const onQrImageLoadHandler = () => {};

  const onQrImageStartLoadingHandler = () => {
    setLoading(true);
  };

  const onImageLoadStartHandler = () => {
    setLoading(true);
  };

  const onScanTypeButtonClickHandler = () => {
    if (legacyMode.enabled) {
      setLoading(true);
    }
    setLegacyMode({ enabled: !legacyMode.enabled, alertMessage: '' });
  };

  const onOrientationChangeHandler = (
    currentOrientation: WindowOrientation
  ) => {
    if (currentOrientation !== WindowOrientation.LANDSCAPE) {
      setLoading(true);
    }
  };

  return (
    <>
      <DismissableAlert
        type={dismissableAlert.type}
        isShown={dismissableAlert.isShown}
        isFixed={true}
      >
        {dismissableAlert.message}
      </DismissableAlert>
      <Loading isLoading={loading}>
        <OrientationLock
          orientationLock={WindowOrientation.PORTRAIT}
          message={
            'Rotate your device. QR Camera scanner has to be used in portrait mode.'
          }
          onChange={onOrientationChangeHandler}
        >
          <FlexColumn className={styles.qrScanContainer}>
            <FlexColumn className={styles.qrScanModuleContainer}>
              <QrReaderV2
                fileInputRef={qrReaderInputRef}
                delay={CAMERA_DELAY}
                facingMode="environment"
                legacyMode={legacyMode.enabled}
                showViewFinder={true}
                onScan={onQrCodeScanHandler}
                onLoad={onQrReaderLoadedHandler}
                onError={onQrReaderErrorHandler}
                onImageLoad={onQrImageLoadHandler}
                onImageStartLoading={onQrImageStartLoadingHandler}
              />
              {legacyMode.enabled && (
                <FlexColumn className={styles.legacyModeContainer}>
                  <Icon
                    SvgComponent={NoCameraSVG}
                    className={styles.noCameraIcon}
                  />
                  <PrimaryButton
                    className={styles.uploadButton}
                    onClick={onUploadHandler}
                  >
                    {`Upload QR code`}
                  </PrimaryButton>
                </FlexColumn>
              )}
              <FlexRow className={styles.buttons}>
                {!legacyMode.enabled && (
                  <FlexColumn className={styles.scanModeButtonContainer}>
                    <CheckboxSwitch
                      checked={multiScanModeEnabled}
                      onChange={onScanModeChangedHandler}
                    />
                    <Label
                      className={styles.scanModeCaption}
                    >{`Multi Scan`}</Label>
                  </FlexColumn>
                )}
                {!cameraModeNotAvailable && (
                  <IconButton
                    className={styles.scanTypeButton}
                    iconProps={{
                      className: classnames(
                        styles.scanTypeIcon,
                        legacyMode.enabled ? styles.legacy : styles.camera
                      ),
                    }}
                    onClick={onScanTypeButtonClickHandler}
                    SvgComponent={legacyMode.enabled ? CameraSVG : NoCameraSVG}
                  />
                )}
              </FlexRow>
            </FlexColumn>
          </FlexColumn>
        </OrientationLock>
      </Loading>
      <ModalSuccess
        data-private
        title={modalSuccessState.title}
        isOpen={modalSuccessState.isOpen}
        body={modalSuccessState.message}
        footer={
          <>
            <DefaultButton
              onClick={onDismissModalSuccessHandler}
              data-testid="modal-dismiss"
            >
              {`Cancel`}
            </DefaultButton>
            <PrimaryButton
              onClick={onConfirmModalSuccessHandler}
              data-testid="modal-confirm"
            >
              {multiScanModeEnabled ? 'Ok' : 'Home Page'}
            </PrimaryButton>
          </>
        }
      >
        {modalSuccessState.message}
      </ModalSuccess>
      <ModalError
        data-private
        title={modalErrorState.title}
        isOpen={modalErrorState.isOpen}
        onConfirm={onConfirmModalErrorHandler}
      >
        {modalErrorState.message}
      </ModalError>
    </>
  );
};
