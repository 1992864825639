import React, { RefObject, useRef } from 'react';

import { ENTER_KEY } from './keys';

const elementsQuerySelector = [
  'input:not([type=hidden]):not([disabled]):not([type=radio]):not([readonly])',
  'textarea',
  '[data-radio-button="true"]',
].join(',');

const disallowedNodeTypes = ['TEXTAREA', 'BUTTON', 'A'];

export const EnterAsTab: React.FC = props => {
  const containerRef = useRef() as RefObject<HTMLDivElement>;

  const getAllElements = () => {
    const elements = containerRef.current
      ? containerRef.current.querySelectorAll(elementsQuerySelector)
      : [];
    return Array.from(elements).filter(
      (element: any) =>
        element.offsetWidth ||
        element.offsetHeight ||
        element.parentElement.offsetHeight
    );
  };

  const focusFirstElement = (elements: any[]) => {
    (elements[0] as HTMLInputElement).focus();
  };

  const shouldFocusToNextElement = (element: EventTarget) => {
    return !disallowedNodeTypes.includes((element as HTMLInputElement).tagName);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation();
    if (event.key === ENTER_KEY) {
      if (!shouldFocusToNextElement(event.target)) {
        return;
      }

      event.preventDefault();
      focusNextElement(event.target);
    }
  };

  const focusNextElement = (eventTarget?: EventTarget) => {
    const elements = getAllElements();
    const currentIndex = Array.from(elements).findIndex(
      el => el === eventTarget
    );
    const currentElement = elements[currentIndex] as HTMLInputElement;
    const currentTabIndex = currentElement.getAttribute('tabindex');
    const nextTabIndexedItem =
      currentTabIndex !== null && Number(currentTabIndex) >= 1
        ? elements
            .filter((el: any) => {
              return el.getAttribute('tabindex') > currentTabIndex;
            })
            .sort(
              (a, b) =>
                Number(a.getAttribute('tabindex')) -
                Number(b.getAttribute('tabindex'))
            )[0]
        : false;

    let nextElement: any = nextTabIndexedItem
      ? nextTabIndexedItem
      : elements[currentIndex + 1];

    if (nextElement && nextElement.type && nextElement.type === 'radio') {
      const selectedRadio = document.querySelector(
        `[name="${nextElement.name}"]:checked`
      );
      if (selectedRadio) {
        nextElement = selectedRadio;
      }
    }

    if (
      currentElement &&
      currentElement.type &&
      currentElement.type === 'radio' &&
      nextElement &&
      nextElement.type === 'radio'
    ) {
      if (currentElement.name === nextElement.name) {
        const disallowedName = currentElement.name;
        const nextElementsArray = elements.slice(currentIndex + 1);
        nextElement = nextElementsArray.find(
          (el: any) => el.name !== disallowedName
        );
      }
    }

    if (nextElement) {
      nextElement.focus();
      nextElement.select();
    } else if (elements.length) {
      focusFirstElement(elements);
    }
  };

  return (
    <div
      onKeyDown={handleKeyDown}
      ref={containerRef}
      style={{ height: '100%' }}
    >
      {props.children}
    </div>
  );
};
