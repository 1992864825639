import React, { useEffect, useState, useRef } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import {
  FlexColumn,
  CustomGrid,
  CustomGridGap,
  gridSize,
  CustomGridSize,
  FlexRow,
  IconButton,
  CloseSVG,
  RemakeSVG,
  SaveSVG,
  ModalSuccess,
  AlertType,
  Alert,
  AlertModel,
  CollapseRow,
  Icon,
  CheckSVG,
  Label,
  ModalError,
} from '../../';
import {
  SignaturePadOptionsURL,
  SignaturePadOptions,
  CanvasImageType,
  SignatureSizeGuard,
  EmbeddedDate,
} from './signature-pad.model';
import { getCanvasBlob } from '../../../utils/canvas-to-blob';
import classnames from 'classnames';
import styles from './signature-pad.module.scss';
import LogRocket from 'logrocket';

const ALERT_DURATION = 2000; // in ms

export interface SignaturePadProps {
  innerRef?: React.RefObject<ReactSignatureCanvas>;
  className?: string;
  canvasClassName?: string;
  showActionButtons?: boolean;
  isPlaceholder?: boolean;
  placeholder?: string | JSX.Element;
  signatureData?: string | Blob;
  signatureWidth?: number;
  signatureHeight?: number;
  canvasWidth?: number;
  canvasHeight?: number;
  signatureScale?: number;
  canvasImageType?: CanvasImageType;
  canvasImageQuality?: number;
  signatureOptionsURL?: SignaturePadOptionsURL;
  signaturePadOptions?: SignaturePadOptions;
  signatureSizeGuard?: SignatureSizeGuard;
  embeddedDate?: EmbeddedDate;
  disabled?: boolean;
  onClick?: () => void;
  onRemake?: () => void;
  onClose?: () => void;
  onSave?: (imgData: string | undefined) => void;
  onSaveConfirm?: () => void;
}

export const SignaturePad: React.FC<SignaturePadProps> = ({
  innerRef,
  className,
  canvasClassName,
  showActionButtons,
  isPlaceholder,
  placeholder,
  signatureData,
  signatureWidth = 500,
  signatureHeight = 200,
  canvasWidth = 500,
  canvasHeight = 200,
  signatureScale = 1,
  canvasImageType = 'image/png',
  canvasImageQuality = 1,
  signatureOptionsURL,
  signaturePadOptions,
  signatureSizeGuard = { type: 'blob', sizeLimit: 10000 },
  embeddedDate = {
    enable: true,
    fontSize: 1.0,
    locale: 'en-GB',
    offset: { x: 5, y: -5 },
  },
  disabled,
  onClick,
  onRemake,
  onClose,
  onSave,
  onSaveConfirm,
}) => {
  const signaturePadRefTemp = React.useRef<ReactSignatureCanvas>(null);
  const signaturePadRef = innerRef || signaturePadRefTemp;
  const [
    signatureSizeLimitModalOpen,
    setSignatureSizeLimitModalOpen,
  ] = useState(false);
  const [signatureCapturedModalOpen, setSignatureCapturedModalOpen] = useState(
    false
  );
  const [alert, setAlert] = useState<AlertModel>({
    type: AlertType.ERROR,
    isShown: false,
    message: 'Error',
  });

  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    resizeCanvas();

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (signaturePadRef.current) {
      if (isPlaceholder) {
        signaturePadRef.current.off();
      }

      if (signatureData) {
        drawSignature(signatureData as string);
      } else {
        signaturePadRef.current.clear();
      }
    }
  }, [isPlaceholder, signatureData]);

  useEffect(() => {
    window.addEventListener('resize', onResizeHandler);

    return () => {
      window.removeEventListener('resize', onResizeHandler);
    };
  }, []);

  const onResizeHandler = (e: Event) => {
    if (signatureData) {
      resizeCanvas();
      drawSignature(signatureData as string);
    }
  };

  const resizeCanvas = () => {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    if (signaturePadRef.current) {
      const canvas = signaturePadRef.current.getCanvas();
      canvas.width = canvas.width * ratio;
      canvas.height = canvas.height * ratio;
      canvas.getContext('2d')!.scale(ratio, ratio);
      signaturePadRef.current.clear(); // otherwise isEmpty() might return incorrect value
    }
  };

  const onRemakeHandler = () => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }

    onRemake && onRemake();
  };

  const onSaveHandler = () => {
    if (signaturePadRef.current) {
      if (signaturePadRef.current.isEmpty()) {
        if (signatureData) {
          if (timeout.current) {
            clearTimeout(timeout.current);
          }
          setAlert({
            isShown: true,
            type: AlertType.SUCCESS,
            message: 'Signature cleared',
          });
          timeout.current = setTimeout(() => {
            setAlert({ ...alert, isShown: false });
          }, ALERT_DURATION);
        } else {
          if (timeout.current) {
            clearTimeout(timeout.current);
          }
          setAlert({
            isShown: true,
            type: AlertType.ERROR,
            message: 'Capture signature',
          });
          timeout.current = setTimeout(() => {
            setAlert({ ...alert, isShown: false });
          }, ALERT_DURATION);
        }

        onSave && onSave(undefined);
        return;
      }
      setAlert({
        ...alert,
        isShown: false,
      });

      const resizedCanvas = document.createElement('canvas');
      const resizedContext = resizedCanvas.getContext('2d');

      const resizedWidth = signatureWidth * signatureScale;
      const resizedHeight = signatureHeight * signatureScale;
      resizedCanvas.width = resizedWidth;
      resizedCanvas.height = resizedHeight;

      const canvas = signaturePadRef.current.getCanvas();
      const context = canvas.getContext('2d');

      if (embeddedDate.enable) {
        const dateNow = new Date();

        const dateOptons: Intl.DateTimeFormatOptions = {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        };
        const timeOptions: Intl.DateTimeFormatOptions = {
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
        };

        const dateNowString = `${dateNow.toLocaleDateString(
          'en-GB',
          dateOptons
        )} ${dateNow.toLocaleTimeString('en-GB', timeOptions)}`;

        // IMPORTANT: Draw it on both canvases - (will show date on currently used canvas and the one saved)
        if (resizedContext && context) {
          resizedContext!.drawImage(canvas, 0, 0, resizedWidth, resizedHeight);

          const fontStyle = `lighter ${embeddedDate.fontSize}rem Calibri`;

          resizedContext.font = fontStyle;
          resizedContext.strokeText(
            dateNowString,
            embeddedDate.offset.x,
            resizedHeight + embeddedDate.offset.y
          );

          context.font = fontStyle;
          context.strokeText(
            dateNowString,
            embeddedDate.offset.x,
            canvasHeight + embeddedDate.offset.y
          );
        }
      }

      checkSignatureSizeAndSave(canvas, resizedCanvas);
    }
  };

  const checkSignatureSizeAndSave = async (
    canvas: HTMLCanvasElement,
    resizedCanvas: HTMLCanvasElement
  ) => {
    // Checks resized signature data but still saves original signature data
    if (signaturePadRef.current) {
      let signatureExceededSizeLimit = false;
      const dataURL = resizedCanvas.toDataURL(
        canvasImageType,
        canvasImageQuality
      );

      switch (signatureSizeGuard.type) {
        case 'base64':
          if (dataURL.length > signatureSizeGuard.sizeLimit) {
            signatureExceededSizeLimit = true;
          }

          LogRocket.log('Captured Signature Size', {
            signatureSize: dataURL.length,
          });

          break;
        case 'blob':
          const dataBlob = await getCanvasBlob(
            resizedCanvas,
            canvasImageType,
            canvasImageQuality
          );
          const signatureSize = dataBlob ? dataBlob.size : -1;
          if (signatureSize > signatureSizeGuard.sizeLimit) {
            signatureExceededSizeLimit = true;
          }

          LogRocket.log('Captured Signature Size', {
            signatureSize: signatureSize,
          });

          break;
      }

      const originalDataURL = canvas.toDataURL(
        canvasImageType,
        canvasImageQuality
      );

      if (signatureExceededSizeLimit) {
        !signaturePadRef.current.isEmpty() &&
          setSignatureSizeLimitModalOpen(true);
      } else {
        setSignatureCapturedModalOpen(true);
        onSave &&
          onSave(
            signaturePadRef.current.isEmpty() ? undefined : originalDataURL
          );
      }
    }
  };

  const drawSignature = (data: string) => {
    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
      signaturePadRef.current.fromDataURL(data as string, signatureOptionsURL);
    }
  };

  const onSignatureSizeLimitModalCloseHandler = () => {
    setSignatureSizeLimitModalOpen(false);

    if (signaturePadRef.current) {
      signaturePadRef.current.clear();
    }
  };

  const onSignatureCapturedModalHandler = () => {
    setSignatureCapturedModalOpen(false);

    onSaveConfirm && onSaveConfirm();
  };

  return (
    <>
      <Alert type={alert.type} isShown={alert.isShown} underNavBar={false}>
        {alert.message}
      </Alert>

      <FlexRow
        className={classnames(
          styles.signaturePadWrapper,
          disabled && styles.disableCursor
        )}
      >
        <FlexColumn
          className={classnames(
            className,
            styles.signaturePadContainer,
            isPlaceholder && styles.placeholderm,
            isPlaceholder && (signatureData ? styles.valid : styles.invalid),
            disabled && styles.disablePointer
          )}
        >
          <ReactSignatureCanvas
            data-private
            ref={signaturePadRef}
            canvasProps={{
              className: classnames(canvasClassName, styles.canvas),
              width: canvasWidth,
              height: canvasHeight,
            }}
            clearOnResize={true}
            backgroundColor={
              canvasImageType === 'image/jpeg' ? 'rgb(255, 255, 255)' : ''
            }
            {...signaturePadOptions}
          />
          {isPlaceholder && (
            <>
              <CollapseRow
                className={classnames(
                  styles.placeholderBackground,
                  signatureData ? styles.valid : styles.invalid,
                  disabled && styles.disabled
                )}
              />
              <FlexColumn
                onClick={onClick}
                className={classnames(styles.placeholder)}
              >
                <FlexColumn className={styles.placeholderContent}>
                  <Icon
                    className={classnames(
                      signatureData ? styles.iconValid : styles.iconInvalid,
                      disabled && styles.iconDisabled
                    )}
                    SvgComponent={signatureData ? CheckSVG : CloseSVG}
                  />
                  <Label className={styles.caption}>{placeholder}</Label>
                </FlexColumn>
              </FlexColumn>
            </>
          )}
        </FlexColumn>
        {showActionButtons && (
          <CustomGrid
            className={styles.actionButtonsContainer}
            rowGap={CustomGridGap.LARGE}
            rowTemplate={[
              gridSize(CustomGridSize.MIN_CONTENT),
              gridSize(CustomGridSize.MIN_CONTENT),
              gridSize(CustomGridSize.MIN_CONTENT),
            ]}
          >
            <IconButton
              className={styles.closeButton}
              iconProps={{ className: styles.closeIcon }}
              SvgComponent={CloseSVG}
              onClick={onClose}
            />
            <IconButton
              className={styles.remakeButton}
              iconProps={{ className: styles.remakeIcon }}
              SvgComponent={RemakeSVG}
              onClick={onRemakeHandler}
            />
            <IconButton
              className={styles.saveButton}
              iconProps={{ className: styles.saveIcon }}
              SvgComponent={SaveSVG}
              onClick={onSaveHandler}
            />
          </CustomGrid>
        )}
      </FlexRow>
      <ModalSuccess
        isOpen={signatureCapturedModalOpen}
        title={'Signature Captured'}
        onConfirm={onSignatureCapturedModalHandler}
      >
        Signature has been captured successfully!
      </ModalSuccess>
      <ModalError
        title="Signature size limit exceeded"
        body="Complexity of caputred signature is too high. Try to simplify it."
        isOpen={signatureSizeLimitModalOpen}
        onConfirm={onSignatureSizeLimitModalCloseHandler}
      ></ModalError>
    </>
  );
};
