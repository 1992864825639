import axios from 'axios';
import { setupAPI, setupAxiosInterceptors } from './common-api';

const baseApiAxios = axios.create({});
export const deployment = { type: '' };

export const configureBaseApi = (baseUrl: string, deploymentType: string) => {
  deployment.type = deploymentType;
  baseApiAxios.defaults.baseURL = baseUrl;
  setupAxiosInterceptors(baseApiAxios);
};

const baseApi = setupAPI(baseApiAxios);
export default baseApi;
