export interface Position {
  x: number;
  y: number;
}

export enum BrowserType {
  EDGE,
  IE,
  MOZILLA,
  OPERA,
  UC_BROWSER,
  SAMSUNG_BROWSER,
  CHROME,
  SAFARI,
  OTHER,
}

export enum OperatingSystemType {
  WINDOWS,
  LINUX,
  MAC,
  IOS,
  ANDROID,
  OTHER,
}
