import axios from 'axios';
import './styles/index.scss';
import * as serviceWorker from './serviceWorker';
import { bootstrap } from './bootstrap';

axios
  .get(process.env.REACT_APP_CONFIG_URL!)
  .then(response => bootstrap(response.data))
  .catch(err => {
    document.getElementById('root')!.innerHTML = `
      <p>
        Failed to load application config, please try refresh page, if error persists please contact administrator.
      </p>
    `;
    console.error(
      'Cannot load config from ',
      process.env.REACT_APP_CONFIG_URL,
      'due',
      err
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
