import React from 'react';
import {
  DefaultButton,
  InnerBox,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalSize,
  PrimaryButton,
  CustomGrid,
  FlexColumn,
  CustomGridSize,
  Icon,
  ModalConfirmationSVG,
} from '../../../..';
import styles from './modal-confirmation.module.scss';

interface ModalConfirmationProps {
  isOpen: boolean;
  title?: string | JSX.Element;
  body?: string | JSX.Element;
  footer?: string | JSX.Element;
  size?: ModalSize;
  className?: string;
  hasCloseButton?: boolean;
  confirmButtonCaption?: string;
  dismissButtonCaption?: string;
  onConfirm?: () => void;
  onDismiss?: () => void;
}

export const ModalConfirmation: React.FunctionComponent<ModalConfirmationProps> = ({
  isOpen,
  title,
  body,
  footer,
  children,
  size = ModalSize.SMALL,
  className,
  hasCloseButton = true,
  confirmButtonCaption = 'Yes',
  dismissButtonCaption = 'No',
  onConfirm,
  onDismiss,
}) => {
  return (
    <Modal isOpen={isOpen} size={size} className={className} autoFocus={true}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <InnerBox withOnlyVerticalPadding={true}>
          <CustomGrid autoColumnSize={CustomGridSize.MAX_CONTENT}>
            <FlexColumn data-testid="modal-icon">
              <Icon
                className={styles.icon}
                SvgComponent={ModalConfirmationSVG}
              />
            </FlexColumn>
            <FlexColumn className={styles.message} data-testid="modal-message">
              {children ? children : body}
            </FlexColumn>
          </CustomGrid>
        </InnerBox>
      </ModalBody>
      <ModalFooter>
        {footer ? (
          footer
        ) : (
          <>
            <PrimaryButton
              onClick={onConfirm}
              data-testid="modal-confirm"
              className={'order-2'}
            >
              {confirmButtonCaption}
            </PrimaryButton>
            <DefaultButton onClick={onDismiss} data-testid="modal-dismiss">
              {dismissButtonCaption}
            </DefaultButton>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};
