import { NavItem } from '../../design/components/app-bar/nav-items/nav-item.model';
import { HomeSVG, QrScanSVG } from '../../design';

import { HomeScreenModule } from './home.model';

export function mapHomeScreenModule(remote: any[]): HomeScreenModule[] {
  const homeScreenModules: HomeScreenModule[] = remote.map(item => {
    return {
      id: item.id,
      externalUrl: item.externalUrl,
      icon: item.icon,
      sortOrder: item.sortOrder,
      title: item.title,
    };
  });
  return homeScreenModules;
}

export function mapHomeScreenModuleToNavItem(input: HomeScreenModule[]) {
  const navItems: NavItem[] = input.map(item => {
    return {
      path: item.externalUrl,
      icon: MapSVGIconToIconName(item.icon),
      name: item.title,
    };
  });
  return navItems;
}

export function MapSVGIconToIconName(
  iconName: string
): React.SFC<React.SVGProps<SVGSVGElement>> | undefined {
  switch (iconName) {
    case 'home':
      return HomeSVG;
    case 'qr_scan':
      return QrScanSVG;
    default:
      return undefined;
  }
}
