import React from 'react';
import classNames from 'classnames';

import {
  ComposablePrimitive,
  ComposablePrimitivePropsBase,
} from '../composable-primitive';

import {
  GridTemplateUnit,
  CustomGridGap,
  CustomGridOptions,
  CustomGridSize,
  GridFrType,
  GridSizeType,
  GridPercentType,
} from './custom-grid-options';
import { CustomGridContext } from './custom-grid.context';
import styles from './custom-grid.module.scss';

type CustomGridProps<AsProps> = CustomGridOptions &
  ComposablePrimitive<AsProps>;

function mapGridGap(gridGap: CustomGridGap): string {
  switch (gridGap) {
    case CustomGridGap.DEFAULT:
      return 'var(--custom-grid-default-gap)';
    case CustomGridGap.SMALL:
      return 'var(--custom-grid-small-gap)';
    case CustomGridGap.XSMALL:
      return 'var(--custom-grid-xsmall-gap)';
    case CustomGridGap.LARGE:
      return 'var(--custom-grid-large-gap)';
    case CustomGridGap.MEDIUM:
      return 'var(--custom-grid-medium-gap)';
    case CustomGridGap.NONE:
      return '0';
  }
}

function mapGridSize(gridSize: CustomGridSize): string {
  switch (gridSize) {
    case CustomGridSize.EQUAL:
      return 'minmax(0, 1fr)';
    case CustomGridSize.MAX_CONTENT:
      return 'fit-content(100%)';
    case CustomGridSize.MIN_CONTENT:
      return 'min-content';
  }
}

function buildGridTemplateString(
  template: GridTemplateUnit[],
  gap?: CustomGridGap,
  specificGap?: CustomGridGap
) {
  return template
    .map((unit: GridTemplateUnit) => {
      switch (unit.type) {
        case GridFrType:
          return `${unit.value}fr`;
        case GridPercentType:
          return `calc(${unit.value}${GridPercentType} - ((${mapGridGap(
            gap || specificGap || CustomGridGap.DEFAULT
          )} * ${template.length - 1}) / ${template.length}))`;
        case GridSizeType:
          return mapGridSize(unit.value);
        default:
          console.error('invalid grid unit');
          return undefined;
      }
    })
    .join(' ');
}

function generateGridTemplateColumns(
  columnTemplate: GridTemplateUnit[] | undefined,
  columnCount: number | undefined,
  columnSize: CustomGridSize | undefined,
  gap?: CustomGridGap,
  columnGap?: CustomGridGap
): string | undefined {
  if (columnTemplate) {
    return buildGridTemplateString(columnTemplate, gap, columnGap);
  }

  if (columnCount) {
    return `repeat(${columnCount}, ${mapGridSize(
      columnSize || CustomGridSize.EQUAL
    )})`;
  }

  return undefined;
}

function generateGridTemplateRows(
  rowTemplate: GridTemplateUnit[] | undefined,
  gap?: CustomGridGap,
  rowGap?: CustomGridGap
): string | undefined {
  if (rowTemplate) {
    return buildGridTemplateString(rowTemplate, gap, rowGap);
  }

  return undefined;
}

export const CustomGrid = <AsProps extends ComposablePrimitivePropsBase>({
  children,
  style,
  gap,
  as,
  rowGap,
  columnGap,
  columnCount,
  columnTemplate,
  rowTemplate,
  columnSize,
  autoColumnSize,
  className,
  ...props
}: CustomGridProps<AsProps>) => {
  const isAutoColumn = typeof autoColumnSize === 'number';

  const RenderComponent: any = as || 'div';

  return (
    <RenderComponent
      className={classNames(styles.customGrid, className, {
        [styles.autoColumn]: isAutoColumn,
      })}
      style={{
        ...style,
        gridTemplateColumns: generateGridTemplateColumns(
          columnTemplate,
          columnCount,
          columnSize,
          gap,
          columnGap
        ),
        gridTemplateRows: generateGridTemplateRows(rowTemplate, gap, columnGap),
        rowGap: mapGridGap(gap || rowGap || CustomGridGap.DEFAULT),
        columnGap: mapGridGap(gap || columnGap || CustomGridGap.DEFAULT),
        gridAutoColumns: isAutoColumn
          ? mapGridSize(autoColumnSize!)
          : undefined,
      }}
      {...props}
    >
      <CustomGridContext.Provider
        value={{
          options: {
            gap,
            rowGap,
            columnGap,
            columnCount: columnTemplate ? columnTemplate.length : columnCount,
            columnTemplate,
            columnSize,
          } as any,
        }}
      >
        {children}
      </CustomGridContext.Provider>
    </RenderComponent>
  );
};
