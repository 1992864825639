/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useMemo,
  useRef,
  MutableRefObject,
} from 'react';
import { Input, InputProps } from 'reactstrap';
import { Omit } from 'react-router';
import classnames from 'classnames';

import styles from './text-input.module.scss';
import { assignRefs } from '../../../utils/assign-refs';
import { escapeAsCancel } from '../../../utils/escapeAsCancel';

type RemappedInputProps = Omit<InputProps, 'onChange'>;

export interface TextInputProps extends RemappedInputProps {
  value?: string;
  onChange?: (value: string) => void;
  defaultValue?: string;
  id?: string;
  name?: string;
  className?: string;
  placeholder?: string;
  placeholderIntlKey?: string;
  readonly?: boolean;
  disabled?: boolean;
  onBlur?: React.FocusEventHandler;
  isInvalid?: boolean;
  centerText?: boolean;
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  innerRef?:
    | React.RefCallback<HTMLElement | null>
    | MutableRefObject<HTMLInputElement | null>;
}

export const TextInput: React.FunctionComponent<TextInputProps> = ({
  value,
  onChange,
  id,
  name,
  className,
  defaultValue,
  placeholder,
  placeholderIntlKey,
  readonly,
  disabled,
  onBlur,
  isInvalid,
  centerText,
  onClick,
  onKeyDown,
  innerRef,
  autoComplete,
  ...rest
}) => {
  const customRender = (params = {}) => {
    const initialValue = useMemo(() => value || '', [id]);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleClick = useCallback(event => {
      event.stopPropagation();

      if (onClick) {
        onClick(event);
      }
    }, []);

    const handleKeyDown = useCallback(
      event => {
        escapeAsCancel(event, inputRef, initialValue, onChange);

        if (onKeyDown) {
          onKeyDown(event);
        }
      },
      [inputRef.current, initialValue, onKeyDown]
    );

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.target.value);
      }
    };

    return (
      <Input
        type="text"
        onClick={handleClick}
        id={id}
        name={name}
        defaultValue={defaultValue == null ? undefined : defaultValue}
        className={classnames(className, { [styles.center]: centerText })}
        bsSize={'sm'}
        value={value == null ? undefined : value}
        placeholder={placeholder}
        onChange={handleChange}
        readOnly={readonly}
        disabled={disabled}
        invalid={isInvalid}
        aria-invalid={isInvalid}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
        innerRef={assignRefs([inputRef, innerRef])}
        {...rest}
        {...params}
        autoComplete={autoComplete || 'off'}
      />
    );
  };

  return customRender();
};
