import React from 'react';

import { noop } from '../../../utils/noop';

export type ReleaseOnOpenListener = () => void;
export type OnOpenListener = () => void;
export type RegisterOnOpenListener = (
  cb: OnOpenListener
) => ReleaseOnOpenListener;

export interface ModalContextProps {
  registerOnOpen: RegisterOnOpenListener;
}

export const ModalContext = React.createContext<ModalContextProps>({
  // if no modal context then we can treat as modal opened (as nothing can be opened)
  registerOnOpen: (cb: OnOpenListener) => {
    cb();
    return noop;
  },
});
