import React from 'react';
import { Icon, IconProps } from '../../';
import classnames from 'classnames';
import styles from './icon-avatar.module.scss';

interface IconAvatarProps extends IconProps {
  className?: string;
}

export const IconAvatar: React.FC<IconAvatarProps> = ({
  className,
  ...props
}) => {
  return (
    <div className={classnames(className, styles.avatarContainer)}>
      <Icon className={styles.avatarIcon} {...props} />
    </div>
  );
};
