import React from 'react';
import classnames from 'classnames';
import { FullHeightContainer, SpinnerComponent } from '../../';
import styles from './loading.module.scss';
import { FlexColumn } from '../../primitives';

export interface LoadingProps {
  isLoading: boolean | undefined;
  className?: string;
}

export const Loading: React.FC<LoadingProps> = ({
  isLoading,
  className,
  children,
}) => {
  return (
    <>
      <SpinnerComponent
        className={classnames({ [styles.invisible]: !isLoading })}
      />
      <FlexColumn
        className={classnames(styles.childContainer, {
          [styles.invisible]: isLoading,
        })}
      >
        {children}
      </FlexColumn>
    </>
  );
};
