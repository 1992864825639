import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import {
  AppBar,
  FullHeightContainer,
  SpinnerComponent,
  HttpActions,
  ServiceWorkerActions,
} from '../shared/design';
import { withLazy } from '../shared/utils/with-lazy';
import { HomeView } from './home/home.view';

const QrScanView = withLazy(() => import('./qr-scan/qr-scan.view'));
const SignatureView = withLazy(() => import('./signature/signature.view'));

const Routes: React.FunctionComponent = () => {
  const routes = (
    <Switch>
      <Route path="/qr" exact component={QrScanView} />
      <Route path="/signature" exact component={SignatureView} />
      <Route path="/reload" render={() => <Redirect to="/" />} />
      <Route path="/" component={HomeView} />
      <Redirect to="/" />
    </Switch>
  );

  return (
    <BrowserRouter>
      <FullHeightContainer>
        <AppBar />
        <Suspense fallback={<SpinnerComponent />}>
          <HttpActions />
          <ServiceWorkerActions />
          {routes}
        </Suspense>
      </FullHeightContainer>
    </BrowserRouter>
  );
};

export default Routes;
