export interface Patient {
  patientId: string;
  name: string;
}

export enum GOSFormType {
  SIGHT_TEST = 1,
  VOUCHER = 3,
  REPLACEMENT = 4,
  PRIVATE_TEST = 5,
  VISIT = 6,
}

// Must be in the same order that will be displayed
export enum SignatureType {
  CONTRACTOR = 1,
  SUPPLIER,
  PERFORMER,
  PATIENT,
  PATIENT2,
}

export interface Signature {
  id: string;
  type: SignatureType;
  params?: string[];
  data: string | undefined;
  httpRequestState: HttpRequestState;
}

export interface SignatureAPI {
  id: string;
  signature_type: SignatureType;
  blob: Blob;
}

export interface Business {
  customerId: string;
  practiceId: string;
}

export interface GOSForm {
  business: Business;
  patient: Patient;
  formId: string;
  formNo: GOSFormType;
  visitDate: Date;
  signatures: Signature[];
}

export enum HttpRequestState {
  NONE,
  SENDING,
  COMPLETED,
  WAITING,
}

export interface GOSFormHttp {
  gosForm: GOSForm;
  httpRequestState: HttpRequestState;
}

export interface GOSFormAPI {
  business: { customer_id: string; practice_id: string };
  forms: [
    {
      patient_id: string;
      form_id: string;
      form_no: string;
      signatures: SignatureAPI[];
    }
  ];
}
