import React, { useEffect, useState, useRef } from 'react';
import { FlexColumn } from '../..';
import jsQR, { QRCode } from 'jsqr';
import { Point } from 'jsqr/dist/locator';
import {
  ViewFinder,
  Resolution,
  ViewFinderPolygon,
  MediaStreamError,
} from './qr-reader-v2.model';
import { detectOS } from '../../../utils/system';
import { OperatingSystemType } from '../common.model';
import Worker from './worker';
import { findBackCameras } from './qr-reader-v2.utils';
import classnames from 'classnames';
import styles from './qr-reader-v2.module.scss';

const CANVAS_HEIGHT_RESIZED = 640;
const CANVAS_WIDTH_RESIZED = 320;

interface QrCodeLocation {
  topRightCorner: Point;
  topLeftCorner: Point;
  bottomRightCorner: Point;
  bottomLeftCorner: Point;
  topRightFinderPattern: Point;
  topLeftFinderPattern: Point;
  bottomLeftFinderPattern: Point;
  bottomRightAlignmentPattern?: Point | undefined;
}

interface QrReaderProps {
  fileInputRef?: React.RefObject<HTMLInputElement>;
  className?: string;
  delay?: number;
  facingMode?: 'user' | 'environment';
  legacyMode?: boolean;
  resolution?: Resolution;
  viewFinder?: ViewFinder;
  showViewFinder?: boolean;
  style?: any;
  onScan?: (data: string | null) => void;
  onError?: (err: MediaStreamError) => void;
  onLoad?: () => void;
  onImageLoad?: () => void;
  onImageStartLoading?: () => void;
}

enum resolutionPair{
  'R720'= '1280'
}

export const QrReaderV2: React.FC<QrReaderProps> = ({
  fileInputRef,
  delay = 500,
  className,
  resolution = '720',
  viewFinder = {
    x: 0.1,
    y: 0.1,
    size: 0.85,
    centered: true,
  },
  legacyMode,
  showViewFinder,
  onScan,
  onError,
  onLoad,
  onImageLoad,
  onImageStartLoading,
  facingMode = 'environment',
  ...rest
}) => {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  const canvasContext = useRef<CanvasRenderingContext2D | null>();

  const viewFinderVideoSpace = useRef<ViewFinderPolygon>({
    size: 0,
    top: 0,
    bottom: 0,
    leftOffset: 0,
    rightOffset: 0,
  });
  const viewFinderScreenSpace = useRef<ViewFinderPolygon>({
    size: 0,
    top: 0,
    bottom: 0,
    leftOffset: 0,
    rightOffset: 0,
  });

  let inputRef = useRef<HTMLInputElement>(null);
  inputRef = fileInputRef || inputRef;

  const scanTimeout = useRef<NodeJS.Timeout>();
  const throttleFlag = useRef(false);
  const localStream = useRef<MediaStream>();
  const worker: Worker = useRef<Worker>();
  const imageRef = useRef<HTMLImageElement>(null);
  const isProcessing = useRef(false);
  const [clipPathPolygon, setClipPathPolygon] = useState<string>();

  const [output, setOutput] = useState<string | number>(0);

  useEffect(() => {
    if (
      'mediaDevices' in navigator &&
      'getUserMedia' in navigator.mediaDevices &&
      videoRef.current &&
      !legacyMode
    ) {
      accessCamera();
    }
    window.addEventListener('resize', onResizeHandler);

    return () => {
      window.removeEventListener('resize', onResizeHandler);

      cleanUpVideoResources();
    };
  }, [legacyMode]);

  useEffect(() => {
    worker.current = new Worker();
  }, []);

  useEffect(() => {
    calculateViewFinderPosition();
  }, [
    videoRef.current &&
      videoRef.current.videoHeight &&
      videoRef.current.videoWidth,
  ]);

  const accessCamera = async () => {
    // On desktop try to only detect a camera
    // On mobile try to detect rear camera
    let videoHeight = +resolution;
    let aspectRatio = window.innerWidth / window.innerHeight;
    if (window.innerWidth > window.innerHeight) {
      aspectRatio = window.innerHeight / window.innerWidth;
    }
    if (canvasRef.current) {
      canvasContext.current = canvasRef.current.getContext('2d');
    }

    let isDesktop = false;
    const osType = detectOS();
    switch (osType) {
      case OperatingSystemType.WINDOWS:
      case OperatingSystemType.MAC:
      case OperatingSystemType.OTHER:
        isDesktop = true;
        break;
    }
    // If desktop, try to access first available
    if (isDesktop) {
      const constraints = {
        video: {
          width: { ideal: videoHeight },
          height: { ideal: resolutionPair[`R${videoHeight}`] }
        },
      };
      const result = await queryCamera(constraints);
      if (result instanceof MediaStream) {
        await onStreamLoadedSuccessfully(result);
      } else {
        onError && onError(result);
      }
    } else {
      // On Mobile find all back cameras and select the one that is working properly
      const fakeQueryResult = await fakeQueryCamera();
      if (!(fakeQueryResult instanceof MediaStream)) {
        onError && onError(fakeQueryResult);
        return;
      }
      const availableDevices = await navigator.mediaDevices.enumerateDevices();
      const backCameras = findBackCameras(availableDevices);

      if (backCameras.length === 0) {
        // No back cameras detected
        onError && onError({ type: 'NoBackCamera' });
        return;
      }

      let queryResult = undefined;
      for (let i = 0; i < backCameras.length; i++) {
        const backCamera = backCameras[i];
        let constraints: MediaStreamConstraints = {
          video: {
            deviceId: backCamera.deviceId,
            // width: { ideal: videoHeight / aspectRatio }, // <-- For some reason it causes constraint error on some of the iPhones
            width: { ideal: resolutionPair[`R${videoHeight}`] },
            height: { ideal:  videoHeight }
          },
        };

        queryResult = await queryCamera(constraints);
        if (queryResult instanceof MediaStream) {
          // Back camera working properly, use it
          break;
        }
      }

      if (!(queryResult instanceof MediaStream)) {
        // Handle error
        // Case when all back camers were iterated through,
        // but any doesn't work properly
        onError && onError(queryResult!);
        return;
      }

      if (videoRef.current) {
        calculateViewFinderPosition();
      }
    }
  };

  const queryCamera = async (constraints: MediaStreamConstraints) => {
    return navigator.mediaDevices
      .getUserMedia(constraints)
      .then(async (stream: MediaStream) => {
        if (videoRef.current) {
          onStreamLoadedSuccessfully(stream);
        }
        return stream;
      })
      .catch((error: MediaStreamError) => {
        return error;
      });
  };

  const fakeQueryCamera = async () => {
    return navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream: MediaStream) => {
        stream.getTracks().forEach((track: MediaStreamTrack) => {
          track.stop();
        });
        return stream;
      })
      .catch((error: MediaStreamError) => {
        return error;
      });
  };

  const cleanUpVideoResources = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setVideoStream(null);
    }
    if (localStream.current) {
      localStream.current.getTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
      });
    }
  };

  const onStreamLoadedSuccessfully = async (stream: MediaStream) => {
    if (videoRef.current) {
      setVideoStream(stream);
      localStream.current = stream;
      videoRef.current.setAttribute('playsinline', 'true');
      await videoRef.current.play();
      videoRef.current.playbackRate = 0.5;
      requestAnimationFrame(drawVideoFrame);
      onLoadHandler();
    }
  };

  const setVideoStream = (stream: MediaStream | null) => {
    const preview = videoRef.current as any;
    if ('srcObject' in preview) {
      preview.srcObject = stream;
    } else if ('mozSrcObjec' in preview) {
      preview.mozSrcObject = stream;
    } else if ('createObjectURL' in window.URL) {
      preview.src = window.URL.createObjectURL(stream);
    } else if ('webkitURL' in window) {
      preview.src = window.webkitURL.createObjectURL(stream);
    } else {
      preview.src = stream;
    }
  };

  const validateQrCode = (code: QRCode | null) => {
    if (code) {
      if (
        showViewFinder &&
        !isInViewFinderBounds(code.location as QrCodeLocation)
      ) {
        return;
      }

      if (!throttleFlag.current) {
        // set timeout on scan
        onScan && onScan(code.data !== '' ? code.data : null);
        throttleFlag.current = true;
        scanTimeout.current = setTimeout(() => {
          throttleFlag.current = false;
        }, delay);
      }
    }
  };

  const processImageData = (imageData: ImageData): Promise<QRCode | null> => {
    return new Promise(async resolve => {
      if (worker.current) {
        const processed = await worker.current.processData(imageData);
        resolve(processed);
      }
    });
  };

  const drawVideoFrame = () => {
    if (!canvasContext) {
      return;
    }

    if (
      canvasRef.current &&
      videoRef.current &&
      videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA
    ) {
      // Resize the image, so it's processed faster
      canvasRef.current.height = CANVAS_HEIGHT_RESIZED;
      canvasRef.current.width = CANVAS_WIDTH_RESIZED;

      // canvasRef.current.height = videoRef.current.videoHeight;
      // canvasRef.current.width = videoRef.current.videoWidth;
      if (canvasContext.current) {
        canvasContext.current.drawImage(
          videoRef.current,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
        const imageData: ImageData = canvasContext.current.getImageData(
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );

        // process only once at the time
        if (!isProcessing.current) {
          isProcessing.current = true;
          processImageData(imageData).then((code: QRCode | null) => {
            validateQrCode(code);
            isProcessing.current = false;
          });
        }

        requestAnimationFrame(drawVideoFrame);
      }
    }
  };

  const onFileReaderLoadHandler = (event: ProgressEvent<FileReader>) => {
    if (event.target && imageRef.current) {
      imageRef.current.addEventListener('load', () => {
        if (canvasRef.current && canvasContext.current && imageRef.current) {
          canvasRef.current.width = imageRef.current.width;
          canvasRef.current.height = imageRef.current.height;

          canvasContext.current.drawImage(
            imageRef.current,
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height
          );
          const imageData: ImageData = canvasContext.current.getImageData(
            0,
            0,
            canvasRef.current.width,
            canvasRef.current.height
          );

          const code: QRCode | null = jsQR(
            imageData.data,
            imageData.width,
            imageData.height
          );

          if (code) {
            // set timeout on scan
            if (!throttleFlag.current) {
              onScan && onScan(code.data !== '' ? code.data : null);
              throttleFlag.current = true;
              scanTimeout.current = setTimeout(() => {
                throttleFlag.current = false;
              }, delay);
            }
          } else {
            onScan && onScan(null);
          }
        }
        if (inputRef.current) {
          inputRef.current.value = '';
        }
        onImageLoad && onImageLoad();
      });
      imageRef.current.src = event.target.result as string;
      onImageStartLoading && onImageStartLoading();
    } else {
      onScan && onScan(null);
    }
  };

  const calculateViewFinderPolygon = (): ViewFinderPolygon => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    if (videoRef.current) {
      width = videoRef.current.clientWidth;
      height = videoRef.current.clientHeight;
    }

    const size = 100 * viewFinder.size;
    const top = 100 * viewFinder.y;
    const bottom = viewFinder.x * height + viewFinder.size * width;
    let leftOffset = 100 * viewFinder.x;
    let rightOffset = leftOffset + size;
    if (viewFinder.centered) {
      leftOffset = 50 - size / 2;
      rightOffset = leftOffset + size;
    }

    const viewFinderPolygon: ViewFinderPolygon = {
      size: size,
      top: top,
      bottom: bottom,
      leftOffset: leftOffset,
      rightOffset: rightOffset,
    };

    return viewFinderPolygon;
  };

  const calculateViewFinderPosition = () => {
    const viewFinderPolygon = calculateViewFinderPolygon();

    const videoHeight = +resolution;
    const scaleFactor = videoHeight / window.innerHeight;
    if (videoRef.current) {
      const viewFinderScreenSize =
        (videoRef.current.clientWidth * viewFinderPolygon.size) / 100;
      const viewFinderScreenTop =
        (videoRef.current.clientHeight * viewFinderPolygon.top) / 100;
      const viewFinderScreenBottom = viewFinderPolygon.bottom;
      const viewFinderScreenLeftOffset =
        (videoRef.current.clientWidth * viewFinderPolygon.leftOffset) / 100;
      const viewFinderScreenRightOffset =
        (videoRef.current.clientWidth * viewFinderPolygon.rightOffset) / 100;

      viewFinderScreenSpace.current = {
        size: viewFinderScreenSize,
        top: viewFinderScreenTop,
        bottom: viewFinderScreenBottom,
        leftOffset: viewFinderScreenLeftOffset,
        rightOffset: viewFinderScreenRightOffset,
      };

      const viewFinderVideoSize =
        (CANVAS_WIDTH_RESIZED * viewFinderPolygon.size) / 100;
      const viewFinderVideoTop =
        (CANVAS_HEIGHT_RESIZED * viewFinderPolygon.top) / 100;
      const viewFinderVideoBottom = viewFinderPolygon.bottom;
      const viewFinderVideoLeftOffset =
        (CANVAS_WIDTH_RESIZED * viewFinderPolygon.leftOffset) / 100;
      const viewFinderVideoRightOffset =
        (CANVAS_WIDTH_RESIZED * viewFinderPolygon.rightOffset) / 100;

      viewFinderVideoSpace.current = {
        size: viewFinderVideoSize,
        top: viewFinderVideoTop,
        bottom: viewFinderVideoBottom,
        leftOffset: viewFinderVideoLeftOffset,
        rightOffset: viewFinderVideoRightOffset,
      };
    }
  };

  const calculateClipPathPolygon = () => {
    const viewFinderPolygon = calculateViewFinderPolygon();

    return `
    polygon(
      0% 0%,
      0% 100%,
      ${viewFinderPolygon.leftOffset}% 100%,
      ${viewFinderPolygon.leftOffset}% ${viewFinderPolygon.top}%,
      ${viewFinderPolygon.rightOffset}% ${viewFinderPolygon.top}%,
      ${viewFinderPolygon.rightOffset}% ${viewFinderPolygon.bottom}px,
      ${viewFinderPolygon.leftOffset}% ${viewFinderPolygon.bottom}px,
      ${viewFinderPolygon.leftOffset}% 100%,
      100% 100%,
      100% 0%
      )`;
  };

  useEffect(() => {
    const clipPathPolygonTemp = calculateClipPathPolygon();
    setClipPathPolygon(clipPathPolygonTemp);
  }, [videoRef.current]);

  const isInViewFinderBounds = (qrCodeLocation: QrCodeLocation): boolean => {
    if (viewFinderVideoSpace.current) {
      const viewFinderLocation = viewFinderVideoSpace.current;
      if (
        viewFinderLocation.top <= qrCodeLocation.topLeftCorner.y &&
        viewFinderLocation.bottom >= qrCodeLocation.bottomLeftCorner.y &&
        viewFinderLocation.leftOffset <= qrCodeLocation.topLeftCorner.x &&
        viewFinderLocation.rightOffset >= qrCodeLocation.topRightCorner.x
      ) {
        return true;
      }
    }
    return false;
  };

  const onResizeHandler = (e: Event) => {
    const clipPathPolygonTemp = calculateClipPathPolygon();
    calculateViewFinderPosition();
    setClipPathPolygon(clipPathPolygonTemp);
  };

  const onChangeInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedImg = event.target.files[0];
      const fileReader = new FileReader();
      fileReader.addEventListener('load', onFileReaderLoadHandler);
      fileReader.readAsDataURL(selectedImg);
    }
  };

  const onLoadHandler = () => {
    calculateViewFinderPosition();
    onLoad && onLoad();
  };

  return (
    <FlexColumn className={classnames(styles.qrReaderContainer)}>
      {!legacyMode ? (
        <>
          {showViewFinder && (
            <>
              <div
                className={styles.viewFinder}
                style={{
                  clipPath: clipPathPolygon,
                }}
              />
              <div
                className={styles.box}
                style={{
                  top: viewFinderScreenSpace.current.top,
                  left: viewFinderScreenSpace.current.leftOffset,
                  width: viewFinderScreenSpace.current.size,
                  height: viewFinderScreenSpace.current.size,
                }}
              >
                <div
                  className={styles.scanner}
                  style={{
                    top: viewFinderScreenSpace.current.top,
                    width: viewFinderScreenSpace.current.size,
                  }}
                />
              </div>
              <div
                className={styles.caption}
                style={{
                  top: viewFinderScreenSpace.current.bottom,
                }}
              >
                {`Place the code inside the frame`}
              </div>
            </>
          )}
          <video className={styles.video} ref={videoRef} />
        </>
      ) : (
        <input
          ref={inputRef}
          className={styles.fileInput}
          type="file"
          accept="image/*"
          onChange={onChangeInputHandler}
        />
      )}
      <canvas className={styles.canvas} ref={canvasRef} />
      <img className={styles.qrImagePreview} ref={imageRef} alt="" />
    </FlexColumn>
  );
};
