import { CanvasImageType } from '../design';

export const getCanvasBlob = (
  canvas: HTMLCanvasElement,
  type: CanvasImageType,
  quality: number
) => {
  return new Promise<Blob | null>((resolve, reject) => {
    canvas.toBlob(
      blob => {
        resolve(blob);
      },
      type,
      quality
    );
  });
};
