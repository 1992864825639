import React from 'react';
import { FlexColumn } from '../../../';
import classnames from 'classnames';
import styles from './no-search-match.module.scss';

interface NoSearchMatchProps {
  className?: string;
}

export const NoSearchMatch: React.FC<NoSearchMatchProps> = ({ className }) => {
  return (
    <FlexColumn className={classnames(className, styles.noSearchMatch)}>
      No Patients were found matching your search. Try modifying your search
      criteria.
    </FlexColumn>
  );
};
