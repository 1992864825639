import * as LocalStorage from '../../shared/api/common/local-storage';
import { AuthorizationError } from '../../shared/api/authorization/authorization.model';

export const clearAuthorizationDatabase = () => {
  LocalStorage.clearDatabase(LocalStorage.DatabaseType.INITIALIZATION);
  LocalStorage.clearDatabase(LocalStorage.DatabaseType.AUTHORIZATION);
};

export const mapAuthorizationErrorToMessage = (
  errorType: AuthorizationError
): string => {
  let errorMsg = `Unknown error has occured.
  Please restart the application.`;
  switch (errorType) {
    case AuthorizationError.DATABASE:
      errorMsg = `An error has occured while retrieving authorization information.
    Please repeat the process once again.`;
      break;
    case AuthorizationError.ENCRYPTION:
      errorMsg = `Internal error has occured.
      Please restart the application.`;
      break;
  }
  return errorMsg;
};
