import React from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { FlexRow } from '../../../primitives/flex/flex-row';
import { IconText } from '../../icon-text';

import { NavItem } from './nav-item.model';

interface NavItemProps {
  link: NavItem;
  className?: string;
  isApiTranslated?: boolean;
}

export const NavItemComponent: React.FunctionComponent<NavItemProps> = ({
  link: { path, name, exact, icon },
  className,
}) => (
  <li className={classNames('nav-item', className)}>
    <FlexRow
      as={NavLink}
      to={path}
      exact={exact}
      className="nav-link"
      activeClassName="active"
      data-test-el="nav-link"
    >
      <IconText icon={icon}>{name}</IconText>
    </FlexRow>
  </li>
);
