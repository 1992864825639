import React from 'react';

import { CustomGridOptions } from './custom-grid-options';

export interface CustomGridContextModel {
  options: CustomGridOptions;
}

export const CustomGridContext = React.createContext<CustomGridContextModel>({
  options: {},
});
