import React from 'react';
import classNames from 'classnames';

import {
  ComposablePrimitive,
  ComposablePrimitivePropsBase,
} from './composable-primitive';

interface PrimitiveBuilderOptions {
  defaultEl: string | React.ComponentType;
}

export const buildPrimitive = <Params extends string>(
  primitiveClassName: string,
  conditionalClassNames: Record<Params, string>,
  options: PrimitiveBuilderOptions = {
    defaultEl: 'div',
  }
) => {
  const Primitive = <AsProps extends ComposablePrimitivePropsBase>(
    {
      as,
      className,
      ...props
    }: Partial<Record<Params, boolean>> & ComposablePrimitive<AsProps>,
    forwardRef: any
  ) => {
    const composeProps: any = {};
    const RenderComponent = as || options.defaultEl;

    // if defaultEl is a component then delegate composing to it
    if (options.defaultEl && typeof options.defaultEl !== 'string' && as) {
      composeProps.as = as;
    }

    const propsCopy = { ...props };
    const conditionalClassNameMap =
      conditionalClassNames &&
      Object.keys(conditionalClassNames).reduce((acc, curr) => {
        acc[conditionalClassNames[curr]] = props[curr];
        delete propsCopy[curr];
        return acc;
      }, {});

    return (
      <RenderComponent
        className={classNames(
          primitiveClassName,
          className,
          conditionalClassNameMap
        )}
        ref={forwardRef}
        {...(propsCopy as any)}
        {...composeProps}
      />
    );
  };

  return React.forwardRef(Primitive) as typeof Primitive;
};
