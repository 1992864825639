import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { decrypt, cipherOcuco } from '../encryption/encryption';
import * as LocalStorage from '../local-storage';
import { DATABASE_INITIALIZATION_STORE_KEY } from '../../../../modules/initialize/initialize.options';
import { InitializationOptions } from '../../authorization/authorization.model';

export const setupAxiosInterceptors = (apiAxios: AxiosInstance) => {
  const onRequestSuccess = async (config: AxiosRequestConfig) => {
    const initializationOptions: InitializationOptions = await LocalStorage.getFromDatabase(
      DATABASE_INITIALIZATION_STORE_KEY,
      LocalStorage.DatabaseType.INITIALIZATION
    );
    if (initializationOptions && initializationOptions.P1) {
      const appDecryptedSubscriptionKey = await decrypt(
        initializationOptions.P1
      );
      if (appDecryptedSubscriptionKey) {
        const ocucoDecryptedSubscriptionKey = await cipherOcuco(
          appDecryptedSubscriptionKey
        );
        config.headers.subscription_key = `${ocucoDecryptedSubscriptionKey}`;
      }
    }

    return config;
  };

  const onResponseSuccess = (response: AxiosResponse) => response;

  const onResponseError = async (error: any) => {
    const { response, config } = error;

    const status = error.status || (response && response.status);

    if (!status) {
      return Promise.reject(error);
    }

    return Promise.reject(error);
  };

  apiAxios.interceptors.request.use(onRequestSuccess);
  apiAxios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export const setupAPI = (apiAxios: AxiosInstance) => {
  return apiAxios;
};
