import React, { MouseEventHandler, FocusEvent } from 'react';
import {
  Button as BootstrapButton,
  ButtonProps as BootstrapButtonProps,
} from 'reactstrap';
import classnames from 'classnames';

import buttonStyles from './button.module.scss';

export enum ButtonColor {
  // bootstrap has no default button, so it's custom one
  DEFAULT = 'default',

  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LINK = 'link',
}

export enum ButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
  // CANCEL = 'cancel',
  RESET = 'reset',
}

export enum ButtonSize {
  XSMALL = 'xs',
  SMALL = 'sm',
  DEFAULT = '',
}

export interface ButtonProps extends BootstrapButtonProps {
  color?: ButtonColor;
  className?: string;
  name?: string;
  disabled?: boolean;
  type?: ButtonType;
  to?: string;
  onClick?: MouseEventHandler<any>;
  onFocus?: (e: FocusEvent<HTMLButtonElement>) => void;
  onBlur?: (e: FocusEvent<HTMLButtonElement>) => void;
  size?: ButtonSize;
}

export type ButtonWithColorProps = Pick<
  ButtonProps,
  Exclude<keyof ButtonProps, 'color'>
>;

export const Button: React.FunctionComponent<ButtonProps> = ({
  children,
  className,
  color,
  disabled,
  onClick,
  onFocus,
  onBlur,
  to,
  type,
  size,
  ...rest
}) => {
  const customClassNames: string[] = [];

  if (color === ButtonColor.DEFAULT) {
    customClassNames.push(buttonStyles.defaultButton);
  }

  if (color === ButtonColor.SECONDARY) {
    customClassNames.push(buttonStyles.secondaryButton);
  }

  if (color === ButtonColor.PRIMARY) {
    customClassNames.push(buttonStyles.primaryButton);
  }

  if (color === ButtonColor.LINK) {
    customClassNames.push(buttonStyles.linkButton);
  }

  const handleClick = (e: any) => {
    e.stopPropagation();
    if (onClick) {
      onClick(e);
    }
  };

  const handleFocus = (e: FocusEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onBlur) {
      onBlur(e);
    }
  };

  const buttonType = (type || ButtonType.BUTTON) as
    | 'submit'
    | 'button'
    | 'reset';

  return (
    <BootstrapButton
      color={color}
      tag={to}
      className={classnames(customClassNames, className)}
      disabled={disabled}
      type={!to ? buttonType : undefined}
      onClick={handleClick}
      onFocus={handleFocus}
      onBlur={handleBlur}
      to={to}
      size={size && ButtonSize.DEFAULT}
      {...rest}
    >
      {children}
    </BootstrapButton>
  );
};
