import React from 'react';
import classnames from 'classnames';

import { FlexColumn } from '../';

import styles from './vertical-container.module.scss';

interface VerticalContainerProps {
  footer?: React.ReactNode;
  header?: React.ReactNode;
  className?: string;
  isScrollable?: boolean;
  footerMarginTop?: FooterMarginSize;
  headerMarginBottom?: FooterMarginSize;
  as?: string | React.ComponentType;
}

export enum FooterMarginSize {
  DEFAULT,
  SMALL,
  LARGE,
}

const mapFooterMarginTop = (marginSize: FooterMarginSize) => {
  switch (marginSize) {
    case FooterMarginSize.SMALL:
      return 'var(--small-footer-margin-top)';
    case FooterMarginSize.LARGE:
      return 'var(--large-footer-margin-top)';
    case FooterMarginSize.DEFAULT:
      return 'var(--default-footer-margin-top)';
    default:
      return undefined;
  }
};

export const VerticalContainer: React.FunctionComponent<any> = ({
  children,
  footer,
  header,
  className,
  footerMarginTop,
  headerMarginBottom,
  isScrollable,
  as,
  ...props
}) => {
  const layout = (layoutChildren: React.ReactNode) => (
    <>
      <div
        style={{
          marginBottom: mapFooterMarginTop(headerMarginBottom),
        }}
      >
        {header}
      </div>
      <div
        className={classnames(styles.main, {
          [styles.scrollable]: isScrollable,
        })}
      >
        {layoutChildren}
      </div>
      <div
        style={{
          marginTop: mapFooterMarginTop(footerMarginTop),
        }}
      >
        {footer}
      </div>
    </>
  );

  const primitiveChildren =
    typeof children === 'function'
      ? (...args: any[]) => layout(children(...args))
      : layout(children);

  return (
    <FlexColumn
      as={as}
      className={classnames(styles.wrapper, className)}
      {...props}
    >
      {primitiveChildren}
    </FlexColumn>
  );
};
