export function assignRefs(
  refs: (
    | React.RefCallback<HTMLElement | null>
    | React.MutableRefObject<HTMLElement | null>
    | undefined
  )[]
) {
  return (value: any) => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        ref.current = value;
      }
    });
  };
}
