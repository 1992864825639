import React, { useState, useEffect, useRef } from 'react';
import baseApi from '../../../api/common/apis/base-api';
import { ModalError, ModalSuccess } from '../modal';
import { Alert, AlertModel, AlertType } from '../alerts';
import { ModalState } from '../../../../modules/common/common.model';
import { AxiosResponse } from 'axios';
import { HttpActionsAlertContext } from './http-actions-alert.context';

const ALERT_DURATION = 5000; // in ms
const ALERT_OFFLINE_DURATION = 3000;

export const HttpActions: React.FC = () => {
  const [modalSuccessState, setModalSuccessState] = useState<ModalState>({
    isOpen: false,
  });
  const [modalErrorState, setModalErrorState] = useState<ModalState>({
    isOpen: false,
  });

  const [alert, setAlert] = useState<AlertModel>({
    type: AlertType.ERROR,
    isShown: false,
    message: 'Error',
  });

  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    baseApi.interceptors.response.use(
      (response: AxiosResponse<any>) => {
        setAlert({
          isShown: true,
          type: AlertType.SUCCESS,
          message: 'GOS form sent succesfully.',
        });
        timeout.current = setTimeout(() => {
          setAlert({ ...alert, isShown: false });
        }, ALERT_DURATION);
        return response;
      },
      (error: AxiosResponse) => {
        if (error.config && error.config.method === 'get') {
          // Handle errors only for POST requests
          return Promise.reject(error);
        }

        if (!error.request!.status) {
          // Network error
          if (timeout.current) {
            clearTimeout(timeout.current);
          }
          setAlert({
            isShown: true,
            type: AlertType.WARNING,
            message: 'GOS form will be sent once you are online.',
          });
          timeout.current = setTimeout(() => {
            setAlert({ ...alert, isShown: false });
          }, ALERT_OFFLINE_DURATION);
        } else {
          if (timeout.current) {
            clearTimeout(timeout.current);
          }
          setAlert({
            isShown: true,
            type: AlertType.ERROR,
            message: `Failed to send GOS form. ${error.request.status}.`,
          });
          timeout.current = setTimeout(() => {
            setAlert({ ...alert, isShown: false });
          }, ALERT_DURATION);
        }
        return Promise.reject(error);
      }
    );
  }, []);

  const onConfirmModalSuccessHandler = () => {
    setModalSuccessState({ isOpen: false });
  };

  const onConfirmModalErrorHandler = () => {
    setModalErrorState({ isOpen: false });
  };

  return (
    <>
      <HttpActionsAlertContext.Provider
        value={{
          type: alert.type,
          isShown: alert.isShown,
          message: alert.message,
        }}
      ></HttpActionsAlertContext.Provider>
      <Alert type={alert.type} isShown={alert.isShown} isFixed={true}>
        {alert.message}
      </Alert>
      <ModalSuccess
        title={modalSuccessState.title}
        isOpen={modalSuccessState.isOpen}
        body={modalSuccessState.message}
        onConfirm={onConfirmModalSuccessHandler}
      >
        {modalSuccessState.message}
      </ModalSuccess>
      <ModalError
        title={modalErrorState.title}
        isOpen={modalErrorState.isOpen}
        onConfirm={onConfirmModalErrorHandler}
      >
        {modalErrorState.message}
      </ModalError>
    </>
  );
};
