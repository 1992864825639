import React, { useEffect, useState } from 'react';
import {
  InnerBox,
  CustomGrid,
  CustomGridSize,
  FlexColumn,
  CustomGridGap,
  DefaultButton,
  SpinnerComponent,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalSize,
} from '../../../';
import { fetchApiVersionDetails } from '../../../../api/home/home.api';
import { ApiVersionDetails } from '../../../../api/home/home.model';
import { deployment } from '../../../../api/common/apis';

interface AppVersionProps {
  isOpen: boolean;
  onDismiss: () => void;
}

export const AppVersionComponent: React.FC<AppVersionProps> = ({
  isOpen,
  onDismiss,
}) => {
  const [apiVersionDetails, setApiVersionDetails] = useState<
    ApiVersionDetails
  >();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!isOpen) return;
    setIsLoading(true);
    fetchApiVersionDetails().then((data: ApiVersionDetails) => {
      setApiVersionDetails(data);
      setIsLoading(false);
    });
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} size={ModalSize.SMALL} autoFocus={true}>
      <ModalHeader>{`App Version`}</ModalHeader>
      <ModalBody>
        {isLoading && <SpinnerComponent />}
        <InnerBox withOnlyVerticalPadding={true}>
          <CustomGrid
            columnCount={2}
            columnSize={CustomGridSize.MAX_CONTENT}
            columnGap={CustomGridGap.SMALL}
          >
            <FlexColumn className={'font-weight-bold'}>
              {`UI Version No:`}
            </FlexColumn>
            <FlexColumn>{process.env.REACT_APP_UI_VERSION_NUMBER}</FlexColumn>
            <FlexColumn className={'font-weight-bold'}>
              {`UI Deployment Type:`}
            </FlexColumn>
            <FlexColumn>{deployment.type}</FlexColumn>
            {apiVersionDetails && (
              <>
                <FlexColumn className={'font-weight-bold'}>
                  {`API Version No:`}
                </FlexColumn>
                <FlexColumn>{`${apiVersionDetails.build}`}</FlexColumn>
                <FlexColumn className={'font-weight-bold'}>
                  {`API Deployment Type:`}
                </FlexColumn>
                <FlexColumn>{apiVersionDetails.deploymentType}</FlexColumn>
              </>
            )}
          </CustomGrid>
        </InnerBox>
      </ModalBody>
      <ModalFooter>
        <DefaultButton onClick={onDismiss}>{`Ok`}</DefaultButton>
      </ModalFooter>
    </Modal>
  );
};
