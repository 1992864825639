import React from 'react';
import classnames from 'classnames';
import styles from './decorator-paragraph.module.scss';

interface DecoratorParagraphProps {
  className?: string;
}

export const DecoratorParagraph: React.FC<DecoratorParagraphProps> = ({
  className,
  children,
}) => {
  return (
    <div className={classnames(className, styles.decoratorParagraph)}>
      {children}
    </div>
  );
};
