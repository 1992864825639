export const ARROW_DOWN_KEY = 'ArrowDown';
export const ARROW_UP_KEY = 'ArrowUp';
export const ARROW_LEFT_KEY = 'ArrowLeft';
export const ARROW_RIGHT_KEY = 'ArrowRight';
export const ENTER_KEY = 'Enter';
export const PAGE_UP_KEY = 'PageUp';
export const PAGE_DOWN_KEY = 'PageDown';
export const COMMA_KEY = ',';
export const DOT_KEY = '.';
export const NUMERIC_KEYS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
export const MINUS_KEY = '-';
export const PLUS_KEY = '+';
export const MATH_SIGNS = [PLUS_KEY, MINUS_KEY];
export const TAB_KEY = 'Tab';
export const ESCAPE_KEY = 'Escape';
export const DELETE_KEY = 'Delete';
export const BACKSPACE_KEY = 'Backspace';
export const SPACE_KEY = ' ';
export const A_KEY = 'A';
export const P_KEY = 'P';
export const S_KEY = 's' || 'S';
