import { useHistory } from 'react-router-dom';
import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  FlexColumn,
  SearchableList,
  ListItem,
  Icon,
  CheckSVG,
  AlertType,
  AlertModel,
  DismissableAlert,
  DeletePlaceholder,
  ModalError,
  Alert,
  PrimaryButton,
  UploadSVG,
  CustomGrid,
  CustomGridSize,
} from '../../shared/design';
import { DatabaseEntry } from '../../shared/api/common/local-storage/local-storage.model';
import {
  Signature,
  HttpRequestState,
  GOSForm,
} from '../../shared/api/signature/signature.model';
import * as LocalStorage from '../../shared/api/common/local-storage/local-storage';
import { EmptyPatientsList } from './empty-patients-list/empty-patients-list.component';
import { ModalConfirmation } from '../../shared/design/components/modal/modal-types/modal-confirmation/modal-confirmation.component';
import { getAcuitasNameInCorrectFormat } from '../../shared/utils/acuitas-name-to-normal';
import { VerticalContainer } from '../../shared/design/primitives/vertical-container';
import { ModalState } from '../common/common.model';
import { postGosForm } from '../../shared/api/signature/signature.api';
import { decryptBatch } from '../../shared/api/common/encryption/encryption';
import { scaleDownUrl } from '../../shared/utils/scale-down-url';
import {
  SIGNATURE_WIDTH,
  SIGNATURE_HEIGHT,
  CANVAS_IMAGE_TYPE,
  CANVAS_IMAGE_QUALITY,
} from '../signature/patient-signature-pad/patient-signature-pad.options';
import {
  mapGOSFormToAPI,
  mapGosFormAPIToFormData,
} from '../../shared/api/signature/signature.to.mapper';
import {
  findIndexOfDatabaseEntry,
  resetSignaturesForGosForm,
  dateWithoutTime,
} from '../utils';
import { HttpActionsAlertContext } from '../../shared/design/components/http-actions/http-actions-alert.context';
import { ServiceWorkerAlertContext } from '../../shared/design/components/servicer-worker-actions/service-worker-alert.context';
import { isDatabaseError } from '../../shared/api/common/local-storage/utils';
import classnames from 'classnames';
import styles from './home.module.scss';
import LogRocket from 'logrocket';

const ALERT_DURATION = 3000;

interface RemovedDatabaseEntry {
  item: DatabaseEntry | undefined;
  index: number;
}

interface DatabaseEntryListItem {
  dbEntry: DatabaseEntry;
  hasLabel?: boolean;
  labelCaption?: string;
}

interface SearchablePatientListState {
  dbEntries: DatabaseEntry[];
  displayedDbEntries: DatabaseEntry[];
}

export const HomeContainer: React.FC = () => {
  const history = useHistory();

  const [list, setList] = useState<SearchablePatientListState>({
    dbEntries: [],
    displayedDbEntries: [],
  });
  const [loading, setLoading] = useState(true);
  const [dismissableAlert, setDismissableAlert] = useState<AlertModel>({
    type: AlertType.ERROR,
    isShown: false,
    message: 'Error',
  });
  const [alert, setAlert] = useState<AlertModel>({
    type: AlertType.ERROR,
    isShown: false,
    message: 'Error',
  });
  const [removedDbEntry, setRemovedDbEntry] = useState<RemovedDatabaseEntry>({
    item: undefined,
    index: -1,
  });
  const [modalConfirmRemoveOpen, setModalConfirmRemoveOpen] = useState(false);
  const [modalErrorState, setModalErrorState] = useState<ModalState>({
    isOpen: false,
  });
  const [gosFormsNeedToBeSent, setGosFormsNeedToBeSent] = useState(false);
  const [isSendingGosForms, setSendingGosForms] = useState(false);

  const timeout = useRef<NodeJS.Timeout>();

  const serviceWorkerAlertContext = useContext(ServiceWorkerAlertContext);
  const httpActionsAlertContext = useContext(HttpActionsAlertContext);

  useEffect(() => {
    prepareDisplayData();
    window.addEventListener('online', networkStatusHandler);
    window.addEventListener('offline', networkStatusHandler);

    return () => {
      window.removeEventListener('online', networkStatusHandler);
      window.removeEventListener('offline', networkStatusHandler);
    };
  }, []);

  useEffect(() => {
    if (gosFormsNeedToBeSent) {
      handleDatabaseEntryHttpState();
      setGosFormsNeedToBeSent(false);
    }
  }, [gosFormsNeedToBeSent]);

  useEffect(() => {
    if (serviceWorkerAlertContext || httpActionsAlertContext) {
      setDismissableAlert({ ...dismissableAlert, isShown: false });
      setAlert({ ...alert, isShown: false });
    }
  }, [serviceWorkerAlertContext, httpActionsAlertContext]);

  useEffect(() => {
    if (isSendingGosForms) {
    }
  }, [isSendingGosForms]);

  const networkStatusHandler = () => {
    if (navigator.onLine) {
      setGosFormsNeedToBeSent(true);
    }
  };

  const prepareDisplayData = async () => {
    const dbEntries = await getAllDatabaseEntries();
    // Make list available to render first
    if (dbEntries) {
      setLoading(false);
      const sortedGosForms = sortGosFormsByDateThenAlpha(dbEntries);
      setList({
        dbEntries: [...sortedGosForms],
        displayedDbEntries: [...sortedGosForms],
      });

      setGosFormsNeedToBeSent(true);
    }
  };

  const handleDatabaseEntryHttpState = async (
    dbEntries?: DatabaseEntry[],
    sendSingleSignatures?: boolean
  ) => {
    const dbEntriesToBeRemoved: DatabaseEntry[] = [];
    const dbEntriesToBeSent: DatabaseEntry[] = [];

    // Get current state of list and pass to other functions
    // Because we operate on the same state we need a way to have most
    // up to date arrays that represents displayed data
    const dbEntriesList = [...list.dbEntries];
    const filteredDbEntriesList = [...list.displayedDbEntries];

    if (!dbEntries) {
      dbEntries = await getAllDatabaseEntries();
    }

    LogRocket.debug('Starting to send data', dbEntries);

    if (dbEntries) {
      if (!sendSingleSignatures) {
        dbEntries.forEach((dbEntry: DatabaseEntry) => {
          const signaturesCount = dbEntry.data.gosForm.signatures.length;

          for (let i = 0; i < signaturesCount; i++) {}

          switch (dbEntry.data.httpRequestState) {
            case HttpRequestState.COMPLETED:
              dbEntriesToBeRemoved.push(dbEntry);
              break;
            case HttpRequestState.WAITING:
              // Ensure that only forms with all signature captured are sent
              if (isSigned(dbEntry)) {
                dbEntriesToBeSent.push(dbEntry);
              } else {
                updateDbEntryHttpRequestState(
                  dbEntriesList,
                  filteredDbEntriesList,
                  dbEntry,
                  HttpRequestState.NONE
                );
              }
              break;
            case HttpRequestState.SENDING:
              // Something interrupted sending process, should not happen
              if (isSigned(dbEntry)) {
                updateDbEntryHttpRequestState(
                  dbEntriesList,
                  filteredDbEntriesList,
                  dbEntry,
                  HttpRequestState.WAITING
                );
                dbEntriesToBeSent.push(dbEntry);
              } else {
                updateDbEntryHttpRequestState(
                  dbEntriesList,
                  filteredDbEntriesList,
                  dbEntry,
                  HttpRequestState.NONE
                );
              }
              break;
          }
        });
      } else {
        // User pressed 'Send' button
        // That will force send forms that have at least one signature
        dbEntries.forEach((dbEntry: DatabaseEntry) => {
          // has to have a signature, which
          const hasSignature = !dbEntry.data.gosForm.signatures.every(
            (signature: Signature) =>
              !(
                signature.data &&
                signature.httpRequestState !== HttpRequestState.COMPLETED
              )
          );
          if (hasSignature) {
            dbEntriesToBeSent.push(dbEntry);
          }
        });
      }

      LogRocket.debug('Entries to be removed : ', dbEntriesToBeRemoved);

      dbEntriesToBeRemoved.forEach((dbEntry: DatabaseEntry) => {
        removeFromLists(dbEntriesList, filteredDbEntriesList, dbEntry);
        removeFromDatabase(dbEntry);
      });

      LogRocket.debug('Entries removed');
      if (navigator.onLine) {
        LogRocket.debug('Device Online. Sending Signatures.');
        if (dbEntriesToBeSent.length > 0) {
          setSendingGosForms(true);
          prepareAndPost(
            dbEntriesList,
            filteredDbEntriesList,
            dbEntriesToBeSent,
            sendSingleSignatures
          ).then(() => {
            LogRocket.debug('Device Online. Sending Signatures.');
          });
        } else if (sendSingleSignatures) {
          setAlertHelper(
            AlertType.ERROR,
            `No signatures to send.`,
            ALERT_DURATION
          );
        }
      } else {
        LogRocket.debug('Device Offline !');
      }
    }
  };

  const getAllDatabaseEntries = async (): Promise<
    DatabaseEntry[] | undefined
  > => {
    const dbEntries = await LocalStorage.getAllFromDatabase();
    if (isDatabaseError(dbEntries)) {
      setList({
        dbEntries: [],
        displayedDbEntries: [],
      });
      setModalErrorState({
        title: 'Error ',
        isOpen: true,
        message:
          'Loading GOS forms from database has failed. Database has been cleared and GOS forms have to be added once again.',
      });
      LocalStorage.clearDatabase();

      return undefined;
    }
    return dbEntries as DatabaseEntry[];
  };

  const prepareAndPost = async (
    dbEntriesList: DatabaseEntry[],
    filteredDbEntriesList: DatabaseEntry[],
    dbEntriesToBeSent: DatabaseEntry[],
    sendSingleSignatures?: boolean
  ) => {
    const dbEntriesToBeSentSorted = sortGosFormsByDateThenAlpha(
      dbEntriesToBeSent
    );

    LogRocket.debug(
      'PrepareAndPost:: List of signatures to send :',
      dbEntriesToBeSentSorted
    );
    const dbEntriesToBeSendSortedCount = dbEntriesToBeSentSorted.length;
    for (let i = 0; i < dbEntriesToBeSendSortedCount; i++) {
      const dbEntry = dbEntriesToBeSentSorted[i];
      const formIdentifier = `${dbEntry.data.gosForm.formId}_${dbEntry.data.gosForm.formNo}`;
      const signaturesDecryptedAndResized: Signature[] = [];
      const signaturesData: (
        | string
        | undefined
      )[] = dbEntry.data.gosForm.signatures.map(
        (signature: Signature) => signature.data
      );
      const signaturesDataDecrypted = await decryptBatch(signaturesData);

      if (signaturesDataDecrypted) {
        const signaturesCount: number = dbEntry.data.gosForm.signatures.length;
        for (let j = 0; j < signaturesCount; j++) {
          const signature: Signature = dbEntry.data.gosForm.signatures[j];

          let signatureDataDecrypted = signaturesDataDecrypted[j];
          let signatureDataDecryptedResized = signatureDataDecrypted;
          if (signatureDataDecrypted) {
            signatureDataDecryptedResized = await scaleDownUrl(
              signatureDataDecrypted,
              SIGNATURE_WIDTH,
              SIGNATURE_HEIGHT,
              CANVAS_IMAGE_TYPE,
              CANVAS_IMAGE_QUALITY
            );
          }

          const signatureDecryptedResized: Signature = {
            ...signature,
            data: signatureDataDecryptedResized,
          };

          signaturesDecryptedAndResized.push(signatureDecryptedResized);
        }

        const gosFormAPI = mapGOSFormToAPI(
          dbEntry.data.gosForm,
          signaturesDecryptedAndResized
        );

        const formData = mapGosFormAPIToFormData(gosFormAPI);

        LogRocket.debug(
          'PrepareAndPost:: Sending GOS form :',
          formIdentifier,
          formData
        );
        setAlert({
          type: AlertType.WARNING,
          isShown: true,
          message: 'Sending GOS form...',
        });

        LogRocket.debug(
          'PrepareAndPost:: Updating status to SENDING :',
          formIdentifier
        );
        updateDbEntryHttpRequestState(
          dbEntriesList,
          filteredDbEntriesList,
          dbEntry,
          HttpRequestState.SENDING
        );
        LogRocket.debug(
          'PrepareAndPost:: Updated status to SENDING :',
          formIdentifier
        );

        LogRocket.debug('PrepareAndPost:: Sending GOS Form :', formIdentifier);
        await postGosForm(formData)
          .then(response => {
            // Sent successfully, delete gos form from database
            LogRocket.debug('PrepareAndPost:: GOS Form Sent :', formIdentifier);
            if (!sendSingleSignatures) {
              removeFromDatabase(dbEntry);
              removeFromLists(dbEntriesList, filteredDbEntriesList, dbEntry);
              LogRocket.debug(
                'PrepareAndPost:: GOS Form Deleted from database and list:',
                formIdentifier
              );
            } else {
              // Update signatures http request state wwhich were sent succesfully
              updateDbEntrySignaturesHttpRequestState(
                dbEntriesList,
                filteredDbEntriesList,
                dbEntry,
                HttpRequestState.COMPLETED
              );
              LogRocket.debug(
                'PrepareAndPost:: GOS Signature Updated as Completed :',
                formIdentifier
              );
            }

            setAlert({
              ...alert,
              isShown: false,
            });

            return response;
          })
          .catch(error => {
            LogRocket.debug(
              'PrepareAndPost:: There was an error sending signatures',
              error
            );
            let httpRequestState: HttpRequestState;
            if (error.status) {
              httpRequestState = HttpRequestState.NONE;
            } else {
              // Network error
              httpRequestState = HttpRequestState.WAITING;
            }

            updateDbEntryHttpRequestState(
              dbEntriesList,
              filteredDbEntriesList,
              dbEntry,
              httpRequestState
            );
            if (sendSingleSignatures) {
              // Reset signature's HTTP states
              updateDbEntrySignaturesHttpRequestState(
                dbEntriesList,
                filteredDbEntriesList,
                dbEntry,
                HttpRequestState.NONE
              );
            }

            setAlert({
              ...alert,
              isShown: false,
            });

            LogRocket.debug('PrepareAndPost:: Error processed');
            return error;
          });
      } else {
        // Decryption failed, database has been accessed from a different environment
        LogRocket.debug('PrepareAndPost:: Decryption Failed');
        setModalErrorState({
          isOpen: true,
          title: 'Decryption failed',
          message: `An error occured while reading signatures for patient
           ${dbEntry.data.gosForm.patient.name} GOS ${dbEntry.data.gosForm.formNo}.
           They have been reset and need to be captured again.`,
        });

        resetSignaturesForGosForm(dbEntry);

        LogRocket.debug('PrepareAndPost:: Decryption failure handled');
        return;
      }
    }

    setSendingGosForms(false);
  };

  const removeFromLists = (
    dbEntriesList: DatabaseEntry[],
    filteredDbEntriesList: DatabaseEntry[],
    dbEntry: DatabaseEntry
  ) => {
    const dbEntryIndex1 = findIndexOfDatabaseEntry(dbEntriesList, dbEntry);
    if (dbEntryIndex1 !== -1) {
      dbEntriesList.splice(dbEntryIndex1, 1);
    }

    const dbEntryIndex2 = findIndexOfDatabaseEntry(
      filteredDbEntriesList,
      dbEntry
    );
    if (dbEntryIndex2 !== -1) {
      filteredDbEntriesList.splice(dbEntryIndex2, 1);
    }

    setList({
      dbEntries: [...dbEntriesList],
      displayedDbEntries: [...filteredDbEntriesList],
    });
  };

  const updateLists = (
    dbEntriesList: DatabaseEntry[],
    filteredDbEntriesList: DatabaseEntry[],
    dbEntry: DatabaseEntry
  ) => {
    const dbEntryIndex1 = findIndexOfDatabaseEntry(dbEntriesList, dbEntry);
    if (dbEntryIndex1 !== -1) {
      dbEntriesList[dbEntryIndex1] = dbEntry;
    }

    const dbEntryIndex2 = findIndexOfDatabaseEntry(
      filteredDbEntriesList,
      dbEntry
    );
    if (dbEntryIndex2 !== -1) {
      filteredDbEntriesList[dbEntryIndex2] = dbEntry;
    }

    setList({
      dbEntries: [...dbEntriesList],
      displayedDbEntries: [...filteredDbEntriesList],
    });
  };

  const updateDbEntryHttpRequestState = async (
    dbEntriesList: DatabaseEntry[],
    filteredDbEntriesList: DatabaseEntry[],
    dbEntry: DatabaseEntry,
    httpRequestState: HttpRequestState
  ) => {
    const updatedDbEntry: DatabaseEntry = {
      ...dbEntry,
      data: {
        ...dbEntry.data,
        httpRequestState: httpRequestState,
      },
    };

    updateLists(dbEntriesList, filteredDbEntriesList, updatedDbEntry);

    await LocalStorage.updateDatabaseEntry(updatedDbEntry);
  };

  const updateDbEntrySignaturesHttpRequestState = async (
    dbEntriesList: DatabaseEntry[],
    filteredDbEntriesList: DatabaseEntry[],
    dbEntry: DatabaseEntry,
    httpRequestState: HttpRequestState
  ) => {
    // State of signatures that has data and were not completed
    // will be updated
    const updatedSignatures = dbEntry.data.gosForm.signatures.map(
      (signature: Signature) => {
        const updatedSignature: Signature = {
          ...signature,
          httpRequestState:
            signature.data &&
            signature.httpRequestState !== HttpRequestState.COMPLETED
              ? httpRequestState
              : signature.httpRequestState,
        };

        return updatedSignature;
      }
    );

    const updatedDbEntry: DatabaseEntry = {
      ...dbEntry,
      data: {
        ...dbEntry.data,
        gosForm: { ...dbEntry.data.gosForm, signatures: updatedSignatures },
      },
    };

    updateLists(dbEntriesList, filteredDbEntriesList, updatedDbEntry);

    await LocalStorage.updateDatabaseEntry(updatedDbEntry);
    LogRocket.debug(
      'UpdateDbEntrySignaturesHttpRequestState:: Signature Updated :'
    );
  };

  const onSearchHandler = (value: string) => {
    const filteredGOSFormDBEntries = list.dbEntries.filter(
      (dbEntry: DatabaseEntry) => {
        const patient = dbEntry.data.gosForm.patient;
        const patientName = `${patient.name}`;
        const gosForm = `GOS ${dbEntry.data.gosForm.formNo}`;

        return (
          patientName.toLowerCase().includes(value.toLowerCase()) ||
          gosForm.toLowerCase().includes(value.toLowerCase()) ||
          patient.patientId.toLowerCase().includes(value.toLowerCase())
        );
      }
    );

    setList({
      ...list,
      displayedDbEntries: [...filteredGOSFormDBEntries],
    });
  };

  const signaturePageButtonHandler = (dbEntryKey: string) => {
    history.push({
      pathname: '/signature',
      state: { dbEntryKey: dbEntryKey },
    });
  };

  const isSigned = (gosFormDbEntry: DatabaseEntry): boolean => {
    return gosFormDbEntry.data.gosForm.signatures.every(
      (signature: Signature) => signature.data
    );
  };

  const onSwipeLeftHandler = (dbEntry: DatabaseEntry, index: number) => {
    if (dbEntry) {
      if (index >= 0) {
        const filteredDbEntriesUpdated = [...list.displayedDbEntries];
        filteredDbEntriesUpdated.splice(index, 1);
        setList({ ...list, displayedDbEntries: filteredDbEntriesUpdated });
      }
    }
    setRemovedDbEntry({ item: dbEntry, index: index });
    setModalConfirmRemoveOpen(true);
  };

  const onModalRemoveConfirm = () => {
    setModalConfirmRemoveOpen(false);
    if (removedDbEntry.item) {
      removeFromDatabase(removedDbEntry.item);
    }
  };

  const onModalRemoveDismiss = () => {
    setModalConfirmRemoveOpen(false);
    // Revert removed item
    if (removedDbEntry.item) {
      const filteredDbEntriesUpdated = [...list.displayedDbEntries];
      filteredDbEntriesUpdated.splice(
        removedDbEntry.index,
        0,
        removedDbEntry.item
      );
      setList({ ...list, displayedDbEntries: filteredDbEntriesUpdated });
    }
  };

  const removeFromDatabase = async (dbEntry: DatabaseEntry) => {
    const retVal = await LocalStorage.removeDatabaseEntry(dbEntry.key);
    if (isDatabaseError(retVal)) {
      setAlert({
        isShown: true,
        message: retVal.message,
        type: AlertType.ERROR,
      });
    }
  };

  const capturedSignaturesCount = (gosForm: GOSForm) => {
    let capturedSignatures = 0;
    gosForm.signatures.forEach((signature: Signature) => {
      if (signature.data) {
        capturedSignatures++;
      }
    });

    return capturedSignatures;
  };

  const sortGosFormsByDateThenAlpha = (
    dbEntries: DatabaseEntry[]
  ): DatabaseEntry[] => {
    const dbEntriesCopy = [...dbEntries];
    dbEntriesCopy.sort((dbEntryA: DatabaseEntry, dbEntryB: DatabaseEntry) => {
      if (
        dbEntryB.data.gosForm.visitDate.getTime() ===
        dbEntryA.data.gosForm.visitDate.getTime()
      ) {
        if (
          dbEntryA.data.gosForm.patient.name ===
            dbEntryB.data.gosForm.patient.name &&
          dbEntryA.data.gosForm.patient.patientId ===
            dbEntryB.data.gosForm.patient.patientId
        ) {
          if (dbEntryA.data.gosForm.formNo < dbEntryB.data.gosForm.formNo)
            return -1;
          if (dbEntryA.data.gosForm.formNo > dbEntryB.data.gosForm.formNo)
            return 1;
        }

        // If date the same, sort by name
        if (
          dbEntryA.data.gosForm.patient.name >
          dbEntryB.data.gosForm.patient.name
        )
          return -1;
        if (
          dbEntryA.data.gosForm.patient.name <
          dbEntryB.data.gosForm.patient.name
        )
          return 1;

        return 0;
      }
      return (
        dbEntryB.data.gosForm.visitDate.getTime() -
        dbEntryA.data.gosForm.visitDate.getTime()
      );
    });

    return dbEntriesCopy;
  };

  const convertDatabaseEntryToDisplayedData = (
    dbEntries: DatabaseEntry[]
  ): DatabaseEntryListItem[] => {
    let date = dateWithoutTime(new Date(0));
    const displayedDbEntries = dbEntries.map((dbEntry: DatabaseEntry) => {
      let hasLabel = false;
      const visitDate = dateWithoutTime(dbEntry.data.gosForm.visitDate);
      if (date.getTime() !== visitDate.getTime()) {
        date = visitDate;
        hasLabel = true;
      }

      let labelCaption = date.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
      const todaysDate = dateWithoutTime(new Date());
      const yesterdaysDate = dateWithoutTime(
        new Date(new Date().setDate(todaysDate.getTime() - 1))
      );
      if (todaysDate.getTime() === date.getTime()) {
        labelCaption = 'Today';
      } else if (yesterdaysDate.getTime() === date.getTime()) {
        labelCaption = 'Yesterday';
      }
      const displatedDbEntry: DatabaseEntryListItem = {
        dbEntry: dbEntry,
        hasLabel: hasLabel,
        labelCaption: labelCaption,
      };

      return displatedDbEntry;
    });

    return displayedDbEntries;
  };

  const setAlertHelper = (
    type: AlertType,
    message: string,
    duration: number
  ) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    setAlert({ isShown: true, type: type, message: message });
    timeout.current = setTimeout(() => {
      setAlert({ ...alert, isShown: false });
    }, duration);
  };

  const onConfirmModalErrorHandler = () => {
    setModalErrorState({ isOpen: false });
  };

  const onSendButtonClickHandler = () => {
    handleDatabaseEntryHttpState(undefined, true);
  };

  return (
    <>
      <Alert
        type={alert.type}
        isShown={
          !httpActionsAlertContext.isShown &&
          !serviceWorkerAlertContext.isShown &&
          alert.isShown
        }
        isFixed={true}
      >
        {alert.message}
      </Alert>
      <DismissableAlert
        type={dismissableAlert.type}
        isShown={dismissableAlert.isShown}
        isFixed={true}
      >
        {dismissableAlert.message}
      </DismissableAlert>
      <VerticalContainer>
        <FlexColumn className={styles.searchablePatientsListContainer}>
          <SearchableList
            className={styles.searchablePatientsList}
            onSearch={onSearchHandler}
            itemCount={list.displayedDbEntries.length}
            emptyComponent={<EmptyPatientsList />}
            isLoading={loading}
          >
            {convertDatabaseEntryToDisplayedData(list.displayedDbEntries).map(
              (dbEntry: DatabaseEntryListItem, index: number) => {
                return (
                  <ListItem
                    key={dbEntry.dbEntry.key}
                    title={`${getAcuitasNameInCorrectFormat(
                      dbEntry.dbEntry.data.gosForm.patient.name
                    )}`}
                    subTitle={dbEntry.dbEntry.data.gosForm.patient.patientId}
                    message={`GOS ${dbEntry.dbEntry.data.gosForm.formNo}`}
                    onSwipeLeft={() =>
                      onSwipeLeftHandler(dbEntry.dbEntry, index)
                    }
                    swipeTriggeringTreshold={0.4}
                    isLoading={
                      dbEntry.dbEntry.data.httpRequestState ===
                      HttpRequestState.SENDING
                    }
                    hasLabel={dbEntry.hasLabel}
                    labelCaption={dbEntry.labelCaption}
                    backgroundTemplate={<DeletePlaceholder />}
                    onClick={() =>
                      signaturePageButtonHandler(dbEntry.dbEntry.key)
                    }
                  >
                    <FlexColumn className={styles.listItemIconWrapper} centered>
                      <Icon
                        className={classnames(
                          styles.signedIcon,
                          isSigned(dbEntry.dbEntry) && styles.valid
                        )}
                        SvgComponent={CheckSVG}
                      />
                      {`${capturedSignaturesCount(
                        dbEntry.dbEntry.data.gosForm
                      )} out of ${
                        dbEntry.dbEntry.data.gosForm.signatures.length
                      }`}
                    </FlexColumn>
                  </ListItem>
                );
              }
            )}
          </SearchableList>
          {Boolean(list.displayedDbEntries.length) && (
            <PrimaryButton
              onClick={onSendButtonClickHandler}
              disabled={isSendingGosForms}
            >
              <CustomGrid
                columnCount={2}
                columnSize={CustomGridSize.MIN_CONTENT}
              >
                <Icon SvgComponent={UploadSVG} />
                {`Send`}
              </CustomGrid>
            </PrimaryButton>
          )}
        </FlexColumn>
      </VerticalContainer>
      <ModalConfirmation
        title={'Delete GOS form'}
        isOpen={modalConfirmRemoveOpen}
        onConfirm={onModalRemoveConfirm}
        onDismiss={onModalRemoveDismiss}
      >
        {removedDbEntry.item &&
          `Are you sure you want to delete GOS ${removedDbEntry.item.data.gosForm.formNo}
          form for patient ${removedDbEntry.item.data.gosForm.patient.name}?`}
      </ModalConfirmation>
      <ModalError
        title={modalErrorState.title}
        isOpen={modalErrorState.isOpen}
        onConfirm={onConfirmModalErrorHandler}
      >
        {modalErrorState.message}
      </ModalError>
    </>
  );
};
