import React, { useEffect, useRef, useState } from 'react';
import { UncontrolledAlert } from 'reactstrap';
import { AlertModel } from '../alert.model';
import { FlexColumn } from '../../../';
import classnames from 'classnames';
import styles from './dissmisable-alert.module.scss';

interface DismissableAlertProps extends AlertModel {
  className?: string;
  isFixed?: boolean;
}

export const DismissableAlert: React.FC<DismissableAlertProps> = ({
  type,
  isShown,
  children,
  className,
  isFixed,
}) => {
  const appBarRef = useRef<HTMLDivElement>();
  const [styleTop, setStyleTop] = useState(0);
  useEffect(() => {
    const appBar = document.querySelector('[role="navigation"]');
    if (appBar) {
      appBarRef.current = appBar as HTMLDivElement;
      setStyleTop(appBar.clientHeight);
    }

    window.addEventListener('scroll', onScrollHandler);

    return () => {
      window.removeEventListener('scroll', onScrollHandler);
    };
  }, []);

  const onScrollHandler = (e: Event) => {
    if (appBarRef.current && isFixed) {
      const newStyleTop = Math.max(
        appBarRef.current.clientHeight - window.scrollY,
        0
      );

      setStyleTop(newStyleTop);
    }
  };

  return (
    <>
      {isShown && (
        <FlexColumn
          className={classnames(styles.alertWrapper, {
            [styles.fixed]: isFixed,
          })}
          style={{ top: styleTop }}
        >
          <UncontrolledAlert
            color={type}
            className={classnames(className, styles.alert)}
          >
            {children}
          </UncontrolledAlert>
        </FlexColumn>
      )}
    </>
  );
};
