import React from 'react';
import {
  CustomGrid,
  gridFr,
  FlexColumn,
  FlexRow,
  gridSize,
  CustomGridSize,
  CustomGridGap,
  SpecSaversLogoSVG,
  IconAvatar,
} from '../../../';
import { Swipeable, SwipeableProps } from '../../swipeable/swipable.component';
import { SpinnerComponent } from '../../spinner/spinner.component';
import classnames from 'classnames';
import styles from './list-item.module.scss';

interface ListItemProps extends SwipeableProps {
  thumbnail?: JSX.Element;
  title?: string | JSX.Element;
  subTitle?: string | JSX.Element;
  message?: string | JSX.Element;
  hasLabel?: boolean;
  labelCaption?: string;
  className?: string;
  isLoading?: boolean;
  onClick?: () => void;
}

export const ListItem: React.FC<ListItemProps> = ({
  thumbnail = <IconAvatar SvgComponent={SpecSaversLogoSVG}></IconAvatar>,
  title,
  subTitle,
  message,
  className,
  isLoading,
  hasLabel,
  labelCaption,
  children,
  onClick,
  ...rest
}) => {
  return (
    <FlexColumn className={styles.listItemWrapper}>
      {hasLabel && (
        <FlexColumn className={styles.listLabel}>{labelCaption}</FlexColumn>
      )}
      <Swipeable {...rest}>
        {isLoading && <SpinnerComponent className={styles.spinner} />}
        <CustomGrid
          className={classnames(className, styles.listItem)}
          columnGap={CustomGridGap.NONE}
          columnTemplate={[
            gridSize(CustomGridSize.MIN_CONTENT),
            gridFr(1),
            gridSize(CustomGridSize.MIN_CONTENT),
          ]}
          onClick={onClick}
        >
          <FlexColumn className={styles.thumbnail}>{thumbnail}</FlexColumn>
          <FlexColumn className={styles.body}>
            <FlexRow className={styles.title} data-private>
              {title}
            </FlexRow>
            <FlexRow className={styles.subTitle} data-private>
              {subTitle}
            </FlexRow>
            <FlexRow className={styles.message}>{message}</FlexRow>
          </FlexColumn>
          <FlexRow className={styles.action}>{children}</FlexRow>
        </CustomGrid>
      </Swipeable>
    </FlexColumn>
  );
};
