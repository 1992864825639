import React, { useState, useEffect } from 'react';
import { CustomGrid, IconButton, RemoveSVG, PinField } from '../../';
import classnames from 'classnames';
import styles from './pin-pad.module.scss';

interface PinField {
  value: string;
  index: number;
  pinFieldRef: React.RefObject<HTMLInputElement>;
}

interface PinState {
  entered: boolean;
  value: string;
}
export interface PinPadProps {
  pinLength: number;
  className?: string;
  pinValid?: boolean;
  onEntered?: (value: string) => void;
}

export const PinPad: React.FC<PinPadProps> = ({
  pinLength,
  pinValid,
  className,
  onEntered,
}) => {
  const [nextActivePinFieldIndex, setNextActivePinFieldIndex] = useState(0);
  const [pinFields, setPinFields] = useState<PinField[]>([]);
  const [pinState, setPinState] = useState<PinState>({
    entered: false,
    value: '',
  });

  useEffect(() => {
    if (
      !pinValid &&
      pinFields.length > 0 &&
      nextActivePinFieldIndex === 0 &&
      pinFields[0] &&
      pinFields[0].pinFieldRef.current
    ) {
      // Focus first one on start
      pinFields[0].pinFieldRef.current.focus();
    }

    window.addEventListener('click', onWindowClickHandler);
    window.addEventListener('touch', onWindowClickHandler);

    return () => {
      window.removeEventListener('click', onWindowClickHandler);
      window.removeEventListener('touch', onWindowClickHandler);
    };
  }, [pinFields]);

  useEffect(() => {
    setPinFields(buildPinFields(pinLength));
  }, [pinLength]);

  useEffect(() => {
    if (pinFields.length > 0 && nextActivePinFieldIndex === pinFields.length) {
      // At this point full pin has been entered
      setPinState({ ...pinState, entered: true });
    } else {
      setPinState({ ...pinState, entered: false });
    }
  }, [nextActivePinFieldIndex]);

  useEffect(() => {
    if (pinState.entered) {
      onEntered && onEntered(pinState.value);
    }
  }, [pinState.entered]);

  const buildPinFields = (count: number): PinField[] => {
    const pinFieldsTemp: PinField[] = [];
    for (let i = 0; i < count; i++) {
      const pinField: PinField = {
        value: '',
        index: i,
        pinFieldRef: React.createRef<HTMLInputElement>(),
      };
      pinFieldsTemp.push(pinField);
    }

    return pinFieldsTemp;
  };

  const onRemoveClickHandler = () => {
    let nextActivePinFieldIndexTemp =
      nextActivePinFieldIndex - 1 >= 0 ? nextActivePinFieldIndex - 1 : 0;

    if (
      pinFields[nextActivePinFieldIndex] &&
      pinFields[nextActivePinFieldIndex].pinFieldRef.current
    ) {
      pinFields[nextActivePinFieldIndex].pinFieldRef.current!.blur();
    }
    if (pinFields[nextActivePinFieldIndexTemp].pinFieldRef.current) {
      pinFields[nextActivePinFieldIndexTemp].pinFieldRef.current!.focus();
    }

    const pinFieldsCopy = [...pinFields];
    pinFieldsCopy[nextActivePinFieldIndexTemp] = {
      ...pinFieldsCopy[nextActivePinFieldIndexTemp],
      value: '',
    };

    const pinValue = pinState.value.slice(0, pinState.value.length - 1);
    setPinState({ ...pinState, value: pinValue });

    setPinFields(pinFieldsCopy);

    setNextActivePinFieldIndex(nextActivePinFieldIndexTemp);
  };

  const onPinFieldChangeHandler = (value: string, index: number) => {
    const pinFieldsCopy = [...pinFields];
    let nextActivePinFieldIndexTemp = -1;
    if (
      index >= nextActivePinFieldIndex + 1 &&
      pinFields[index].pinFieldRef.current &&
      pinFields[nextActivePinFieldIndex].pinFieldRef.current
    ) {
      // Handle case when user wants to enter value for a pin field
      // when previous pin field value is empty
      const currentActivePinFieldIndex = nextActivePinFieldIndex;
      pinFields[index].pinFieldRef.current!.blur();
      pinFields[currentActivePinFieldIndex].pinFieldRef.current!.focus();

      pinFieldsCopy[currentActivePinFieldIndex] = {
        ...pinFieldsCopy[currentActivePinFieldIndex],
        value: value,
      };
      nextActivePinFieldIndexTemp =
        currentActivePinFieldIndex + 1 < pinFieldsCopy.length
          ? currentActivePinFieldIndex + 1
          : currentActivePinFieldIndex;

      pinFieldsCopy[nextActivePinFieldIndexTemp] = {
        ...pinFieldsCopy[nextActivePinFieldIndexTemp],
      };
    } else {
      // Value is entered to a field, save that value and switch to next field
      nextActivePinFieldIndexTemp =
        index + 1 < pinFieldsCopy.length ? index + 1 : index;

      pinFieldsCopy[index] = {
        ...pinFieldsCopy[index],
        value: value,
      };
      pinFieldsCopy[nextActivePinFieldIndexTemp] = {
        ...pinFieldsCopy[nextActivePinFieldIndexTemp],
      };

      if (pinFields[nextActivePinFieldIndexTemp].pinFieldRef.current) {
        pinFields[nextActivePinFieldIndexTemp].pinFieldRef.current!.focus();
      }
    }

    // check if that was last pin digit
    if (nextActivePinFieldIndex === pinFields.length - 1) {
      pinFields[nextActivePinFieldIndex].pinFieldRef.current!.blur();
    } else {
      pinFields[nextActivePinFieldIndexTemp].pinFieldRef.current!.focus();
    }

    const pinValue = pinState.value + value;
    setPinState({ ...pinState, value: pinValue });

    setPinFields(pinFieldsCopy);
    // Even that index greater than pinFields.length, set it to a incremented value
    // By that remove button works correctly
    setNextActivePinFieldIndex(nextActivePinFieldIndex + 1);
  };

  const onWindowClickHandler = () => {
    if (
      pinFields[nextActivePinFieldIndex] &&
      pinFields[nextActivePinFieldIndex].pinFieldRef.current
    ) {
      pinFields[nextActivePinFieldIndex].pinFieldRef.current!.focus();
    }
  };

  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // handle 'remove' button pressed on mobile keyboard
    if (event.keyCode === 46) {
      onRemoveClickHandler();
    }
  };

  return (
    <CustomGrid
      columnCount={pinLength + 1}
      className={classnames(className, styles.pinPadContainer)}
    >
      {pinFields.map((pinField: PinField) => (
        <PinField
          value={pinField.value}
          key={pinField.index}
          pinFieldRef={pinField.pinFieldRef}
          onChange={(value: string) => {
            onPinFieldChangeHandler(value, pinField.index);
          }}
          onKeyDown={onKeyDownHandler}
        />
      ))}
      <IconButton
        iconProps={{ className: styles.removeIcon }}
        className={styles.removeButton}
        SvgComponent={RemoveSVG}
        onClick={onRemoveClickHandler}
      />
    </CustomGrid>
  );
};
