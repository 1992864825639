import React, { useEffect, useState } from 'react';

import { FlexColumn, Label } from '../../';

import styles from './orientation-lock.module.scss';
import { WindowOrientation } from '../../../../modules/common/common.model';
import { isMobile } from '../../../utils/is-mobile';

interface OrientationLockProps {
  orientationLock: WindowOrientation;
  message?: string;
  onChange?: (currentOrientation: WindowOrientation) => void;
}

export const OrientationLock: React.FC<OrientationLockProps> = ({
  orientationLock,
  message,
  children,
  onChange,
}) => {
  const [windowOrientation, setWindowOrientation] = useState<
    WindowOrientation
  >();

  useEffect(() => {
    // On initialization
    if (isMobile()) {
      setOrientation(window.innerWidth, window.innerHeight);
    } else {
      setWindowOrientation(WindowOrientation.LANDSCAPE);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResizeHandler);

    return () => {
      window.removeEventListener('resize', onResizeHandler);
    };
  }, []);

  useEffect(() => {
    if (windowOrientation) {
      onChange && onChange(windowOrientation);
    }
  }, [windowOrientation]);

  const onResizeHandler = (e: Event) => {
    const target = e.target as Window;
    setOrientation(target.innerWidth, target.innerHeight);
  };

  const setOrientation = (width: number, height: number) => {
    if (width > height) {
      setWindowOrientation(WindowOrientation.LANDSCAPE);
    } else {
      setWindowOrientation(WindowOrientation.PORTRAIT);
    }
  };

  return windowOrientation === orientationLock || !isMobile() ? (
    <>{children}</>
  ) : (
    <FlexColumn className={styles.portraitContainer}>
      <Label className={styles.portraitMessage}>{message}</Label>
    </FlexColumn>
  );
};
