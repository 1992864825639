import { buildPrimitive } from '../../primitive-builder';

import styles from './flex-stretch.module.scss';

export const stretchFlexItemClassName = styles.stretch;

export const FlexStretchItem = buildPrimitive(styles.stretch, {
  collapseHeight: styles.collapseHeight,
  collapseWidth: styles.collapseWidth,
  isScrollable: styles.scrollable,
});
