import React from 'react';

import { buildPrimitive } from '../primitive-builder';

import styles from './inner-box.module.scss';

export const InnerBox = buildPrimitive(styles.borderBox, {
  withBorder: styles.withBorder,
  withNonePadding: styles.withNonePadding,
  withXSmallPadding: styles.withXSmallPadding,
  withSmallPadding: styles.withSmallPadding,
  withLargePadding: styles.withLargePadding,
  withOnlyHorizontalPadding: styles.horizontalPadding,
  withOnlyVerticalPadding: styles.verticalPadding,
  withSmallHorizontalPadding: styles.horizontalPaddingSmall,
  withXSmallHorizontalPadding: styles.horizontalPaddingXSmall,
  withNoneHorizontalPadding: styles.horizontalPaddingNone,
  withSmallVerticalPadding: styles.verticalPaddingSmall,
  withBoxRadius: styles.boxRadius,
});
