import {
  GOSForm,
  Signature,
  SignatureType,
  GOSFormHttp,
  GOSFormType,
  HttpRequestState,
} from '../signature/signature.model';
import { QrCodeFormat } from './qr.model';
import { decrypt } from '../common/encryption/encryption';

export const mapQRCodeToGOSForm = (qrCodeFormat: QrCodeFormat): GOSForm => {
  const signatures: Signature[] = mapQRCodeToSignatures(qrCodeFormat);

  const gosForm: GOSForm = {
    business: {
      customerId: qrCodeFormat.BusinessGUID,
      practiceId: qrCodeFormat.PracticeID,
    },
    formId: qrCodeFormat.FormID,
    formNo: +qrCodeFormat.FormNo as GOSFormType,
    patient: { patientId: qrCodeFormat.PID, name: qrCodeFormat.PatientName },
    visitDate: convertBritishDateToCorrectFormat(qrCodeFormat.VisitDate),
    signatures: signatures,
  };

  return gosForm;
};

const mapQRCodeToSignatures = (qrCodeFormat: QrCodeFormat): Signature[] => {
  const signatures: Signature[] = [];

  if (qrCodeFormat.PatientSignatureID) {
    const signature: Signature = {
      id: `${qrCodeFormat.PatientSignatureID}_${SignatureType.PATIENT}`,
      type: SignatureType.PATIENT,
      data: undefined,
      httpRequestState: HttpRequestState.NONE,
    };
    if (qrCodeFormat.PatientSignatureParams) {
      signature.params = extractSignatureParams(
        qrCodeFormat.PatientSignatureParams
      );
    }

    signatures.push(signature);
  }

  if (qrCodeFormat.PatientSignature2ID) {
    const signature: Signature = {
      id: `${qrCodeFormat.PatientSignature2ID}_${SignatureType.PATIENT2}`,
      type: SignatureType.PATIENT2,
      data: undefined,
      httpRequestState: HttpRequestState.NONE,
    };
    if (qrCodeFormat.Patient2SignatureParams) {
      signature.params = extractSignatureParams(
        qrCodeFormat.Patient2SignatureParams
      );
    }
    signatures.push(signature);
  }

  if (qrCodeFormat.PerformerSignatureID) {
    const signature: Signature = {
      id: `${qrCodeFormat.PerformerSignatureID}_${SignatureType.PERFORMER}`,
      type: SignatureType.PERFORMER,
      data: undefined,
      httpRequestState: HttpRequestState.NONE,
    };
    if (qrCodeFormat.PerformerSignatureParams) {
      signature.params = extractSignatureParams(
        qrCodeFormat.PerformerSignatureParams
      );
    }
    signatures.push(signature);
  }

  if (qrCodeFormat.ContractorSignatureID) {
    const signature: Signature = {
      id: `${qrCodeFormat.ContractorSignatureID}_${SignatureType.CONTRACTOR}`,
      type: SignatureType.CONTRACTOR,
      data: undefined,
      httpRequestState: HttpRequestState.NONE,
    };
    if (qrCodeFormat.ContractorSignatureParams) {
      signature.params = extractSignatureParams(
        qrCodeFormat.ContractorSignatureParams
      );
    }
    signatures.push(signature);
  }

  if (qrCodeFormat.SupplierSignatureID) {
    const signature: Signature = {
      id: `${qrCodeFormat.SupplierSignatureID}_${SignatureType.SUPPLIER}`,
      type: SignatureType.SUPPLIER,
      data: undefined,
      httpRequestState: HttpRequestState.NONE,
    };
    if (qrCodeFormat.SupplierSignatureParams) {
      signature.params = extractSignatureParams(
        qrCodeFormat.SupplierSignatureParams
      );
    }
    signatures.push(signature);
  }

  return signatures;
};

export const isGosFormEqual = (
  gosFormHttp1: GOSFormHttp,
  gosFormHttp2: GOSFormHttp
) => {
  return (
    gosFormHttp1.gosForm.business.customerId ===
      gosFormHttp2.gosForm.business.customerId &&
    gosFormHttp1.gosForm.formId === gosFormHttp2.gosForm.formId
  );
};

const extractSignatureParams = (params: string): string[] => {
  const SIGNATURE_PARAM_DELIMITER = '~';
  return params
    .split(SIGNATURE_PARAM_DELIMITER)
    .map(val => val.replace(/"/gi, ''));
};

const convertBritishDateToCorrectFormat = (date: string): Date => {
  const DATE_DELIMITER = '-';
  return new Date(date.split(DATE_DELIMITER).reverse().join(DATE_DELIMITER));
};
