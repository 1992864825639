import React from 'react';
import { FullHeightContainer, VerticalContainer } from '../../shared/design';
import { LoginContainer } from './login.container';
import Routes from '../routes';

interface LoginViewProps {
  shouldSkipAuthorization?: boolean;
}

export const LoginView: React.FC<LoginViewProps> = ({
  shouldSkipAuthorization,
}) => {
  return (
    <FullHeightContainer as={VerticalContainer} isScrollable={true}>
      {shouldSkipAuthorization ? <Routes /> : <LoginContainer />}
    </FullHeightContainer>
  );
};
