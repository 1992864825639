export const isMobile = (): boolean => {
  const testUserAgent = (regexp: RegExp) => {
    return regexp.test(window.navigator.userAgent);
  };
  if (testUserAgent(/Mobi|Android/i)) {
    return true;
  }

  return false;
};
