import React from 'react';
import { FlexColumn } from '../../../primitives';
import classnames from 'classnames';
import styles from './empty-list.module.scss';

interface EmptyListProps {
  className?: string;
}

export const EmptyList: React.FC<EmptyListProps> = ({ className }) => {
  return (
    <FlexColumn className={classnames(className, styles.emptyList)}>
      List is empty. Try adding new records.
    </FlexColumn>
  );
};
