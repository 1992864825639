/* eslint-disable no-throw-literal */
import React, { useState, useEffect, useRef } from 'react';
import {
  SpinnerComponent,
  FullHeightContainer,
  FlexColumn,
  ModalSuccess,
  DefaultButton,
  PrimaryButton,
  ModalError,
  Icon,
  AlertModel,
  AlertType,
  DismissableAlert,
  LockedSVG,
} from '../../shared/design';
import * as LocalStorage from '../../shared/api/common/local-storage';
import {
  InitializationOptions,
  AuthorizationOptions,
  AuthorizationError,
} from '../../shared/api/authorization/authorization.model';
import {
  encrypt,
  decrypt,
} from '../../shared/api/common/encryption/encryption';
import { LoginView } from '../login/login.view';
import { QrScanContainer } from '../qr-scan/qr-scan.container';
import { ModalState } from '../common/common.model';
import {
  VIBRATION_ON_SCAN_ERROR_PATTERN,
  VIBRATION_ON_SCAN_SUCCESS_PATTERN,
} from '../qr-scan/qr-scan.options';
import { DATABASE_INITIALIZATION_STORE_KEY } from './initialize.options';
import { DATABASE_AUTHORIZATION_STORE_KEY } from '../login/login.options';
import styles from './initialize.module.scss';
import {
  mapAuthorizationErrorToMessage,
  clearAuthorizationDatabase,
} from '../common/authorization.utils';
import { stringify } from 'querystring';

export const InitializeContainer: React.FunctionComponent = () => {
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [displayCameraScanner, setDisplayCameraScanner] = useState(false);
  const [modalSuccessState, setModalSuccessState] = useState<ModalState>({
    isOpen: false,
  });
  const [modalErrorState, setModalErrorState] = useState<ModalState>({
    isOpen: false,
  });
  const [modalDatabaseErrorState, setModalDatabaseErrorState] = useState<
    ModalState
  >({
    isOpen: false,
  });
  const [dismissableAlert, setDismissableAlert] = useState<AlertModel>({
    type: AlertType.ERROR,
    isShown: false,
    message: 'Error',
  });
  const isProcessing = useRef(false);

  // extract url parameters

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (!initialized) {
      setDismissableAlert({
        isShown: true,
        type: AlertType.ERROR,
        message: `Please scan initialization QR code`,
      });
    }
  }, [initialized]);

  const initialize = async (url: URL = new URL(location.href)) => {
    const decodedURL = new URL(decodeURIComponent(url.href));
    const query = new URLSearchParams(decodedURL.search);
    let isQueryValid = checkIfValidQuery(query);

    const dbTypeInit = LocalStorage.DatabaseType.INITIALIZATION;
    try {
      if (!isQueryValid) {
        // If query is invalid but auth database has entries set as initialized
        const dbEntry: InitializationOptions = await LocalStorage.getFromDatabase(
          DATABASE_INITIALIZATION_STORE_KEY,
          dbTypeInit
        );
        let dbError = LocalStorage.isDatabaseError(dbEntry);
        if (dbEntry && !dbError) {
          const dbTypeAuth = LocalStorage.DatabaseType.AUTHORIZATION;
          const dbEntryAuth: AuthorizationOptions = await LocalStorage.getFromDatabase(
            DATABASE_AUTHORIZATION_STORE_KEY,
            dbTypeAuth
          );

          // check if login authorization exists
          // That will reset the whole process which is
          // Initialization (Scan QR code form Acuitas) -> enter pin
          // That should be done at one try
          dbError = LocalStorage.isDatabaseError(dbEntryAuth);
          if (dbError) {
            // clear initialization database store
            throw () =>
              onDatabaseError(AuthorizationError.DATABASE, true, true);
          } else if (dbEntryAuth) {
            // check if loginToken is valid, so that login operations can be skipped
            if (dbEntryAuth.loginToken) {
              // try to decryp the login token
              // if it decrypts, its valid
              const decryptedLoginToken = await decrypt(dbEntryAuth.loginToken);
              if (!decryptedLoginToken) {
                clearAuthorizationDatabase();
                throw () =>
                  onDatabaseError(AuthorizationError.ENCRYPTION, true, true);
              }

              setInitialized(true);
              setLoggedIn(true);
            }
          } else {
            clearAuthorizationDatabase();
            setLoading(false);
          }
        } else if (dbError) {
          throw () => onDatabaseError(AuthorizationError.DATABASE, true, true);
        }
      } else {
        // New parameters detected, clear the databases
        clearAuthorizationDatabase();
        await LocalStorage.clearDatabase(LocalStorage.DatabaseType.GOS_FORMS);

        const initializationOptionsTemp: InitializationOptions = {
          P2: '',
          P1: '',
          P3: '',
        };
        for (const param of (query as any).entries()) {
          if (param[0]) {
            const key = param[0];
            const value = param[1] || '';
            // decrypt the value
            const paramValueEncrypted = await encrypt(value);
            if (paramValueEncrypted) {
              initializationOptionsTemp[key] = paramValueEncrypted;
            } else {
              clearAuthorizationDatabase();
              throw () =>
                onDatabaseError(AuthorizationError.ENCRYPTION, true, true);
            }
          }
        }

        // save to local storage
        LocalStorage.clearDatabase(dbTypeInit);
        const retval = await LocalStorage.addToDatabase(
          initializationOptionsTemp,
          DATABASE_INITIALIZATION_STORE_KEY,
          undefined,
          dbTypeInit
        );
        if (!retval || LocalStorage.isDatabaseError(retval)) {
          throw () => onDatabaseError(AuthorizationError.DATABASE, true, true);
        }
        setInitialized(true);
      }
    } catch (e) {
      if (e && typeof e === 'function') {
        e();
      } else {
        onDatabaseError(AuthorizationError.UNKNOWN, true, true);
      }
    }

    setLoading(false);
  };

  const checkIfValidQuery = (query: URLSearchParams): boolean => {
    const loginLink: InitializationOptions = {
      P2: '',
      P3: '',
      P1: '',
    };

    return Object.keys(loginLink).every((value: string) => query.has(value));
  };

  const onScanHandler = (data: string) => {
    if (!isProcessing.current) {
      isProcessing.current = true;
      handleScanAsync(data);
    }
  };

  const handleScanAsync = async (data: string) => {
    try {
      // check if URL
      const url = new URL(data);
      vibrationSuccess();

      await initialize(url);
      isProcessing.current = false;
    } catch (e) {
      vibrationError();
      setModalErrorState({
        isOpen: true,
        title: 'Invalid QR Code',
        message: 'Invalid QR Code. Try once again.',
      });
    }

    setLoading(false);
  };

  const vibrationSuccess = () => {
    if ('vibrate' in navigator) {
      navigator.vibrate(VIBRATION_ON_SCAN_SUCCESS_PATTERN);
    }
  };

  const vibrationError = () => {
    if ('vibrate' in navigator) {
      navigator.vibrate(VIBRATION_ON_SCAN_ERROR_PATTERN);
    }
  };

  const onDatabaseError = (
    errorType: AuthorizationError,
    showError?: boolean,
    reload?: boolean
  ) => {
    if (showError) {
      setModalErrorState({
        isOpen: true,
        title: `An error occured`,
        message: mapAuthorizationErrorToMessage(errorType),
      });
    } else if (reload) {
      clearAuthorizationDatabase();
      location.replace(location.pathname);
    } else {
      clearAuthorizationDatabase();
      setLoading(false);
    }
  };

  const onCameraAccessDeniedHandler = () => {
    setLoading(false);
  };

  const onConfirmModalSuccessHandler = () => {
    setModalSuccessState({ isOpen: false });
  };

  const onDismissModalSuccessHandler = () => {
    setModalSuccessState({ isOpen: false });
  };

  const onConfirmModalErrorHandler = () => {
    isProcessing.current = false;
    setModalErrorState({ isOpen: false });
  };

  const onConfirmModalDatabaseErrorHandler = () => {
    setModalDatabaseErrorState({ isOpen: false });

    clearAuthorizationDatabase();
    location.replace(location.pathname);
  };

  const onEnableCameraButtonClick = () => {
    setDisplayCameraScanner(true);
  };

  return (
    <>
      {loading ? (
        <SpinnerComponent />
      ) : initialized ? (
        <LoginView shouldSkipAuthorization={loggedIn} />
      ) : (
        <>
          {displayCameraScanner ? (
            <QrScanContainer
              scanHandle={onScanHandler}
              onCameraAccessDenied={onCameraAccessDeniedHandler}
            />
          ) : (
            <>
              <DismissableAlert
                type={dismissableAlert.type}
                isShown={dismissableAlert.isShown}
                isFixed={true}
              >
                {dismissableAlert.message}
              </DismissableAlert>
              <FullHeightContainer className={styles.initializeWrapper}>
                <FlexColumn className={styles.initializeContainer}>
                  <FlexColumn className={styles.enableCameraScanner}>
                    <Icon
                      SvgComponent={LockedSVG}
                      className={styles.notAllowedIcon}
                    />
                    <PrimaryButton
                      className={styles.enableCameraButton}
                      onClick={onEnableCameraButtonClick}
                    >
                      {`Enable camera scanner`}
                    </PrimaryButton>
                  </FlexColumn>
                </FlexColumn>
              </FullHeightContainer>
            </>
          )}

          <ModalSuccess
            title={modalSuccessState.title}
            isOpen={modalSuccessState.isOpen}
            body={modalSuccessState.message}
            footer={
              <>
                <DefaultButton
                  onClick={onDismissModalSuccessHandler}
                  data-testid="modal-dismiss"
                >
                  {`Cancel`}
                </DefaultButton>
                <PrimaryButton
                  onClick={onConfirmModalSuccessHandler}
                  data-testid="modal-confirm"
                >
                  {'Ok'}
                </PrimaryButton>
              </>
            }
          >
            {modalSuccessState.message}
          </ModalSuccess>
          <ModalError
            title={modalErrorState.title}
            isOpen={modalErrorState.isOpen}
            onConfirm={onConfirmModalErrorHandler}
          >
            {modalErrorState.message}
          </ModalError>
          <ModalError
            title={modalDatabaseErrorState.title}
            isOpen={modalDatabaseErrorState.isOpen}
            onConfirm={onConfirmModalDatabaseErrorHandler}
          >
            {modalDatabaseErrorState.message}
          </ModalError>
        </>
      )}
    </>
  );
};
