import { KeyboardEvent, RefObject } from 'react';

import { ESCAPE_KEY } from './keys';

export const escapeAsCancel = (
  event: KeyboardEvent<HTMLElement>,
  innerRef: RefObject<any>,
  defaultValue: any,
  callback?: (defaultValue: any) => void
) => {
  if (event.key === ESCAPE_KEY && callback) {
    callback(defaultValue);

    Promise.resolve().then(() => {
      if (innerRef.current) {
        innerRef.current.select();
      }
    });
  }
};
