import React from 'react';

import {
  ComposablePrimitive,
  ComposablePrimitivePropsBase,
} from '../composable-primitive';

import { CustomGridContext } from './custom-grid.context';

type CollapseRowProps<AsProps extends ComposablePrimitivePropsBase> = {
  gridColumnCount?: number;
} & ComposablePrimitive<AsProps>;

export const CollapseRow = <AsProps extends ComposablePrimitivePropsBase>({
  as,
  style,
  gridColumnCount,
  ...props
}: CollapseRowProps<AsProps>) => {
  const RenderComponent: any = as || 'div';
  return (
    <CustomGridContext.Consumer>
      {({ options }) => (
        <RenderComponent
          style={{
            ...style,
            gridColumn: `span ${gridColumnCount || options.columnCount}`,
          }}
          {...(props as any)}
        />
      )}
    </CustomGridContext.Consumer>
  );
};
