import React, { useRef, useEffect, useState, TouchEvent } from 'react';
import { Position } from '../common.model';
import { FlexColumn } from '../../';
import { SwipeTransitionFunction, SwipeState } from './swipeable.model';
import styles from './swipeable.module.scss';

export interface SwipeableProps {
  swipeTransitionDuration?: number;
  swipeTransitionFunction?: SwipeTransitionFunction;
  backgroundTemplate?: JSX.Element;
  swipeTriggeringTreshold?: number;
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
  onSwipeUp?: () => void;
  onSwipeDown?: () => void;
}

export const Swipeable: React.FC<SwipeableProps> = ({
  swipeTransitionDuration = 500,
  swipeTransitionFunction = 'ease',
  backgroundTemplate,
  swipeTriggeringTreshold = 0.3,
  children,
  onSwipeLeft,
  onSwipeRight,
  onSwipeUp,
  onSwipeDown,
}) => {
  const swipeableRef = useRef<HTMLDivElement>(null);

  const [isMouseDown, setMouseDown] = useState(false);
  const [swipeState, setSwipeState] = useState<SwipeState>(SwipeState.NONE);
  const [position, setPosition] = useState<Position>({ x: 0, y: 0 });
  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    switch (swipeState) {
      case SwipeState.MOVING: {
        break;
      }
      case SwipeState.NONE: {
        if (swipeableRef.current) {
          swipeableRef.current.style.transition = '';
        }
        break;
      }
    }
  }, [swipeState]);

  useEffect(() => {
    window.addEventListener('mouseup', onMouseUpHandler);

    return () => {
      window.removeEventListener('mouseup', onMouseUpHandler);

      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isMouseDown) {
      onTouchEndHandler();
    }
  }, [isMouseDown]);

  const getTouches = (e: TouchEvent) => {
    return e.touches;
  };

  const onTouchStartHandler = (e: TouchEvent<HTMLDivElement>) => {
    const firstTouch = getTouches(e)[0];
    setPosition({ x: firstTouch.clientX, y: firstTouch.clientY });
  };

  const onTouchMoveHandler = (e: TouchEvent<HTMLDivElement>) => {
    if (!position.x || !position.y || swipeState !== SwipeState.NONE) {
      return;
    }

    const xUp = e.touches[0].clientX;
    const yUp = e.touches[0].clientY;

    const xDiff = position.x - xUp;
    const yDiff = position.y - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        if (onSwipeLeft) onSwipeLeftHandler(xDiff);
      } else {
        if (onSwipeRight) onSwipeRightHandler(xDiff);
      }
    } else {
      if (yDiff > 0) {
        if (onSwipeUp) onSwipeUpHandler(xDiff);
      } else {
        if (onSwipeDown) onSwipeDownHandler(xDiff);
      }
    }
  };

  const onTouchEndHandler = () => {
    if (swipeableRef.current && swipeState !== SwipeState.MOVING) {
      setSwipeState(SwipeState.MOVING);
      const transitionDuration =
        swipeTransitionDuration * (1.0 - swipeTriggeringTreshold);
      swipeableRef.current.style.transition = `${transitionDuration}ms transform ${swipeTransitionFunction}`;
      swipeableRef.current.style.transform = `translate(0, 0)`;
      timeout.current = setTimeout(() => {
        setSwipeState(SwipeState.NONE);
      }, transitionDuration);
    }
  };

  const onTouchCancelHandler = () => {
    if (swipeableRef.current) {
      swipeableRef.current.style.transform = `translate(0, 0)`;
    }
  };

  const onSwipeLeftHandler = (offsetX: number) => {
    if (swipeableRef.current) {
      const swipeOffset = -offsetX;
      const elementWidth = swipeableRef.current.clientWidth;
      if (offsetX < elementWidth * swipeTriggeringTreshold) {
        swipeableRef.current.style.transform = `translateX(${swipeOffset}px)`;
      } else {
        setSwipeState(SwipeState.MOVING);
        swipeableRef.current.style.transition = `${
          swipeTransitionDuration * swipeTriggeringTreshold
        }ms transform ${swipeTransitionFunction}`;
        swipeableRef.current.style.transform = `translateX(${-elementWidth}px)`;
        timeout.current = setTimeout(() => {
          setSwipeState(SwipeState.NONE);
          onSwipeLeft && onSwipeLeft();
        }, swipeTransitionDuration);
      }
    }
  };

  const onSwipeRightHandler = (offsetX: number) => {
    if (swipeableRef.current) {
      const swipeOffset = -offsetX;
      const elementWidth = swipeableRef.current.clientWidth;
      if (offsetX < elementWidth * swipeTriggeringTreshold) {
        swipeableRef.current.style.transform = `translateX(${swipeOffset}px)`;
      } else {
        swipeableRef.current.style.transition = `${
          swipeTransitionDuration * swipeTriggeringTreshold
        }ms transform ${swipeTransitionFunction}`;
        swipeableRef.current.style.transform = `translateX(${-elementWidth}px)`;
        setSwipeState(SwipeState.MOVING);
        timeout.current = setTimeout(() => {
          setSwipeState(SwipeState.NONE);
          onSwipeRight && onSwipeRight();
        }, swipeTransitionDuration);
      }
    }
  };

  const onSwipeUpHandler = (offsetY: number) => {
    if (swipeableRef.current) {
      const swipeOffset = -offsetY;
      const elementHeight = swipeableRef.current.clientHeight;
      if (offsetY < elementHeight * swipeTriggeringTreshold) {
        swipeableRef.current.style.transform = `translateY(${swipeOffset}px)`;
      } else {
        swipeableRef.current.style.transition = `${swipeTransitionDuration}ms transform ${swipeTransitionFunction}`;
        swipeableRef.current.style.transform = `translateY(${-elementHeight}px)`;
        setSwipeState(SwipeState.MOVING);
        timeout.current = setTimeout(() => {
          setSwipeState(SwipeState.NONE);
          onSwipeUp && onSwipeUp();
        }, swipeTransitionDuration);
      }
    }
  };

  const onSwipeDownHandler = (offsetY: number) => {
    if (swipeableRef.current) {
      const swipeOffset = -offsetY;
      const elementHeight = swipeableRef.current.clientHeight;
      if (offsetY < elementHeight * swipeTriggeringTreshold) {
        swipeableRef.current.style.transform = `translateY(${swipeOffset}px)`;
      } else {
        swipeableRef.current.style.transition = `${swipeTransitionDuration}ms transform ${swipeTransitionFunction}`;
        swipeableRef.current.style.transform = `translateY(${-elementHeight}px)`;
        setSwipeState(SwipeState.MOVING);
        timeout.current = setTimeout(() => {
          setSwipeState(SwipeState.NONE);
          onSwipeDown && onSwipeDown();
        }, swipeTransitionDuration);
      }
    }
  };

  const onMouseMoveHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (
      !position.x ||
      !position.y ||
      swipeState !== SwipeState.NONE ||
      !isMouseDown
    ) {
      return;
    }

    const xUp = e.clientX;
    const yUp = e.clientY;

    const xDiff = position.x - xUp;
    const yDiff = position.y - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        if (onSwipeLeft) onSwipeLeftHandler(xDiff);
      } else {
        if (onSwipeRight) onSwipeRightHandler(xDiff);
      }
    } else {
      if (yDiff > 0) {
        if (onSwipeUp) onSwipeUpHandler(xDiff);
      } else {
        if (onSwipeDown) onSwipeDownHandler(xDiff);
      }
    }
  };

  const onMouseDownHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setPosition({ x: e.clientX, y: e.clientY });
    setMouseDown(true);
  };

  const onMouseUpHandler = () => {
    setMouseDown(false);
  };

  return (
    <FlexColumn className={styles.swipeableContainer}>
      <FlexColumn className={styles.backgroundElement}>
        {backgroundTemplate}
      </FlexColumn>
      <div
        ref={swipeableRef}
        onTouchStart={onTouchStartHandler}
        onTouchMove={onTouchMoveHandler}
        onTouchEnd={onTouchEndHandler}
        onTouchCancel={onTouchCancelHandler}
        onMouseMove={onMouseMoveHandler}
        onMouseUp={onMouseUpHandler}
        onMouseDown={onMouseDownHandler}
        className={styles.swipeable}
      >
        {children}
      </div>
    </FlexColumn>
  );
};
