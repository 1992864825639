/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useMemo,
  useRef,
  MutableRefObject,
} from 'react';
import { Input, InputProps } from 'reactstrap';
import { Omit } from 'react-router';
import { assignRefs } from '../../../utils/assign-refs';
import { escapeAsCancel } from '../../../utils/escapeAsCancel';
import classnames from 'classnames';
import styles from './numeric-input.module.scss';

type RemappedInputProps = Omit<InputProps, 'onChange'>;

export interface NumericInputProps extends RemappedInputProps {
  value?: string;
  defaultValue?: string;
  id?: string;
  name?: string;
  className?: string;
  placeholder?: string;
  placeholderIntlKey?: string;
  readonly?: boolean;
  disabled?: boolean;
  isInvalid?: boolean;
  centerText?: boolean;
  innerRef?:
    | React.RefCallback<HTMLElement | null>
    | MutableRefObject<HTMLInputElement | null>;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: React.FocusEventHandler;
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (value: string) => void;
  onInput?: (event: React.FormEvent<HTMLInputElement>) => void;
}

export const NumericInput: React.FC<NumericInputProps> = ({
  value,
  id,
  name,
  className,
  defaultValue,
  placeholder,
  placeholderIntlKey,
  readonly,
  disabled,
  isInvalid,
  centerText,
  innerRef,
  autoComplete,
  onBlur,
  onFocus,
  onChange,
  onClick,
  onKeyDown,
  onInput,
  ...rest
}) => {
  const customRender = (params = {}) => {
    const initialValue = useMemo(() => value || '', [id]);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleClick = useCallback(event => {
      event.stopPropagation();

      if (onClick) {
        onClick(event);
      }
    }, []);

    const handleKeyDown = useCallback(
      event => {
        escapeAsCancel(event, inputRef, initialValue, onChange);

        if (onKeyDown) {
          onKeyDown(event);
        }
      },
      [inputRef.current, initialValue, onKeyDown]
    );

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.target.value);
      }
    };

    return (
      <Input
        type="number"
        onClick={handleClick}
        id={id}
        name={name}
        defaultValue={defaultValue == null ? undefined : defaultValue}
        className={classnames(className, { [styles.center]: centerText })}
        bsSize={'lg'}
        value={value == null ? undefined : value}
        placeholder={placeholder}
        onChange={handleChange}
        readOnly={readonly}
        disabled={disabled}
        invalid={isInvalid}
        aria-invalid={isInvalid}
        onFocus={onFocus}
        onBlur={onBlur}
        onInput={onInput}
        onKeyDown={handleKeyDown}
        innerRef={assignRefs([inputRef, innerRef])}
        {...rest}
        {...params}
        autoComplete={autoComplete || 'off'}
      />
    );
  };

  return customRender();
};
