import { CanvasImageType } from '../design';

export const scaleDownUrl = async (
  dataURL: string,
  canvasWidth: number,
  canvasHeight: number,
  type: CanvasImageType,
  quality: number
): Promise<string> => {
  const resizedCanvas = document.createElement('canvas');
  const resizedContext = resizedCanvas.getContext('2d');
  resizedCanvas.width = canvasWidth;
  resizedCanvas.height = canvasHeight;

  const img = await loadImage(dataURL);
  resizedContext!.drawImage(img, 0, 0, canvasWidth, canvasHeight);

  const scaledDownURL = resizedCanvas.toDataURL(type, quality);
  return scaledDownURL;
};

const loadImage = (dataURL: string): Promise<HTMLImageElement> => {
  return new Promise(resolve => {
    const image = new Image();
    image.addEventListener('load', () => {
      resolve(image);
    });
    image.src = dataURL;
  });
};
