export enum CustomGridGap {
  DEFAULT,
  SMALL,
  XSMALL,
  LARGE,
  MEDIUM,
  NONE,
}

/**
 * Defined some size for grid cells
 */
export enum CustomGridSize {
  /**
   * All columns/rows would have the same size
   */
  EQUAL, // default one
  /**
   * Each column/row will take size of the biggest cell
   */
  MAX_CONTENT,
  /**
   * Each column/row will take size of the smallest cell
   */
  MIN_CONTENT,
}

export interface GridUnit<Type, Value> {
  type: Type;
  value: Value;
}

export const GridFrType = 'fr';

export type GridFrUnit = GridUnit<typeof GridFrType, number>;

export const gridFr = (value: number): GridFrUnit => ({
  type: GridFrType,
  value,
});

export type GridPercentUnit = GridUnit<typeof GridPercentType, number>;

export const GridPercentType = '%';

export const gridPercent = (value: number): GridPercentUnit => ({
  type: GridPercentType,
  value,
});

export const GridSizeType = 'size';

export type GridSizeUnit = GridUnit<typeof GridSizeType, number>;

export const gridSize = (value: CustomGridSize): GridSizeUnit => ({
  type: GridSizeType,
  value,
});

export type GridTemplateUnit = GridFrUnit | GridPercentUnit | GridSizeUnit;

type CustomGridGapOptions =
  | {
      gap?: CustomGridGap;
      rowGap?: undefined;
      columnGap?: undefined;
    }
  | {
      gap?: undefined;
      rowGap?: CustomGridGap;
      columnGap?: CustomGridGap;
    };

// we define all possible alternatives shapes of the grid
export type CustomGridOptions =
  | (CustomGridGapOptions & {
      columnCount: number;
      columnSize?: CustomGridSize;
      columnTemplate?: undefined;
      autoColumnSize?: undefined;
      rowTemplate?: GridTemplateUnit[];
    })
  | (CustomGridGapOptions & {
      columnCount?: undefined;
      columnSize?: undefined;
      columnTemplate: GridTemplateUnit[];
      autoColumnSize?: undefined;
      rowTemplate?: GridTemplateUnit[];
    })
  | (CustomGridGapOptions & {
      columnCount?: undefined;
      columnSize?: undefined;
      columnTemplate?: undefined;
      autoColumnSize: CustomGridSize;
      rowTemplate?: GridTemplateUnit[];
    })
  | (CustomGridGapOptions & {
      columnCount?: undefined;
      columnSize?: undefined;
      columnTemplate?: undefined;
      autoColumnSize?: undefined;
      rowTemplate?: GridTemplateUnit[];
    });
