import React, { useEffect } from 'react';
import {
  populateDatabase,
  populateAllAvailableGosForms,
} from '../mock-api/helpers/signature';
import { clearDatabase } from './shared/api/common/local-storage/local-storage';
import { InitializeView } from './modules/initialize/initialize.view';
import { FullHeightContainer } from './shared/design';

export const App: React.FC = () => {
  useEffect(() => {
    // clearDatabase();
    // populateAllAvailableGosForms();
    // populateDatabase(60);
  }, []);

  return (
    <FullHeightContainer>
      <InitializeView />
    </FullHeightContainer>
  );
};

export default App;
