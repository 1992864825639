import React from 'react';
import { SpinnerComponent, FlexRow } from '../..';
import { NavItemsComponent } from './nav-items';
import { AppNavLogoComponent } from './app-nav-logo/app-nav-logo.component';
import { mapHomeScreenModuleToNavItem } from '../../../api/home/modules.mapper';
import { HomeScreenModule } from '../../../api/home/home.model';
import styles from './app-bar.module.scss';

export const AppBar: React.FunctionComponent = () => {
  const modules: HomeScreenModule[] = [
    { id: '0', externalUrl: '/', title: 'Home', icon: 'home', sortOrder: 0 },
    {
      id: '1',
      externalUrl: '/qr',
      title: 'QR',
      icon: 'qr_scan',
      sortOrder: 0,
    },
  ];
  const navItems = mapHomeScreenModuleToNavItem(modules);

  return (
    <FlexRow
      className={`${styles.appBar} app-bar navbar navbar-expand`}
      role="navigation"
    >
      <FlexRow stretched={true}>
        <AppNavLogoComponent className={styles.barItem} />
        {navItems && (
          <NavItemsComponent
            links={navItems}
            className={`${styles.barItem} navbar-nav mr-auto app-nav-left app-nav-home`}
          />
        )}
      </FlexRow>
      <FlexRow as="ul" className="navbar-nav app-nav-right"></FlexRow>
    </FlexRow>
  );
};
