import { Signature } from '../shared/api/signature/signature.model';
import * as LocalStorage from '../shared/api/common/local-storage';

export const findIndexOfDatabaseEntry = (
  dbEntries: LocalStorage.DatabaseEntry[],
  dbEntry: LocalStorage.DatabaseEntry
): number => {
  const dbEntriesCount = dbEntries.length;
  for (let i = 0; i < dbEntriesCount; i++) {
    if (
      dbEntries[i].data.gosForm.business.customerId ===
        dbEntry.data.gosForm.business.customerId &&
      dbEntries[i].data.gosForm.formId === dbEntry.data.gosForm.formId
    )
      return i;
  }

  return -1;
};

export const resetSignaturesForGosForm = (
  dbEntry: LocalStorage.DatabaseEntry
) => {
  const updatedDbEntry: LocalStorage.DatabaseEntry = {
    ...dbEntry,
    data: {
      ...dbEntry.data,
      gosForm: {
        ...dbEntry.data.gosForm,
        signatures: dbEntry.data.gosForm.signatures.map(
          (signature: Signature) => {
            const emptySignature: Signature = { ...signature, data: undefined };
            return emptySignature;
          }
        ),
      },
    },
  };

  LocalStorage.updateDatabaseEntry(updatedDbEntry);
};

export const dateWithoutTime = (date: Date): Date => {
  const dateTemp = new Date(date);
  dateTemp.setHours(0, 0, 0, 0);
  return dateTemp;
};
