/* eslint-disable no-throw-literal */
import React, { useEffect, useState, useRef } from 'react';
import {
  SpinnerComponent,
  FullHeightContainer,
  FlexColumn,
  PinPad,
  Alert,
  AlertModel,
  AlertType,
  ModalSuccess,
  ModalError,
  Icon,
  UnlockedSVG,
} from '../../shared/design';
import * as LocalStorage from '../../shared/api/common/local-storage';
import Routes from '../routes';
import { DATABASE_AUTHORIZATION_STORE_KEY } from './login.options';
import {
  AuthorizationOptions,
  InitializationOptions,
  AuthorizationError,
} from '../../shared/api/authorization/authorization.model';
import {
  cipherOcuco,
  encrypt,
  decrypt,
} from '../../shared/api/common/encryption/encryption';
import { DATABASE_INITIALIZATION_STORE_KEY } from '../initialize/initialize.options';
import { ModalState } from '../common/common.model';
import styles from './login.module.scss';
import {
  clearAuthorizationDatabase,
  mapAuthorizationErrorToMessage,
} from '../common/authorization.utils';
import LogRocket from 'logrocket';

const PIN_LENGTH = 6;
const MAX_PIN_INVALID_COUNT = 3;
const ALERT_DURATION = 3000;

export const LoginContainer: React.FC = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const [pinInvalidCount, setPinInvalidCount] = useState(0);
  const [alert, setAlert] = useState<AlertModel>({
    type: AlertType.ERROR,
    isShown: false,
    message: 'Error',
  });
  const [modalSuccessState, setModalSuccessState] = useState<ModalState>({
    isOpen: false,
  });
  const [modalErrorState, setModalErrorState] = useState<ModalState>({
    isOpen: false,
  });

  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    setAlertHelper(
      AlertType.SUCCESS,
      `Initialization successful. Please enter PIN.`,
      ALERT_DURATION
    );

    authorize();

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (pinInvalidCount >= MAX_PIN_INVALID_COUNT) {
      // TBD: reset initialization process?
    }
  }, [pinInvalidCount]);

  useEffect(() => {
    if (loggedIn) {
      setAlert({ ...alert, isShown: false });
      if (timeout.current) {
        // Hide timeout when logged in
        clearTimeout(timeout.current);
      }
    }
  }, [loggedIn]);

  const authorize = async () => {
    const dbType = LocalStorage.DatabaseType.AUTHORIZATION;

    // Check if authorized
    try {
      const dbEntry: AuthorizationOptions = await LocalStorage.getFromDatabase(
        DATABASE_AUTHORIZATION_STORE_KEY,
        dbType
      );
      const dbError = LocalStorage.isDatabaseError(dbEntry);
      if (dbEntry && !dbError) {
        const decryptedLoginToken = await decrypt(dbEntry.loginToken);
        if (decryptedLoginToken) {
          setLoggedIn(true);
        } else {
          // Encryption failed
          throw () =>
            onDatabaseError(AuthorizationError.ENCRYPTION, true, true);
        }
      } else if (dbError) {
        // Retrieving from database failed
        throw () => onDatabaseError(AuthorizationError.DATABASE, true, true);
      }
    } catch (e) {
      // Failed to verify pin, needs to repeat the whole initialization process
      if (e && typeof e === 'function') {
        e();
      } else {
        onDatabaseError(AuthorizationError.UNKNOWN, true, true);
      }
    }

    setLoading(false);
  };

  const addLoginTokenToDatabase = async (ocucoEncryptedPin: string) => {
    const dbType = LocalStorage.DatabaseType.AUTHORIZATION;

    try {
      const appEncryptedPin = await encrypt(ocucoEncryptedPin);
      if (appEncryptedPin) {
        const loginToken: AuthorizationOptions = {
          loginToken: appEncryptedPin,
        };

        // Add login token to the database and reload the page
        const retVal = await LocalStorage.addToDatabase(
          loginToken,
          DATABASE_AUTHORIZATION_STORE_KEY,
          undefined,
          dbType
        );

        if (LocalStorage.isDatabaseError(retVal)) {
          throw () => onDatabaseError(AuthorizationError.DATABASE, true, true);
        }
      } else {
        // Failed to encrypt pin
        throw () => onDatabaseError(AuthorizationError.ENCRYPTION, true, true);
      }
    } catch (e) {
      if (e && typeof e === 'function') {
        e();
      } else {
        onDatabaseError(AuthorizationError.UNKNOWN, true, true);
      }
    }
  };

  const onPinEnteredHandler = (pin: string) => {
    setLoading(true);
    verifyPin(pin);
  };

  const verifyPin = async (pin: string) => {
    const dbTypeInit = LocalStorage.DatabaseType.INITIALIZATION;

    try {
      const dbEntry: InitializationOptions = await LocalStorage.getFromDatabase(
        DATABASE_INITIALIZATION_STORE_KEY,
        dbTypeInit
      );

      const dbError = LocalStorage.isDatabaseError(dbEntry);
      if (dbError) {
        throw () => onDatabaseError(AuthorizationError.DATABASE, true, true);
      } else if (dbEntry) {
        // double encrypted
        const appDecryptedPin = await decrypt(dbEntry.P2);
        if (appDecryptedPin) {
          const ocucoDecryptedPin = cipherOcuco(appDecryptedPin);

          if (ocucoDecryptedPin === pin) {
            await addLoginTokenToDatabase(dbEntry.P2);
            setVerified(true);
            setModalSuccessState({
              isOpen: true,
              title: 'Verification success',
              message:
                'Verification process was successful. You have an access to the app.',
            });
          } else {
            setPinInvalidCount(pinInvalidCount + 1);

            setAlertHelper(
              AlertType.ERROR,
              `Wrong pin. Try once again`,
              ALERT_DURATION
            );
          }
          setLoading(false);
        }
      } else {
        throw () => onDatabaseError(AuthorizationError.DATABASE, true, true);
      }
    } catch (e) {
      if (e && typeof e === 'function') {
        e();
      } else {
        onDatabaseError(AuthorizationError.UNKNOWN, true, true);
      }
    }
  };

  const onDatabaseError = (
    errorType: AuthorizationError,
    showError?: boolean,
    reload?: boolean
  ) => {
    if (showError) {
      setModalErrorState({
        isOpen: true,
        title: `An error occured`,
        message: mapAuthorizationErrorToMessage(errorType),
      });
    } else if (reload) {
      clearAuthorizationDatabase();
      location.replace(location.pathname);
    } else {
      clearAuthorizationDatabase();
      setLoading(false);
    }
  };

  const onConfirmModalSuccessHandler = async () => {
    setModalSuccessState({ isOpen: false });
    setLoggedIn(true);

    const businessId = await getInitializationBusinessId();
    if (businessId) {
      LogRocket.identify(businessId);
    }

    if (location.search) {
      // Get rid of link params if accessed by phone's QR scanner
      location.replace(location.pathname);
    }
  };

  const onConfirmModalErrorHandler = () => {
    setModalErrorState({ isOpen: false });
    clearAuthorizationDatabase();
    location.replace(location.pathname);
  };

  const setAlertHelper = (
    type: AlertType,
    message: string,
    duration: number
  ) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    setAlert({ isShown: true, type: type, message: message });
    timeout.current = setTimeout(() => {
      setAlert({ ...alert, isShown: false });
    }, duration);
  };

  const getInitializationBusinessId = async (): Promise<string | undefined> => {
    const initialziation: InitializationOptions = await LocalStorage.getFromDatabase(
      DATABASE_INITIALIZATION_STORE_KEY,
      LocalStorage.DatabaseType.INITIALIZATION
    );
    if (initialziation && initialziation.P3) {
      // app decrypt
      try {
        const businessIdAppDecrypted = await decrypt(initialziation.P3);
        if (businessIdAppDecrypted) {
          const businessIdOcucoDecrypted = await cipherOcuco(
            businessIdAppDecrypted
          );

          return businessIdOcucoDecrypted;
        }
      } catch (e) {
        setAlertHelper(
          AlertType.ERROR,
          `An error occured while retrieving verification information.
          Please try once again.`,
          ALERT_DURATION
        );
      }
    }

    return undefined;
  };

  return (
    <>
      <Alert type={alert.type} isShown={alert.isShown} isFixed={true}>
        {alert.message}
      </Alert>
      {loading ? (
        <SpinnerComponent />
      ) : loggedIn ? (
        <Routes />
      ) : (
        <FullHeightContainer className={styles.loginWrapper}>
          <FlexColumn className={styles.loginContainer}>
            {verified ? (
              <Icon
                className={styles.iconVerified}
                SvgComponent={UnlockedSVG}
              />
            ) : (
              <FlexColumn className={styles.pinContainer}>
                <PinPad
                  pinLength={PIN_LENGTH}
                  onEntered={onPinEnteredHandler}
                  pinValid={modalSuccessState.isOpen}
                />
              </FlexColumn>
            )}
          </FlexColumn>
        </FullHeightContainer>
      )}
      <ModalSuccess
        title={modalSuccessState.title}
        isOpen={modalSuccessState.isOpen}
        onConfirm={onConfirmModalSuccessHandler}
      >
        {modalSuccessState.message}
      </ModalSuccess>
      <ModalError
        title={modalErrorState.title}
        isOpen={modalErrorState.isOpen}
        onConfirm={onConfirmModalErrorHandler}
      >
        {modalErrorState.message}
      </ModalError>
    </>
  );
};
