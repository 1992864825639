import React from 'react';
import classnames from 'classnames';

import iconStyle from './icon.module.scss';

export type IconProps = {
  SvgComponent?: React.SFC<React.SVGProps<SVGSVGElement>>;
  inline?: boolean;
} & React.HTMLAttributes<SVGElement>;

export const Icon: React.FunctionComponent<IconProps> = props => {
  const { SvgComponent, className, inline, ...rest } = props;

  return (
    <>
      {SvgComponent ? (
        <SvgComponent
          className={classnames(
            iconStyle.icon,
            {
              [iconStyle.inline]: inline,
            },
            className
          )}
          {...rest}
        />
      ) : null}
    </>
  );
};
