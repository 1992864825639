import React from 'react';
import {
  FlexStretchItem,
  FullHeightContainer,
  VerticalContainer,
} from '../../shared/design';
import { InitializeContainer } from './initialize.container';

export const InitializeView: React.FunctionComponent = () => {
  return (
    <FlexStretchItem>
      <FullHeightContainer as={VerticalContainer} isScrollable={true}>
        <InitializeContainer />
      </FullHeightContainer>
    </FlexStretchItem>
  );
};
