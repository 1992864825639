export enum WindowOrientation {
  LANDSCAPE,
  PORTRAIT,
}

export interface ModalState {
  isOpen: boolean;
  title?: string;
  message?: string | JSX.Element;
  confirmButtonMessage?: string;
  dismissButtonMessage?: string;
}
