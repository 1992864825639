import React, { useRef, RefObject } from 'react';
import classnames from 'classnames';

import { Icon, SearchSVG } from '../icon';
import { TextInput, TextInputProps } from '../../';

import searchInputStyles from './search-input.module.scss';
import { assignRefs } from '../../../utils/assign-refs';

export interface SearchInputProps extends TextInputProps {
  inputClassName?: string;
  hasDropdown?: boolean;
  onDropdownClick?: () => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const SearchInput: React.FunctionComponent<SearchInputProps> = ({
  className,
  inputClassName,
  onDropdownClick,
  onFocus,
  hasDropdown,
  innerRef,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    // search input should set cursor at the end when focused
    if (inputRef.current && typeof inputRef.current.value === 'string') {
      const length = inputRef.current.value.length;
      inputRef.current.setSelectionRange(length, length);
    }

    if (onFocus) {
      onFocus(e);
    }
  };

  return (
    <div
      className={classnames(searchInputStyles.searchInputContainer, className)}
    >
      <Icon
        className={searchInputStyles.searchIcon}
        inline={true}
        SvgComponent={SearchSVG}
      />
      <TextInput
        className={classnames(searchInputStyles.searchInput, inputClassName)}
        innerRef={assignRefs([inputRef, innerRef])}
        onFocus={handleFocus}
        {...props}
      />
    </div>
  );
};
