export interface DatabaseEntry {
  key: string;
  data: any;
}

export interface DatabaseError {
  type: LocalStorageErrorType;
  message: string;
}

export enum LocalStorageErrorType {
  ERROR_DUPLICATE,
  ERROR_ADD,
  ERROR_GET,
  ERROR_UPDATE,
  ERROR_REMOVE,
  ERROR_INITIALIZE,
  ERROR_ACCESS,
}
