import { OperatingSystemType } from '../design/components/common.model';

export const detectOS = (): OperatingSystemType => {
  const testUserAgent = (regexp: RegExp) => {
    return regexp.test(window.navigator.userAgent);
  };
  const testPlatform = (regexp: RegExp) => {
    return regexp.test(window.navigator.platform);
  };
  switch (true) {
    case testUserAgent(/Android/i):
      return OperatingSystemType.ANDROID;
    case testPlatform(
      /(ipad simulator)|(iphone simulator)|(ipod simulator)|ipad|iphone|ipod/i
    ) ||
      (testUserAgent(/mac/i) && 'ontouchend' in document): // iOS 13
      return OperatingSystemType.IOS;
    case testUserAgent(/Win/i):
      return OperatingSystemType.WINDOWS;
    case testUserAgent(/linux/i):
      return OperatingSystemType.LINUX;
    case testUserAgent(/mac/i):
      return OperatingSystemType.MAC;
    default:
      return OperatingSystemType.OTHER;
  }
};
