import React from 'react';
import { ModalBody as BootstrapModalBody } from 'reactstrap';
import { EnterAsTab } from '../../../../utils/enter-as-tab';
import classnames from 'classnames';
import styles from './modal-body.module.scss';

interface ModalBodyProps {
  className?: string;
  children: React.ReactNode;
}

export const ModalBody: React.FunctionComponent<ModalBodyProps> = ({
  className,
  children,
}) => (
  <EnterAsTab>
    <BootstrapModalBody
      data-testid="modal-body"
      className={classnames(className, styles.body)}
    >
      {children}
    </BootstrapModalBody>
  </EnterAsTab>
);
