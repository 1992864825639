import React from 'react';
import { ModalHeader as BootstrapModalHeader } from 'reactstrap';
import { Icon, CloseSVG } from '../../../index';
import { noop } from '../../../../utils/noop';
import classnames from 'classnames';
import style from './modal-header.module.scss';

interface ModalHeaderProps {
  className?: string;
  children: React.ReactNode;
  disableClose?: boolean;
  onCloseIconClick?: () => void;
  onCloseIconMouseDown?: () => void;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  className,
  children,
  disableClose = false,
  onCloseIconClick,
  onCloseIconMouseDown,
  ...rest
}) => (
  <BootstrapModalHeader
    className={classnames(style.modalHeader, className)}
    data-testid="modal-header"
    close={
      onCloseIconClick ? (
        // there is a requirement for close button to not be in tab order
        // using regular button with tabindex set to -1 causes bugs with SHIFT-TAB
        // that's why we use span icon with custom styles and controled onClick
        <Icon
          onClick={() => {
            disableClose ? noop() : onCloseIconClick();
          }}
          SvgComponent={CloseSVG}
          data-testid="modal-close"
          className={classnames(
            style.closeIcon,
            disableClose && style.disabledClose
          )}
        />
      ) : onCloseIconMouseDown ? (
        // there is a requirement for close button to not be in tab order
        // using regular button with tabindex set to -1 causes bugs with SHIFT-TAB
        // that's why we use span icon with custom styles and controled onClick
        <Icon
          onMouseDown={() => {
            disableClose ? noop() : onCloseIconMouseDown();
          }}
          SvgComponent={CloseSVG}
          data-testid="modal-close"
          className={classnames(
            style.closeIcon,
            disableClose && style.disabledClose
          )}
        />
      ) : null
    }
    {...rest}
  >
    {children}
  </BootstrapModalHeader>
);
