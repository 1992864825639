import React from 'react';
import {
  FullHeightContainer,
  FlexColumn,
  Loading,
  List,
  ListProps,
} from '../../';
import { SearchInput } from '../search-input/search-input';
import { NoSearchMatch } from './no-serach-match/no-search-match.component';
import classnames from 'classnames';
import styles from './searchable-list.module.scss';

interface SearchableListProps extends ListProps {
  className?: string;
  isLoading?: boolean;
  onSearch?: (value: string) => void;
}

export const SearchableList: React.FC<SearchableListProps> = ({
  className,
  children,
  isLoading,
  onSearch,
  ...rest
}) => {
  const childrenCount = React.Children.count(children);
  return (
    <FullHeightContainer className={classnames(className)}>
      <Loading isLoading={isLoading}>
        <FlexColumn>
          <SearchInput className={styles.searchInput} onChange={onSearch} />
          <List {...rest}>
            {childrenCount > 0 ? children : <NoSearchMatch />}
          </List>
        </FlexColumn>
      </Loading>
    </FullHeightContainer>
  );
};
