import React from 'react';

import { Button, ButtonColor, ButtonWithColorProps } from './button';

export const PrimaryButton: React.FunctionComponent<ButtonWithColorProps> = ({
  children,
  ...props
}) => (
  <Button color={ButtonColor.PRIMARY} {...props}>
    {children}
  </Button>
);
