import React from 'react';
import { ModalFooter as BootstrapModalFooter } from 'reactstrap';
import classnames from 'classnames';
import style from './modal-footer.module.scss';

interface ModalFooterProps {
  className?: string;
  children?: React.ReactNode;
}

export const ModalFooter: React.FunctionComponent<ModalFooterProps> = ({
  className,
  children,
}) => (
  <BootstrapModalFooter
    data-testid="modal-footer"
    className={classnames(style.modalFooter, className)}
  >
    {children}
  </BootstrapModalFooter>
);
