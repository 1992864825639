import React from 'react';
import { Modal as BootstrapModal } from 'reactstrap';
import classnames from 'classnames';

import {
  ModalContext,
  OnOpenListener,
  ReleaseOnOpenListener,
} from './modal.context';

interface ModalProps {
  className?: string;
  isOpen?: boolean;
  onClosed?: () => void;
  children: React.ReactNode;
  size?: ModalSize;
  autoFocus?: boolean;
}

export enum ModalSize {
  EXTRA_LARGE = 'xl',
  LARGE = 'lg',
  MEDIUM = 'md',
  SMALL = 'sm',
}

export class Modal extends React.Component<ModalProps> {
  openListeners: OnOpenListener[] = [];

  handleOnOpen = () => {
    this.openListeners.forEach(cb => cb());
  };

  registerOnOpen = (cb: OnOpenListener): ReleaseOnOpenListener => {
    this.openListeners.push(cb);
    return () => {
      const index = this.openListeners.indexOf(cb);
      if (index !== -1) {
        this.openListeners.splice(index, 1);
      } else {
        console.warn('Shared onOpen listener for modal');
      }
    };
  };

  render() {
    const {
      className,
      isOpen,
      onClosed,
      children,
      size,
      autoFocus,
    } = this.props;

    return (
      <ModalContext.Provider value={{ registerOnOpen: this.registerOnOpen }}>
        <BootstrapModal
          size={size}
          onClosed={onClosed}
          className={classnames(className, 'modal-dialog-centered')}
          isOpen={isOpen}
          onOpened={this.handleOnOpen}
          autoFocus={autoFocus === undefined ? true : autoFocus}
          fade={false}
        >
          {children}
        </BootstrapModal>
      </ModalContext.Provider>
    );
  }
}
