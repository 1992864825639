import React, { useRef, useEffect, useState } from 'react';
import { Alert as ReactstrapAlert } from 'reactstrap';
import { AlertModel } from '../alert.model';
import { FlexColumn } from '../../../primitives';
import classnames from 'classnames';
import styles from './alert.module.scss';

interface AlertProps extends AlertModel {
  className?: string;
  isFixed?: boolean;
  underNavBar?: boolean;
}

export const Alert: React.FC<AlertProps> = ({
  type,
  isShown,
  children,
  className,
  isFixed,
  underNavBar = true,
}) => {
  const appBarRef = useRef<HTMLDivElement>();
  const [styleTop, setStyleTop] = useState(0);
  useEffect(() => {
    const appBar = document.querySelector('[role="navigation"]');
    if (appBar && underNavBar) {
      appBarRef.current = appBar as HTMLDivElement;
      setStyleTop(appBar.clientHeight);
    }

    window.addEventListener('scroll', onScrollHandler);

    return () => {
      window.removeEventListener('scroll', onScrollHandler);
    };
  }, []);

  const onScrollHandler = (e: Event) => {
    if (appBarRef.current && isFixed) {
      const newStyleTop = Math.max(
        appBarRef.current.clientHeight - window.scrollY,
        0
      );

      setStyleTop(newStyleTop);
    }
  };

  return (
    <>
      {isShown && (
        <FlexColumn
          className={classnames(styles.alertWrapper, {
            [styles.fixed]: isFixed,
          })}
          style={{ top: styleTop }}
        >
          <ReactstrapAlert color={type} className={className}>
            {children}
          </ReactstrapAlert>
        </FlexColumn>
      )}
    </>
  );
};
