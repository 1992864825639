import {
  DatabaseType,
  localForageGosFormsInstance,
  localForageAuthorizationInstance,
  localForageInitializationInstance,
} from './local-storage.config';
import { LocalStorageErrorType } from './local-storage.model';

export const mapLocalStorageErrorToMessage = (error: LocalStorageErrorType) => {
  switch (error) {
    case LocalStorageErrorType.ERROR_DUPLICATE:
      return 'That record already exists in the database.';
    case LocalStorageErrorType.ERROR_ADD:
      return 'Could not add to the database. Try once again.';
    case LocalStorageErrorType.ERROR_GET:
      return 'Could not read from the database. Try once again.';
    case LocalStorageErrorType.ERROR_UPDATE:
      return 'Could not update the database. Try once again.';
    case LocalStorageErrorType.ERROR_REMOVE:
      return 'Could not remove from the database. Try once again.';
    case LocalStorageErrorType.ERROR_INITIALIZE:
      return 'Could not initialize database.';
    case LocalStorageErrorType.ERROR_ACCESS:
      return 'Could not access the database. Check your web browsers permissions.';
    default:
      return 'An unknown error has occured.';
  }
};

export const getDatabaseInstance = (databaseType: DatabaseType) => {
  switch (databaseType) {
    case DatabaseType.GOS_FORMS:
      return localForageGosFormsInstance;
    case DatabaseType.AUTHORIZATION:
      return localForageAuthorizationInstance;
    case DatabaseType.INITIALIZATION:
      return localForageInitializationInstance;
  }
};
