import React from 'react';
import { Label } from '../../..';
import classnames from 'classnames';
import styles from './decorator-title.module.scss';

interface DecoratorTitleProps {
  className?: string;
}

export const DecoratorTitle: React.FC<DecoratorTitleProps> = ({
  className,
  children,
}) => {
  return (
    <Label className={classnames(className, styles.decoratorTitle)}>
      {children}
    </Label>
  );
};
