import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Alert,
  AlertModel,
  AlertType,
  DismissableAlert,
  ModalError,
  ModalSuccess,
  ModalInformation,
} from '../../';
import { ModalState } from '../../../../modules/common/common.model';
import { isStandalone } from '../../../utils/standalone';
import { HttpActionsAlertContext } from '../http-actions/http-actions-alert.context';
import { ServiceWorkerAlertContext } from './service-worker-alert.context';

const ALERT_DURATION = 3000; // in ms

interface ServiceWorkerState {
  registration: ServiceWorkerRegistration | undefined;
}

export const ServiceWorkerActions: React.FC = () => {
  const [modalSuccessState, setModalSuccessState] = useState<ModalState>({
    isOpen: false,
  });
  const [modalErrorState, setModalErrorState] = useState<ModalState>({
    isOpen: false,
  });
  const [modalInformationState, setModalInformationState] = useState<
    ModalState & ServiceWorkerState
  >({
    isOpen: false,
    registration: undefined,
  });
  const [alert, setAlert] = useState<AlertModel>({
    type: AlertType.ERROR,
    isShown: false,
    message: 'Error',
  });
  const [dismissableAlert, setDismissableAlert] = useState<AlertModel>({
    type: AlertType.ERROR,
    isShown: false,
    message: 'Error',
  });
  const timeout = useRef<NodeJS.Timeout>();

  const httpActionsAlertContext = useContext(HttpActionsAlertContext);

  useEffect(() => {
    serviceWorkerHandler();

    window.addEventListener('online', networkStatusHandler);
    window.addEventListener('offline', networkStatusHandler);

    return () => {
      window.removeEventListener('online', networkStatusHandler);
      window.removeEventListener('offline', networkStatusHandler);
    };
  }, []);

  useEffect(() => {
    if (httpActionsAlertContext) {
      setDismissableAlert({ ...dismissableAlert, isShown: false });
      setAlert({ ...alert, isShown: false });
    }
  }, [httpActionsAlertContext]);

  const serviceWorkerHandler = async () => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.ready.then(
        (registration: ServiceWorkerRegistration) => {
          if (registration) {
            registration.addEventListener('updatefound', () => {
              const installingWorker = registration.installing;
              if (installingWorker) {
                installingWorker.addEventListener('statechange', () => {
                  if (installingWorker.state === 'installed') {
                    if (navigator.serviceWorker.controller) {
                      // New content available
                      setModalInformationState({
                        isOpen: true,
                        title: 'New content',
                        message: `New content available. Update will be applied now.`,
                        registration: registration,
                      });
                    } else {
                      const mobileModeMessage = isStandalone()
                        ? 'App'
                        : 'Website';
                      setDismissableAlert({
                        isShown: true,
                        type: AlertType.SUCCESS,
                        message: `Content cached. ${mobileModeMessage} can be used now in offline mode.`,
                      });
                    }
                  }
                });
              }
            });
          }
        }
      );
    }
  };

  const networkStatusHandler = () => {
    const message = navigator.onLine
      ? 'Back online.'
      : 'Running in offline mode.';

    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    setAlert({
      isShown: true,
      type: AlertType.WARNING,
      message: message,
    });
    timeout.current = setTimeout(() => {
      setAlert({ ...alert, isShown: false });
    }, ALERT_DURATION);
  };

  const onConfirmModalSuccessHandler = () => {
    setModalSuccessState({ isOpen: false });
  };

  const onConfirmModalErrorHandler = () => {
    setModalErrorState({ isOpen: false });
  };

  const onConfirmModalInformationHandler = () => {
    if (modalInformationState.registration) {
      const waitingServiceWorker = modalInformationState.registration.waiting;
      if (waitingServiceWorker) {
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }
    }
    setModalInformationState({ isOpen: false, registration: undefined });
  };

  return (
    <ServiceWorkerAlertContext.Provider
      value={{
        type: alert.type,
        isShown: alert.isShown,
        message: alert.message,
      }}
    >
      <HttpActionsAlertContext.Consumer>
        {httpActionsAlert => (
          <Alert
            type={alert.type}
            isShown={!httpActionsAlert.isShown && alert.isShown}
            isFixed={true}
          >
            {alert.message}
          </Alert>
        )}
      </HttpActionsAlertContext.Consumer>
      <DismissableAlert
        type={dismissableAlert.type}
        isShown={dismissableAlert.isShown}
        isFixed={true}
      >
        {dismissableAlert.message}
      </DismissableAlert>
      <ModalSuccess
        title={modalSuccessState.title}
        isOpen={modalSuccessState.isOpen}
        body={modalSuccessState.message}
        onConfirm={onConfirmModalSuccessHandler}
      >
        {modalSuccessState.message}
      </ModalSuccess>
      <ModalError
        title={modalErrorState.title}
        isOpen={modalErrorState.isOpen}
        onConfirm={onConfirmModalErrorHandler}
      >
        {modalErrorState.message}
      </ModalError>
      <ModalInformation
        title={modalInformationState.title}
        isOpen={modalInformationState.isOpen}
        onConfirm={onConfirmModalInformationHandler}
      >
        {modalInformationState.message}
      </ModalInformation>
    </ServiceWorkerAlertContext.Provider>
  );
};
