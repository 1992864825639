import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/app';
import { configureBaseApi } from './app/shared/api/common/apis';
import LogRocket from 'logrocket';

export interface AppConfig {
  apiUrl: string;
  deploymentType: string;
  loggerKey: string;
}

export const bootstrap = (config: AppConfig) => {
  configureBaseApi(config.apiUrl, config.deploymentType);
  LogRocket.init(config.loggerKey, {
    shouldCaptureIP: false,
    dom: {
      inputSanitizer: true,
    },
  });

  ReactDOM.render(<App />, document.getElementById('root'));
};
