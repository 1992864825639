import { BrowserType } from '../design/components/common.model';

export const detectBrowser = (): BrowserType => {
  const test = (regexp: RegExp) => {
    return regexp.test(window.navigator.userAgent);
  };
  switch (true) {
    case test(/edg/i):
      return BrowserType.EDGE;
    case test(/trident/i):
      return BrowserType.IE;
    case test(/firefox|fxios/i):
      return BrowserType.MOZILLA;
    case test(/opr\//i):
      return BrowserType.OPERA;
    case test(/ucbrowser/i):
      return BrowserType.UC_BROWSER;
    case test(/samsungbrowser/i):
      return BrowserType.SAMSUNG_BROWSER;
    case test(/chrome|chromium|crios/i):
      return BrowserType.CHROME;
    case test(/safari/i):
      return BrowserType.SAFARI;
    default:
      return BrowserType.OTHER;
  }
};
