import React from 'react';
import classnames from 'classnames';

import './spinner.scss';

interface SpinnerComponentProps {
  size?: SpinnerSize;
  innerRef?: React.RefObject<HTMLDivElement>;
  className?: string;
}

export enum SpinnerSize {
  SMALL = 'sm',
}

export const SpinnerComponent: React.FunctionComponent<SpinnerComponentProps> = ({
  size,
  innerRef,
  className,
  ...rest
}) => {
  const classes = classnames('SpinnerComponent', 'spinner', size, className);

  return (
    <div className={classes} ref={innerRef} {...rest}>
      <div className="circle circle-one" />
      <div className="circle circle-two" />
      <div className="circle circle-three" />
    </div>
  );
};
