import { buildPrimitive } from '../../primitive-builder';

import styles from './flex-row.module.scss';

export const FlexRow = buildPrimitive(styles.row, {
  stretched: styles.stretched,
  rightAligned: styles.rightAligned,
  centerAligned: styles.centerAligned,
  spaceBetween: styles.spaceBetween,
  alignBottom: styles.alignBottom,
  alignTop: styles.alignTop,
  reverse: styles.rowReverse,
});
