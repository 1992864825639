import { buildPrimitive } from '../../primitive-builder';

import styles from './flex-column.module.scss';

export const FlexColumn = buildPrimitive(styles.column, {
  stretched: styles.stretched,
  centerAligned: styles.centerAligned,
  spaceBetween: styles.spaceBetween,
  centered: styles.centered,
});
