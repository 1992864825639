export interface InitializationOptions {
  P1: string; // hub
  P2: string; // pin
  P3: string; // business
}

export interface AuthorizationOptions {
  loginToken: string;
}

export enum AuthorizationError {
  UNKNOWN,
  DATABASE,
  ENCRYPTION,
}
