import React from 'react';
import { Link } from 'react-router-dom';
import { FlexColumn, Label } from '../../../shared/design';
import classnames from 'classnames';
import styles from './empty-patients-list.module.scss';

interface EmptyListProps {
  className?: string;
}

export const EmptyPatientsList: React.FC<EmptyListProps> = ({ className }) => {
  return (
    <FlexColumn className={classnames(className, styles.emptyList)}>
      <Label>
        There are no patients scanned. Click <Link to="/qr">here</Link> to scan
        patient's QR code.
      </Label>
    </FlexColumn>
  );
};
