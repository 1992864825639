import React, { ChangeEvent } from 'react';

import { serialIdGenerator } from '../../../utils/id-generators';

import styles from './checkbox-switch.module.scss';

const generateId = serialIdGenerator('checkboxSwitchId');

export enum CheckboxSwitchSize {
  SMALL = 'small',
  XSMALL = 'xsmall',
  DEFAULT = '',
}

interface CheckboxSwitchProps {
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  name?: string;
  value?: string;
  readonly?: boolean;
  size?: CheckboxSwitchSize;
  disabled?: boolean;
  checked?: boolean;
  invalid?: boolean;
  tabIndex?: number;
}

export class CheckboxSwitch extends React.Component<CheckboxSwitchProps> {
  private readonly defaultId = generateId.next().value;

  render() {
    const {
      id,
      name,
      value,
      onChange,
      size = CheckboxSwitchSize.DEFAULT,
      readonly,
      disabled,
      checked,
      invalid,
      tabIndex,
    } = this.props;

    const mapSizeToClassName = (size: CheckboxSwitchSize) => {
      switch (size) {
        case CheckboxSwitchSize.SMALL: {
          return styles.small;
        }
        case CheckboxSwitchSize.XSMALL: {
          return styles.xsmall;
        }
        default: {
          return '';
        }
      }
    };

    const switchSelectorClasses = [
      styles.switchSelector,
      mapSizeToClassName(size),
    ];
    const switchLineClasses = [styles.switchLine, mapSizeToClassName(size)];

    return (
      <React.Fragment>
        <input
          type="checkbox"
          id={id || (this.defaultId as string)}
          name={name}
          value={value}
          readOnly={readonly}
          disabled={disabled}
          onChange={readonly ? undefined : onChange}
          checked={checked}
          className={styles.checkboxInput}
          aria-invalid={invalid}
          tabIndex={tabIndex}
          data-testid="checkbox-switch"
        />
        <label
          htmlFor={id || (this.defaultId as string)}
          className={switchLineClasses.join(' ')}
        >
          <div className={switchSelectorClasses.join(' ')} />
        </label>
      </React.Fragment>
    );
  }
}
