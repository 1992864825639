import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../../../../../assets/images/logo.png';
import styles from './app-nav-logo.module.scss';
import { AppVersionComponent } from '../app-version/app-version.component';

interface AppNavLogoProps {
  path?: string;
  className?: string;
}
export const AppNavLogoComponent: React.FC<AppNavLogoProps> = ({
  path,
  className,
}) => {
  const [isVersionModalOpen, setVersionModalOpen] = useState(false);
  return (
    <>
      <NavLink
        to={path || '/'}
        exact={true}
        className={className}
        onDoubleClick={() => setVersionModalOpen(true)}
      >
        <img src={logo} className={styles.logo} alt="logo" />
      </NavLink>
      <AppVersionComponent
        isOpen={isVersionModalOpen}
        onDismiss={() => setVersionModalOpen(false)}
      />
    </>
  );
};
