import * as localForage from 'localforage';

const DATABASE_APP_NAME = 'OcuSign';
const DATABASE_GOS_FORMS_STORE_NAME = 'gos_forms';
export const DATABASE_GOS_FORMS_ALLOW_DUPLICATES = false;

const DATABASE_AUTHORIZATION_STORE_NAME = 'authorization';
export const DATABASE_AUTHORIZATION_ALLOW_DUPLICATES = false;

const DATABASE_INITIALIZATION_STORE_NAME = 'initialization';
export const DATABASE_INITIALIZAITON_ALLOW_DUPLICATES = false;

export enum DatabaseType {
  GOS_FORMS,
  AUTHORIZATION,
  INITIALIZATION,
}

interface DatabaseOptions extends LocalForageOptions {}

const DatabaseGosForms: DatabaseOptions = {
  name: DATABASE_APP_NAME,
  version: 1.0,
  storeName: DATABASE_GOS_FORMS_STORE_NAME,
  description: 'GOS forms database of OcuSign app',
};

const DatabaseAuthorization: DatabaseOptions = {
  name: DATABASE_APP_NAME,
  version: 1.0,
  storeName: DATABASE_AUTHORIZATION_STORE_NAME,
  description: 'Authorization database of OcuSign app',
};

const DatabaseInitialization: DatabaseOptions = {
  name: DATABASE_APP_NAME,
  version: 1.0,
  storeName: DATABASE_INITIALIZATION_STORE_NAME,
  description: 'Initialization database of OcuSign app',
};

export const localForageGosFormsInstance = localForage.createInstance(
  DatabaseGosForms
);
export const localForageAuthorizationInstance = localForage.createInstance(
  DatabaseAuthorization
);
export const localForageInitializationInstance = localForage.createInstance(
  DatabaseInitialization
);
