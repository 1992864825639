import {
  GOSForm,
  GOSFormAPI,
  SignatureAPI,
  Signature,
  HttpRequestState,
} from './signature.model';
import { dataURLToBlob } from '../../utils/blob-utils';

export const mapGOSFormToAPI = (
  gosForm: GOSForm,
  signatures: Signature[]
): GOSFormAPI => {
  const signaturesAPI: SignatureAPI[] = [];
  const signaturesCount = signatures.length;
  for (let i = 0; i < signaturesCount; i++) {
    const signature = signatures[i];
    if (
      signature.data &&
      signature.httpRequestState !== HttpRequestState.COMPLETED
    ) {
      const signatureAPI: SignatureAPI = {
        id: signature.id,
        signature_type: signature.type,
        blob: dataURLToBlob(signature.data!),
      };
      signaturesAPI.push(signatureAPI);
    }
  }

  const gosFormAPI: GOSFormAPI = {
    business: {
      customer_id: gosForm.business.customerId,
      practice_id: gosForm.business.practiceId,
    },
    forms: [
      {
        form_id: gosForm.formId,
        form_no: (gosForm.formNo as number).toString(),
        patient_id: gosForm.patient.patientId,
        signatures: signaturesAPI,
      },
    ],
  };

  return gosFormAPI;
};

export const mapGosFormAPIToFormData = (gosFormAPI: GOSFormAPI): FormData => {
  const formData = new FormData();
  formData.append('business.customer_id', gosFormAPI.business.customer_id);
  formData.append('business.practice_id', gosFormAPI.business.practice_id);
  formData.append('forms[0].patient_id', gosFormAPI.forms[0].patient_id);
  formData.append('forms[0].form_id', gosFormAPI.forms[0].form_id);
  formData.append('forms[0].form_no', gosFormAPI.forms[0].form_no);
  gosFormAPI.forms[0].signatures.forEach((sig: SignatureAPI, index) => {
    formData.append(`forms[0].signatures[${index}].id`, sig.id.split('_')[0]);
    formData.append(
      `forms[0].signatures[${index}].type`,
      (sig.signature_type as number).toString()
    );
    formData.append(
      `forms[0].signatures[${index}].blob`,
      sig.blob || '',
      'form.png'
    );
  });

  return formData;
};
